import React, { Component } from 'react'
import SearchBar from './searchbar'
import Pagination from './pagination'
class List extends Component {
    state={page: 0}
    render() {
        return (<div className="b1">
            {this.props.search !== false && <SearchBar template={this.props.template} mainKey={this.props.mainKey} items={this.props.items} indexes={this.props.indexes}/>}
            <div className="c4">{window.index(this.props.items, this.state.page, (this.props.count || 12)).map(this.props.listItem || ((u, i) => (<div key={i}>{JSON.stringify(u)}</div>)))}</div>
            {this.props.pagination !== false && <Pagination page={this.state.page} count={this.props.count || 12} total={this.props.items?.length || 0} set={e => this.setState({page: e})} />}
        </div>)
    }
}
export default List