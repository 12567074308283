import '../css/account.css'

function NewUser() {
    return (<div className="formCont b1" style={{ marginTop: '10vh' }}>
        <form className="form b1" onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            let form = e.target
            let warn = form.querySelector('.warn')
            let warning = e => warn.innerHTML = e
            let p1 = form['password'].value
            let p2 = form['password2'].value
            if (p1 !== p2) return warning('Passwords must match')
            let email = form['email'].value
            let firstName = form['firstName'].value
            let lastName = form['lastName'].value
            let type = form['type'].value || 'sales'
            if (!(p1 && p2 && email && firstName && lastName && type)) return warning('All fields are required')
            window.app.request('/new-user', 'POST', { email, password: p1, firstName, lastName, type }).then(result => {
                if (result.error) return warning(result.message || JSON.stringify(result))
                form.reset()
                warning('Success!')
                setTimeout(() => warning(''), 2000)
            }).catch(e => {
                warning(e)
            })
        }}>
            <h2 className="formHead">Create a User</h2>
            <span className="warn"></span>
            <div className="b2">
                <div className="b1 fifty margy">
                    <label htmlFor="">First Name</label>
                    <input className="wide" type="text" name="firstName" placeholder="First Name"></input>
                </div>
                <div className="b1 fifty margy">
                    <label htmlFor="">Last Name</label>
                    <input className="wide" type="text" name="lastName" placeholder="Last Name"></input>
                </div>
            </div>
            <div className="b1 fifty margy">
                <label htmlFor="email">Email Address</label>
                <input className="wide" type="email" name="email" placeholder="Email"></input>
            </div>
            <div className="b1 fifty margy">
                <label htmlFor="type">User Type</label>
                <select className="wide" name="type">
                    <option>Select a User Type</option>
                    <option value="tech">Tech</option>
                    <option value="sales">Sales</option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                    <option value="film">Filmmaker</option>
                </select>
            </div>
            <div className="b2">
                <div className="b1 fifty margy">
                    <label htmlFor="password">Password</label>
                    <input className="wide" type="password" name="password" placeholder="Password"></input>
                </div>
                <div className="b1 fifty margy">
                    <label htmlFor="password2">Confirm Password</label>
                    <input className="wide" type="password" name="password2" autoComplete="new-password" placeholder="Confirm Password"></input>
                </div>
            </div>
            <div className="b1" style={{ justifySelf: 'center', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}><button type="submit">Create User</button></div>
        </form></div>)
}
export default NewUser