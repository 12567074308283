import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Sure from '../helper/sure'
import '../css/title.css'
class CreateTitle extends Component {
    constructor(props) {
        super(props)
        this.sel = React.createRef()
    }
    state = {
        recipients: [],
        contacts: [],
        showPass: false,
        name: '',
        page: 0,
        allgenres: ['action',
            'adventure',
            'animation',
            'aliens',
            'apocalypse',
            'comedy',
            'creature',
            'crime',
            'children',
            'detective',
            'dinosaurs',
            'disaster',
            'drama',
            'documentary',
            'epic',
            'erotic',
            'family',
            'fantasy',
            'horror',
            'history',
            'lgbtq',
            'monsters',
            'mystery',
            'noir',
            'occult',
            'paranormal',
            'postapocalypse',
            'psychological',
            'psychosexual',
            'robots',
            'romance',
            'scifi',
            'siege',
            'slasher',
            'survival',
            'suspense',
            'superhero',
            'supernatural',
            'teen',
            'thriller',
            'war',
            'western',
            'zombie'],
        genres: [],
        hds: []
    }
    componentDidMount() {
        this.getRecipients()
        this.getHDs()
    }
    getHDs() {
        window.app.request(`/hard-drives`, 'post').then(result => this.setState({ hds: result.HD })).catch(e => console.log(e))
    }
    getRecipients() {
        window.app.request(`/recipients`, 'post').then(result => this.setState({ contacts: result.recipients })).catch(e => console.log(e))
    }
    sum(ar) {
        if (typeof ar !== 'object') return 0
        let a = 0
        for (let i = 0; i < ar.length; i++) a += (ar[i] || 0)
        return a
    }
    addGenre(email) {
        this.setState({ genres: [...this.state.genres, email] })
    }
    removeGenre(email) {
        this.setState({ genres: [...this.state.genres].filter(u => u !== email) })
    }
    addRecipient(email) {
        this.setState({ recipients: [...this.state.recipients, email] })
    }
    removeRecipient(email) {
        this.setState({ recipients: [...this.state.recipients].filter(u => u === email) })
    }
    sure(message, data, callback) {
        ReactDOM.render((<Sure confirm={data => { callback(data); this.removeSure() }} data={data} message={message} close={this.removeSure}></Sure>), document.getElementById('pop'))
        document.getElementById('popup').classList.remove('hidden');
    }
    removeSure() {
        try {
            ReactDOM.unmountComponentAtNode(document.getElementById('pop'))
            document.querySelector('#popup').classList.add('hidden');
        } catch (e) {
            console.log('error', e)
        }
    }
    submit() {
        let data = {
            title: this.state.name,
            users: this.state.recipients,
            genres: this.state.genres,
            shortSynopsis: this.state.shortSynopsis,
            mediumSynopsis: this.state.mediumSynopsis,
            longSynopsis: this.state.longSynopsis,
            releaseDate: this.state.releaseDate,
            productionYear: this.state.productionYear,
            type: this.state.mediaType
        }
        if (this.state.hd) data.hardDrive = this.state.hd
        if (!data.title) return window.flash('Missing title')
        window.app.request('/new-title', 'post', data).then(result => {
            window.flash('Title Created!', 5000, 'green')
            window.app.getTitles()
            if (window.app.state.location !== '/titles') {
                window.redirect('/titles')
            }
        }).catch(e => {
            window.flash(e, 10000)
        })
    }
    upperCase(u) {
        let a = u.split('')
        a[0] = a[0].toUpperCase()
        return a.join('')
    }
    render() {
        let synStyle = { display: 'inline-flex', flexDirection: 'column', margin: '5px 10px' }
        let centStyle = {justifySelf: 'center', justifyContent: 'center', alignSelf: 'center', alignItems: 'center'}
        if (this.state.page === 1) return (<div className="form formtop told b1">
            <h1 className="formHead">{'Create a Title'}</h1>
            <div className="b1">
                <label htmlFor="">Who has title access?</label>
                {this.state.recipients?.length > 0 ? (<div id="packageRecipients">
                    {[...this.state.contacts].filter(u => u && this.state.recipients.includes(u._id)).map((u, i) => (<div key={i} id={u._id} onClick={e => {
                        let recipient = this.state.contacts.find(u => u._id === e.target.id)
                        if (!recipient) return
                        this.removeRecipient(recipient)
                    }}>{u.firstName} {u.lastName[0]}. - {u.email} <button onClick={window.parentClick}>X</button></div>))}
                </div>) : (<p>Please select some people for access</p>)}
                {!this.state.cr && <div style={{}} className="b1">
                    <label htmlFor="selectRecipient">Select a user</label>
                    <select ref={this.sel} name="selectRecipient"><option value="undefined">Select One</option>{this.state.contacts?.length > 0 && [...this.state.contacts].filter((u) => !this.state.recipients.includes(u._id)).map((u, i) => <option key={i} value={u._id} className="recipient">{u.firstName} {u.lastName[0]}. - {u.email}</option>)}</select>
                    <div className="b1">
                        <div className="b2" style={{ }}>
                            <div className="b1 fifty">
                                <button onClick={e => {
                                    let v = this.sel.current.value
                                    if (v && v !== 'undefined') this.addRecipient(v)
                                }}><i className="fas fa-plus"></i> Add User</button>

                            </div>
                            <div className="b1 fifty">
                                <button onClick={e => this.setState({ cr: true })}><i className="fas fa-user-plus"></i> Create New User</button>

                            </div>
                        </div>
                    </div>
                </div>}
                {this.state.cr && <form className="b1 form" onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => warn.innerHTML = e
                    let data = {
                        firstName: form['firstName'].value,
                        lastName: form['lastName'].value,
                        email: form['email'].value,
                        companyName: form['companyName'].value,
                        password: form['password'].value,
                        type: 'filmmaker'
                    }
                    if (!data.firstName || !data.lastName || !data.email || !data.password) return warning('Missing required fields')
                    if (data.password !== form['confirmPassword'].value) return warning('Password is ')
                    window.app.request('/new-recipient', 'post', data).then(result => {
                        form.reset()
                        this.setState({ cr: false, recipients: [...this.state.recipients, result.recipient._id], contacts: [...this.state.contacts, result.recipient] })
                    }).catch(e => warning(e))
                }}>
                    <h3>Create a user</h3>
                    <span className="warn"></span>
                    <div className="c2">
                        <div className="b1">
                            <label htmlFor="">First Name</label>
                            <input type="text" name="firstName"></input>
                        </div>
                        <div className="b1">
                            <label htmlFor="lastName">Last Name</label>
                            <input type="text" name="lastName"></input>
                        </div>
                    </div>
                    <div className="c2">
                    <div className="b1">
                        <label htmlFor="companyName">Company Name</label>
                        <input type="text" name="companyName"></input>
                    </div>
                    <div className="b1">
                        <label htmlFor="email">Email Address</label>
                        <input type="email" name="email"></input>
                    </div>
                    </div>
                    <div className="c2">
                        <div className="b1">
                            <label htmlFor="password">Password</label>
                            <input name="password" type={this.state.showPass ? 'text' : 'password'}></input>
                        </div>
                        <div className="b1">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input name="confirmPassword" autoComplete="new-password" type={this.state.showPass ? 'text' : 'password'}></input>
                        </div>
                    </div>
                    <div className="b2 fifty" style={{...centStyle, marginTop: '20px'}}>
                    <button type="submit">Add</button><button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ cr: false })
                    }}>Cancel</button></div>
                </form>}
            </div>
            <div className="b1">
                <label htmlFor="type">Hard Drive:</label>
                <select id="type" value={this.state.hd} onChange={e => this.setState({ hd: e.target.value })} >
                    <option>Select A Hard Drive</option>
                    {this.state.hds?.length > 0 && this.state.hds.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                </select>
            </div>
            <div className="c2">
                <div className="b1">
                    <button style={{ width: '80%' }} onClick={() => this.setState({ page: 0 })}>Back</button>
                </div>
                <div className="b1">
                    <button style={{ width: '80%' }} onClick={() => this.submit()}>Create Title</button>
                </div>
            </div>
        </div>)
        return (<div className="form told b1 formtop">
            <h1 className="formHead">{'Create a Title'}</h1>
            <div className="b1">
                <div className="b1">
                    <label htmlFor="name">Title Name:</label>
                    <input type="text" name="name" placeholder="Enter Title Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })}></input>
                </div>
                <div className="b1">
                    <div className="b1">
                        <div className="c3 wrap">
                            <div style={synStyle}>
                                <label htmlFor="">Short Synopsis:</label>
                                <textarea placeholder="Enter Short Synopsis" value={this.state.shortSynopsis} onChange={e => this.setState({ shortSynopsis: e.target.value })}></textarea>
                            </div>
                            <div style={synStyle}>
                                <label htmlFor="">Medium Synopsis:</label>
                                <textarea placeholder="Enter Medium Synopsis" value={this.state.mediumSynopsis} onChange={e => this.setState({ mediumSynopsis: e.target.value })}></textarea>
                            </div>
                            <div style={synStyle}>
                                <label htmlFor="">Long Synopsis:</label>
                                <textarea placeholder="Enter Long Synopsis" value={this.state.longSynopsis} onChange={e => this.setState({ longSynopsis: e.target.value })}></textarea>
                            </div>
                            <div style={synStyle}>
                                <label htmlFor="type">Media Type:</label>
                                <select id="type" value={this.state.mediaType} onChange={e => this.setState({ mediaType: e.target.value })} >
                                    <option value="feature">Feature Film</option>
                                    <option value="tv">TV Series</option>
                                    <option value="short">Short Film</option>
                                </select>
                            </div>
                            <div style={synStyle}>
                                <label htmlFor="productionYear">Production Year:</label>
                                <select name="productionYear" value={this.state.productionYear} onChange={e => this.setState({ productionYear: e.target.value })}>{Array(150).fill('').map((u, i) => new Date().getFullYear() - i).map((u, i) => (<option key={i} value={u}>{u}</option>))}</select>
                            </div>
                            <div style={synStyle}>
                                <label htmlFor="releaseDate">Release Date:</label>
                                <input type="date" onChange={e => this.setState({ releaseDate: e.target.value })}></input>
                            </div>
                        </div>
                    </div>
                    <div className="b1">
                        <label htmlFor="">Genres:</label>
                        {this.state.genres.length > 0 ? (<div id="selectedGenres" className="b2">{this.state.genres.map((u, i) => (<div key={i} className="selectedGenre" data={u}>{this.upperCase(u)}<div className="xButton" onClick={e => {
                            let d = e.target.parentElement.getAttribute(['data']).toUpperCase()
                            this.setState({ genres: [...this.state.genres].filter(u => u.toUpperCase() !== d) })
                        }}>X</div></div>))}</div>) : (<p>Please select some genres</p>)}
                        <div className="b1" style={centStyle}>
                            <label htmlFor="genre">Select a Genre</label>
                            <select name="genre"><option value="undefined">Select One</option>{this.state.allgenres.length > 0 && [...this.state.allgenres].filter((u) => !this.state.genres.includes(u)).map((u, i) => <option key={i} value={u}>{this.upperCase(u)}</option>)}</select>
                            <button onClick={e => {
                                let val = e.target?.parentElement?.querySelector('select')?.value
                                if (val && val !== 'undefined') this.addGenre(val)
                                console.log(val)
                            }}><i className="fas fa-plus"></i> Add Genre</button>
                        </div>
                    </div>
                </div>
            </div>
            <button style={{ width: '80%', marginTop: '10px', ...centStyle }} onClick={() => {
                if (!this.state.name) return window.flash('Please enter the name of the title.')
                this.setState({ page: 1 })
            }}>Next</button>
        </div>)
    }
}
export default CreateTitle