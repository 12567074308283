import '../css/account.css'

import React, { Component } from 'react'
class ResetPass extends Component {
    state = { email: '', code: null, emailcheck: false, codecheck: false, password: '' }
    render() {
        console.log(this.state)
        return (<div className="b1" style={{margin: 0}}>
            <div id="logoStacked" className="b1 loginTop">
                <long-logo color="var(--l)"></long-logo>
                <style-hidden></style-hidden>
            </div>
            {!this.state.emailcheck ? (<div id="changePass" className="b1">
                <form className="b1" onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => { warn.innerHTML = e; setTimeout(() => warn.innerHTML = '', 3000) }
                    let email = this.state.email
                    if (!email || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) return warning('Invalid email.')
                    warning('Sending reset request.')
                    window.app.request('/request-reset', 'post', { email }).then(r => {
                        warning('')
                        form.reset()
                        if (r.error) return warning(r.message || JSON.stringify(r))
                        this.setState({ emailcheck: true })
                    }).catch(e => warning(e))
                }}>
                    <span className="warn"></span>
                    <div className="b1">
                        <label htmlFor="email">Please enter your email address below to change your password.</label>
                        <input type="email" name="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}></input>
                    </div>
                    <button type="submit">Request Password Reset</button>
                </form>
            </div>) : !this.state.codecheck ? (<div id="changePass" className="b1">
                <form className="b1" onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => warn.innerHTML = e
                    let { code, email } = this.state
                    if (!email) return this.setState({ emailcheck: false })
                    if (!code) return warning('Please enter the code from your email.')
                    warning('Validating code.')
                    window.app.request('/validate-code', 'POST', { code, email }).then(r => {
                        if (r.error) return warning(r.message || JSON.stringify(r))
                        warning('')
                        form.reset()
                        this.setState({ codecheck: true })
                    }).catch(e => warning(e))
                }}>
                    <span className="warn"></span>
                    <div className="b1">
                        <label htmlFor="p2">Please enter the code from your email below.</label>
                        <input type="text" name="code" value={this.state.code} onChange={e => this.setState({ code: e.target.value })}></input>
                    </div>
                    <button type="submit">Submit</button>
                </form>
                <button onClick={() => this.setState({ emailcheck: false })}>BACK</button>
            </div>) : (<div id="changePass" className="b1">
                <form className="b1" onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => warn.innerHTML = e
                    let { code, email, password } = this.state
                    if (!email || !code) return this.setState({ emailcheck: false, codecheck: false })
                    let p2 = form['p2'].value
                    if (!password) return warning('Please choose a new password.')
                    if (!p2) return warning('Please confirm the new password.')
                    if (password.length < 8) return warning('Password must be 8 characters.')
                    if (password !== p2) return warning('Passwords must match.')
                    window.app.request('/reset-password', 'POST', { email, code, password }).then(r => {
                        if (r.error) return warning(r.message || JSON.stringify(r))
                        form.reset()
                        warning('Success!')
                        setTimeout(() => warning(''), 3000)
                        window.redirect('/login')
                    }).catch(e => warning(e))
                }}>
                    <span className="warn"></span>
                    <div className="b1">
                        <label htmlFor="p1">New Password</label>
                        <input type="password" name="password" value={this.state.password} onChange={e => this.setState({ password: e.target.value })}></input>
                    </div>
                    <div className="b1">
                        <label htmlFor="p2">Confirm New Password</label>
                        <input type="password" name="p2"></input>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>)}
            <div className="b1" style={{ marginTop: '100px' }}>
                <a href={"/"}><button>Back to homepage</button></a>
            </div>
        </div>)
    }
}
export default ResetPass