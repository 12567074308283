import React, { Component } from 'react';
class Error404 extends Component {
   render() {
        window.home = this
        return (<div className="b1">
            <i className="fas fa-exclamation fa-5x" style={{opacity:0.6, padding:'1em'}}></i>
            <h4>404</h4>
            Page not found
        </div>)
    }
}
export default Error404