import React, { Component } from 'react'
import Note from './helper/note'
class Notes extends Component {
    state = { add: false, pastNote: '' }
    render() {
        if (this.state.add) return (<div id="notesSection" className="b1">
            <form onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                let form = e.target
                let note = form['note'].value
                this.props.add(note)
                this.setState({ pastNote: note, add: false })
            }}>
                <h3>Add a Note</h3>
                <textarea name="note" defaultValue={this.state.pastNote || ''} placeholder="Add your notes here."></textarea>
                <button type="submit">Add Note</button>
            </form>
        </div>)
        if (this.props.notes?.length > 0) {
            return (<div className="c1 rel" id="notesSection">
                {this.props.notes.map((u, i) => (<Note key={i} _id={u._id} remove={this.props.remove} note={u.note} user={u.user} date={u.date}></Note>))}
                <button onClick={() => { this.setState({ add: true }) }}><i className="fas fa-plus"></i>  New Note</button>
            </div>)
        } else {
            return (<div className="b1" id="notesSection"><h3>No Notes Added</h3><button onClick={() => { this.setState({ add: true }) }}><i className="fas fa-plus"></i>  New Note</button></div>)
        }
    }
}
export default Notes