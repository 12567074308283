import React, { Component } from 'react'
import Spinner from '../helper/spinner'
import Pagination from '../helper/pagination'
import Preview from '../helper/preview'
import File from '../file/file'
import SearchBar from '../helper/searchbar'
import TagList from '../helper/taglist'
import '../css/upload.css'
import FileStatus from '../file/filestatus'
class MyUploads extends Component {
    state = {
        uploads: [],
        viewed: false,
        page: 0,
        viewing: '',
        count: 12
    }
    getUploads() {
        if (window.ws && window.ws.readyState === 1) window.ws.sendData({ type: 'my-uploads' })
    }
    componentDidMount() {
        this.getUploads()
        this.uploadChecker = setInterval(() => this.getUploads(), 1000)
        setTimeout(() => {
            this.setState({ viewed: true })
        }, 3000)
    }
    componentWillUnmount() {
        clearInterval(this.uploadChecker)
    }
    hideMyUploads() {
        window.home.setState({ view: false })
    }
    render() {
        window.myUploads = this
        if (this.state.viewing) return (<div id="file" className="b1 rel"><button className="close" onClick={() => this.setState({ viewing: '' })}><i className="fas fa-times"></i></button><File file={this.state.uploads.find(u => u._id === this.state.viewing)}></File></div>)
        return (<div className="full b1 rel">
            <div className="options"><SearchBar items={this.state.uploads} indexes={['_id', 'name']} mainKey={'_id'} template={(u, i) => (<div className="jsb searchResult" key={i} id={u._id}>
                <h3 id={u._id} onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ viewing: e.target.id })
                }}>{u.name}</h3>
                <span>{new Date(u.dateAdded).toLocaleDateString()}</span>
            </div>)} /></div>
            <h2>My Uploads</h2>
            {window.app.state.location !== '/my-uploads' && <button style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={this.hideMyUploads}><i className="fas fa-times"></i></button>}
            <ul className="c4">
                {this.state.uploads?.length > 0 ? window.index([...this.state.uploads], this.state.page, this.state.count).map((u, i) => (<li key={i} className="b1 myupload">
                    <h2 style={{wordBreak: 'break-all'}}>{u.name}</h2>
                    <FileStatus file={u} />
                    <Preview link={u.link} size={u.stats.size} type={u.type} />
                    <TagList tags={u.tags}></TagList>
                    {(window.DESKTOP && u.path) ? <button id={u._id} onClick={e => window.app.handleDownload(e.target.id)}>Save File</button> : u.link && <a href={u.link} target="_blank" rel="noreferrer"><button style={{ position: 'absolute', top: 0, right: 0, }}><i className="fas fa-download"></i></button></a>}
                </li>)) : (<div className="b1" style={{ gridArea: '1 / 1 / span 1 / span 3' }}>{!this.state.viewed ? (<div className="b1"><h3>Loading Past Uploads</h3><Spinner></Spinner></div>) : (<h3>You haven't uploaded any files yet.</h3>)}</div>)}
            </ul>
            <Pagination set={e => this.setState({ page: e })} total={this.state.uploads?.length || 0} count={this.state.count} page={this.state.page} />
        </div>)
    }
}
export default MyUploads