import React, { Component } from 'react'
import '../css/title.css'
import Accordian from '../helper/accordian.js';
import FileManager from '../file/filemanager'
import Packages from '../package/packages';
import HD from '../hd/HD';
class Title extends Component {
    state = {
        title: '',
        metadata: {},
        titleID: '',
        keyArt: '',
        view: 'files',
        edit: false
    }
    editing = false
    componentDidMount() {
        console.log(this.props.title)
        if (!this.props.title) return
        this.initTitle()
    }
    componentDidUpdate() {
        if (this.props.title._id !== this.state.titleID) this.initTitle()
        if (this.state.edit !== this.editing) {
            this.editing = this.state.edit
            if (this.state.edit === false) this.submit()
        }
    }
    initTitle() {
        let { metadata, title, _id, files, keyArt, hardDrive } = this.props.title
        this.getMetadata(metadata)
        this.getFiles(files)
        this.getHDs()
        this.setState({ title, titleID: _id, keyArt, hardDrive })
    }
    arrayString(string) {
        return string.split(',').map(u => u.trim())
    }
    submit(e) {
        e.preventDefault()
        e.stopPropogation()

    }
    getHDs() {
        window.app.request(`/hard-drives`, 'post').then(result => this.setState({ hds: result.HD })).catch(e => console.log(e))
    }
    getMetadata(meta) {
        window.app.request(`/metadata?_id=${meta}`, 'post').then(result => {
            this.setState({ metadata: result.meta, gotMetadata: true })
        }).catch(e => console.log(e))
    }
    getFiles(files) {
        window.app.request(`/files`, 'post', { files }).then(result => {
            console.log(result)
            this.setState({ files: result.files })
        }).catch(e => console.log(e))
    }
    defaultTags = ['still', 'music cue sheet']
    sortByTags(files) {
        var sorted = []
        if (files) for (let i = 0; i < files.length; i++) {
            var file = files[i]
            if (!file?.tags) continue
            for (let z = 0; z < file.tags?.length || 0; z++) {
                var objIndex = sorted.findIndex((obj => obj.tag == file.tags[z]));
                if (objIndex >= 0) {
                    sorted[objIndex].files.push(file)
                } else {
                    sorted.push({
                        tag: file.tags[z],
                        files: [file],
                        visible: this.defaultTags.includes(file.tags[z].toLowerCase())
                    })
                }
            }
        }
        return sorted
    }
    links = {
        imdb: "https://imdb.com/title/",
        vudu: "https://vudu.com/content/movies/details/title/",
        youtube: "https://youtu.be/",
        apple: "https://tv.apple.com/movie/umc.cmc.",
        amazon: "https://amazon.com/dp/",
        google: "https://play.google.com/store/movies/details?id=",
        fandango: "https://fandangonow.com/details/movie/",
        roku: "https://roku.com/whats-on/movies/?id=",
        itunes: "https://itunes.apple.com/us/movie/",
        microsoft: "https://microsoft.com/p/p/",
        fye: "https://fye.com/",
        walmart: "https://walmart.com/ip/",
        bestbuy: "https://bestbuy.com/site/f/",
        ebay: "https://ebay.com/itm/",
        redbox: "https://redbox.com/ondemand-movies/"
    }
    mapMeta(array) {
        return array.map((u, i) => (<li key={i}><strong style={{ textTransform: 'capitalize' }}>{u}:</strong> <input className="wide" type='text' name={u} value={this.state.metadata[u]} onChange={e => this.setState({
            metadata: (() => {
                let a = { ...this.state.metadata }
                a[e.target.name] = e.target.value
                return a
            })()
        })}></input></li>))
    }
    metaMap(array) {
        let linkKeys = Object.keys(this.links)
        return array.filter(u => this.state.metadata[u]).map((u, i) => {
            if (linkKeys.includes(u)) {
                return (<li key={i}><strong style={{ textTransform: 'capitalize' }}>{u}:</strong> <a href={this.links[u] + this.state.metadata[u]} target="_blank" rel="noreferrer">{u}</a></li>)
            } else {
                return (<li key={i}><strong style={{ textTransform: 'capitalize' }}>{u}:</strong> {(typeof this.state.metadata[u] === 'object' && this.state.metadata[u].length > 0) ? this.state.metadata[u].join(', ') : this.state.metadata[u]}</li>)
            }

        })
    }
    submit() {
        let warning = e => window.flash(e)
        window.app.request('/update-metadata', 'post', { metadata: this.state.metadata }).then(result => {
            if (result.error) return warning(result.message || JSON.stringify(result))
            this.setState({ edit: false, metadata: result.metadata })
        }).catch(e => { console.log(e); window.flash(e) })
    }
    render() {
        window.TITLE = this
        return (<div className="full">
            <div id="titleInfo">
                <div className="b2 fifty" style={{ justifyContent: 'flex-start' }}>
                    <h1>{this.state.title.toUpperCase()}</h1>
                    {window.titles && <span id="backtotitles" onClick={() => {
                        window.titles.setState({ viewing: '' })
                    }}>Return to titles</span>}
                </div>
                <div id="titleoption" className="b2 fifty" style={{ padding: '20px', justifyContent: 'flex-end' }}>
                    <h3 onClick={e => this.setState({ view: 'files' })}>Files</h3>
                    <h3 onClick={e => this.setState({ view: 'packages' })}>Packages</h3>
                    <h3 onClick={e => this.setState({ view: 'metadata' })}>Metadata</h3>
                    <h3 onClick={e => this.setState({ view: 'hd' })}>Hard Drive</h3>
                </div>
                { this.state.view === 'metadata' && window.isInternal() && <span>Edit Mode: <input type='checkbox' onInput={(e) => this.setState({ edit: e.target.checked })} /></span>}
            </div>
            {this.state.view === 'packages' ? (<Packages title={this.props.title?._id}/>) : this.state.view === 'hd' ? (this.state.hardDrive ? (<div className="b1 pushed"><HD hd={this.state.hardDrive} /></div>) : window.isInternal() ? (<div className="b1">
                <form className="b1" onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => warn.innerHTML = e
                    let title = this.state.titleID
                    if (!title) return warning('Please select a title')
                    let hd = this.state.hd
                    if (!hd) return warning('Please select a hard drive')
                    fetch(window.API + '/hd/associate-drive-title', { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ hd, title }) }).then(result => result.json()).then(result => {
                        if (result.error) return warning(result.message || JSON.stringify(result))
                        this.setState({ hardDrive: result.hd._id })
                    }).catch(e => {
                        console.log(e)
                        warning(e)
                    })
                }}>
                    <span className="warn"></span>
                    <div className="b1">
                        <label htmlFor="type">Hard Drive:</label>
                        <select id="type" value={this.state.hd} onChange={e => this.setState({ hd: e.target.value })} >
                            <option>Select A Hard Drive</option>
                            {this.state.hds?.length > 0 && this.state.hds.map((u, i) => (<option key={i} value={u._id}>{u.name}</option>))}
                        </select>
                    </div>
                    <button type="submit">Associate</button>
                </form>
            </div>) : (<div className="b1"><h3>No Hard Drive Added</h3></div>)) : this.state.view === 'metadata' ? (<div id="titleMetadata">
                <div className="b1 pushed">
                    <img className="poster" src={this.state.keyArt ? this.state.keyArt : window.API+'/static/poster.png'} alt={this.state.title + " Poster"}></img>
                    {this.state.keyArt && <span style={{cursor: 'pointer'}} onClick={e => {
                        window.app.request('/remove-title-key-art', 'post', {title: this.state.titleID}).then(result => {
                            if (!result || result.message) return window.flash(result?.message || JSON.stringify(result))
                            return this.setState({keyArt: ''})
                        }).catch(e => window.flash(e))
                    }}>Remove Title Key Art</span>}
                </div>
                <div className="b1 pushed">
                    <h2>Metadata</h2>
                    <div className="b1" style={{ justifyContent: 'flex-start!important' }}>
                        <Accordian open={this.state.edit} items={[
                            {
                                title: 'Credits',
                                inners: (<div className="b2">
                                    <ul>
                                        {this.state.edit ? this.mapMeta(['director', 'producers', 'writers', 'cast', 'cinematography', 'music']) : this.metaMap(['director', 'producers', 'writers', 'cast', 'cinematography', 'music'])}
                                    </ul>
                                </div>)
                            }, {
                                title: 'Specs',
                                inners: (<div className="b2">
                                    <ul>
                                        {this.state.edit ? this.mapMeta(['audio', 'fps', 'runtime', 'cuepoints']) : this.metaMap(['audio', 'fps', 'runtime', 'cuepoints'])}
                                    </ul>
                                </div>)
                            }, {
                                title: 'Sales',
                                inners: (<div className="b2">
                                    <ul>
                                        {this.state.edit ? this.mapMeta(['keywords', 'genre', 'synopsis']) : this.metaMap(['keywords', 'genre', 'synopsis'])}
                                    </ul>
                                </div>)
                            }, {
                                title: 'Streaming Links',
                                inners: (<div className="b2">
                                    <ul className="social">
                                        {this.state.edit ? this.mapMeta(Object.keys(this.links)) : this.metaMap(Object.keys(this.links))}
                                    </ul>
                                </div>)
                            }, {
                                title: 'Social',
                                inners: (<div className="b2">
                                    <ul className="social">
                                        {this.state.edit ? this.mapMeta(['facebook', 'instagram', 'twitter']) : this.metaMap(['facebook', 'instagram', 'twitter'])}
                                    </ul>
                                </div>)
                            }]}></Accordian>
                    </div>
                </div>
            </div>) : (<FileManager title={this.props.title?._id} />)}
        </div>)
    }
}
export default Title