function DarkMode(){
    return (<style>
        {`
    :root {
        --d: #ffffff;
        --dw: #000200;
        --p: #0f245b;
        --po: #0f245b80;
        --w: #d67e1e;
        --s: #ba9877;
        --g: #d7d7d7;
        --a: #efefef;
        --b: #333333;
        --r: #dc3545;
        --gr: #48d6b3;
        --f: #EAD87D;
        --l: #333333;
        --delivered: rgb(44, 160, 62);
        --processing: rgb(234, 141, 249);
        --review: rgb(249, 206, 141);
        --marketing: rgb(141, 152, 249);
        --onboarding: #409fae;
        --radius: 8px;
        --border: 2px solid var(--p);
        --lightBorder: 1px solid var(--p);
        --transBorder: 2px solid transparent;
        --shadow: 1px 2px 3px #00000040;
        --transitionIn: 300ms ease-in;
        --transitionOut: 250ms ease-out;
    }
    button, h3, span, h2, p, h1, div {
        color: var(--d)!important;
    }
    #searchbarInput::placeholder {
        color: var(--d);
    }
    .activebutton {
        color: var(--l);
        background-color: var(--d);
    }
    *.active {
        color: var(--l)!important;
        background-color: var(--d)!important;
    }
    #searchbar:before {
        color: var(--d);
    }
    #notificationIcon path {
        fill: var(--d);
    }
    .selective {
        color: var(--l)!important;
    }
    .selective:hover {
        color: var(--d)!important;
    }
    `}
    </style>)
}
export default DarkMode