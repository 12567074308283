function FileStatus(props) {
    let parentClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.target.parentElement?.click()
    }
   
    return (<div onClick={props.onClick || parentClick} className="status">
    {props.file?.uploadId ? 
                    <><i onClick={parentClick} className="fas fa-circle" style={{color:'var(--s)'}}></i>{!props.short?<span>Processing</span>:""}</> : 
                    props.file?.serverPath ? 
                    <><i onClick={parentClick}  className="fas fa-exclamation" style={{color:'var(--warn)'}}></i>{!props.short?<span>Error File</span>:""}</> 
                    : props.file?.link && !props.file?.path ? 
                    <><i onClick={parentClick}  className="fas fa-circle" style={{color:'var(--gr)'}}></i>{!props.short?<span>Available for Download</span>:""}</> : 
                    props.file?.link && props.file?.path ? 
                    <><i onClick={parentClick}  className="fas fa-sync"></i>{!props.short?<span>Synced</span>:""}</> : 
                    !props.file?.link && props.file?.path ? 
                    <><i onClick={parentClick}  className="fas fa-circle" style={{color:'var(--gr)'}}></i>{!props.short?<span>On NAS</span>:""}</> : 
                    <><i onClick={parentClick}  className="fas fa-exclamation" style={{color:'var(--g)'}}></i>{!props.short?<span>Unknown</span>:""}</>
                    }
</div>)
}
export default FileStatus