import React, { Component } from 'react';
import Spinner from '../helper/spinner'
import '../css/upload.css'
class Upload extends Component {
  state = {
    progress: 0,
    show: false,
    acceptedFiles: [],
    acceptedFolders: [],
    title: '',
    titles: []
  };
  componentDidMount() {
    if (this.props.title) this.setState({ title: this.props.title })
  }
  images = ['image/png', 'image/jpeg', 'image/webp', 'image/svg+xml']
  fileImage = 'https://docs.microsoft.com/en-us/windows/apps/design/signature-experiences/images/iconography_anatomy1.svg'
  files = ['video/mp4', 'font/woff']
  defaultImage = 'https://cdn3.iconfinder.com/data/icons/files-buttons/512/File_Settings-512.png'
  async desktopUpload(e){
    e.preventDefault()
    e.stopPropagation()
    let form = e.target
    let button = form.querySelector('#uButton')
    let warning = e => {
      let warn = form.querySelector('.warn')
      warn.innerHTML = e
      warn.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => warn.innerHTML = '', 5000)
    }
    let missed = 0
    let missedUpload = e => {
        missed++
        window.flash(e)
    }
    let { title, acceptedFiles, acceptedFolders } = this.state
    let directory = this.props.directory
    let uploadQue = [...acceptedFiles, ...acceptedFolders]
    window.app.handleUpload(title, uploadQue, directory).then(result => {
      if (result?.error) return missedUpload(result.message || JSON.stringify(result))
    }).catch(e => {
      missedUpload(`Failed to upload files: ${JSON.stringify(uploadQue)} | ERROR:${JSON.stringify(e)}`)
    })
    if (missed > 0) {
      window.flash('Failed to copy some files')
      form.reset()
      return this.setState({ acceptedFiles: [], acceptedFolders: [] })
    } else {
        this.setState({ acceptedFiles: [], acceptedFolders: [] })
        form.reset()
        window.flash('Copied all files')
    }
  }
  uploadFiles(e) {
    e.preventDefault()
    e.stopPropagation()
    if (this.uploading) return
    let form = e.target
    let button = form.querySelector('#uButton')
    let warning = e => {
      let warn = form.querySelector('.warn')
      warn.innerHTML = e
      warn.scrollIntoView({ behavior: 'smooth' })
      setTimeout(() => warn.innerHTML = '', 5000)
    }
    if (!form['title'] || !form['title'].value || form['title'].value === 'undefined') return warning('Please select a title for upload')
    this.uploading = true
    button.innerHTML = 'Uploading File'
    this.setState({ show: true })
    let fd = new FormData(form)
    fd.append('files', this.state.acceptedFiles)
    fd.append('folders', this.state.acceptedFolders)
    let er = e => {
      this.setState({ show: false, progress: 0 })
      window.flash('File Transfer Error: ' + (e.message || JSON.stringify(e)), 8000, '#a75353')
      button.innerHTML = 'Upload'
      this.uploading = false
      return warning(e.message || JSON.stringify(e))
    }
    let that = new XMLHttpRequest()
    let timeStarted = new Date()
    let onProgress = (evt) => {
      if (evt.lengthComputable) {
        let percent = parseInt((evt.loaded / evt.total) * 100);
        let timeElapsed = ((new Date()) - timeStarted)/1000
        let uploadSpeed = evt.loaded / (timeElapsed)
        let remaining = ((evt.total - evt.loaded) / uploadSpeed)
        this.setState({ progress: percent, uploadSpeed, timeRemaining: remaining, timeElapsed, loaded: evt.loaded, total: evt.total })
        if (percent === 100) warning('Processing upload, please wait')
      }
    }
    that.upload.onprogress = onProgress
    that.upload.onload = onProgress
    that.onerror = e => er(e)
    that.onabort = e => er(e)
    that.onreadystatechange = e => {
      if (that.readyState === 4) {
        let response
        try {
          response = JSON.parse(that.responseText)
        } catch(e) {
          return er(e)
        }
        if (response.error) return er(response)
        this.setState({ show: false, acceptedFiles: [], acceptedFolders: [], progress: 0 })
        warning('Upload complete')
        window.flash("Upload Complete", 5000, '#00ef00');
        form.reset()
        if (window.filemanager) window.filemanager.setState({uploader: false})
        button.innerHTML = 'Upload'
        this.uploading = false
      }
    }
    that.open('POST', window.API + "/upload", true)
    that.setRequestHeader('authorization', window.app.state.auth)
    that.setRequestHeader('userid', window.app.state.userID)
    that.send(fd)
  }
  onDrop(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    let input = document.querySelector('#importantFiles')
    input.files = ev.dataTransfer.files;
    let f = []
    if (ev.dataTransfer.items) {
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          console.log('... file[' + i + '].name = ' + file.name);
          f.push(file)
        }
      }
    }
    this.setState({ acceptedFiles: f })
  }
  dragOver(e) {
    e.stopPropagation();
    e.preventDefault()
    let a = document.querySelector('#drop_zone')?.querySelector('span')
    if (a) a.innerHTML = 'Drop files here'
  }
  dragStop(e) {
    e.stopPropagation();
    e.preventDefault()
    console.log('exit')
    let a = document.querySelector('#drop_zone')?.querySelector('span')
    if (a) a.innerHTML = this.state?.acceptedFiles?.length > 0 ? `Selected ${this.state.acceptedFiles.length} Files` : `Drag Files Here or Click to Select Files`
  }
  sort(list) {
    let a = [...list].map(u => u.title.toUpperCase()).sort()
    let b = []
    for (let i = 0; i < list.length; i++) {
      for (let z = 0; z < a.length; z++) {
        if (new RegExp(list[i].title, 'i').test(a[z]) && !b[z]) {
          b[z] = list[i]
          z = Infinity
        }
      }
    }
    return b
  }
  render() {
    window.uploader = this
    return (
      <div className="b1 pushed rel">
        <h1>Upload Files</h1>
        <form className="b1" encType="multipart/form-data" onSubmit={e => { window.DESKTOP ? this.desktopUpload(e) : this.uploadFiles(e) }} >
          <span className="warn"></span>
          {this.props.directory && <input type="hidden" name="directory" value={this.props.directory} />}
          {this.props.title ? <input type="hidden" value={this.props.title} name="title"/> : <div className="b1"><label htmlFor="title">{window.app?.state?.titles ? 'Select' : 'Enter'} the title you will be uploading files for:</label>
          {window.app?.state?.titles ? <select name="title" value={this.state.title} onChange={e => this.setState({ title: e.target.value })}><option value="undefined">Select a Title</option>{this.sort([...window.app.state.titles]).map((u, i) => (<option key={i} value={u._id}>{u.title}</option>))}</select> : <input onChange={e => this.setState({ title: e.target.value })} value={this.state.title} type="text" name="title"></input>}</div>}
          <div className="b1">
            {this.state.timeRemaining && <div className="b1"><h3>Uploading Files</h3><span><strong>Estimated time remaining:</strong> {this.state.timeRemaining < 60 ? `${Math.floor(this.state.timeRemaining)} seconds` : this.state.timeRemaining < 60*60 ? `${Math.floor(this.state.timeRemaining/60)} minutes` : this.state.timeRemaining < 60*60*24 ? `${Math.floor(this.state.timeRemaining/60/60)} hours` : `${Math.floor(this.state.timeRemaining/60/60/24)} days`} | <strong>It has been:</strong> {this.state.timeElapsed < 60 ? `${Math.floor(this.state.timeElapsed)} seconds` : this.state.timeElapsed < 60*60 ? `${Math.floor(this.state.timeElapsed/60)} minutes` : this.state.timeElapsed < 60*60*24 ? `${Math.floor(this.state.timeElapsed/60/60)} hours` : `${Math.floor(this.state.timeElapsed/60/60/24)} Days`} | <strong>Average Upload Speed:</strong> {window.fileSize(this.state.uploadSpeed)}/s</span></div>}
            {this.state.show ? <Spinner></Spinner> : ''}
          </div>
          <div className={(this.state.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) && !this.state.show ? 'c2' : "b1"} style={{ margin: '15px 0' }}>
            {(this.state.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) && <ul className="grid" id="selectedFiles"><div id="selectedFileInfo"><h2>Selected Files</h2></div>{[...this.state.acceptedFiles, ...this.state.acceptedFolders].map((file, i) => (<li key={i} className="uploadedFile b1">
              <img className="meg" src={this.images.includes(file.type) && file.size < 1000 * 1000 ? URL.createObjectURL(file) : this.files.includes(file.type) ? this.fileImage : this.defaultImage} alt="fileImage"></img>
              <p><b>{file.name}</b> - {window.fileSize(file.size)}</p>
            </li>))}</ul>}
            <div className={"progress" + (this.state.show ? '' : ' hidden')}>
              <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: this.state.progress + '%' }}>
                {this.state.progress + "%"} Complete
              </div>
            </div>
            <div className={this.state.show ? 'hidden' : "b1"}>
              <div className="b1" id="drop_zone" onDrop={e => this.onDrop(e)} onDragExit={e => this.dragStop(e)} onMouseEnter={e => {
                let a = document.querySelector('#drop_zone')?.querySelector('span')
                if (a) a.innerHTML = 'Click to select files'
              }} onMouseLeave={e => {
                let a = document.querySelector('#drop_zone')?.querySelector('span')
                if (a) a.innerHTML = (this.state?.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) ? `Selected ${this.state.acceptedFiles.length + this.state.acceptedFolders.length} Files` : `Drag Files Here or Click to Select Files`
              }} onDragOver={e => this.dragOver(e)} onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                document.querySelector('#importantFiles').click()
              }}>
                <span style={{ cursor: 'pointer' }}>{(this.state?.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) ? `Selected ${this.state.acceptedFiles.length + this.state.acceptedFolders.length} Files` : `Drag Files Here or Click to Select Files`}</span>
                <i className="fas fa-file-upload"></i>
              </div>
                <input id="importantFiles" name="files" className="hidden" type="file" accept="*" multiple={true} onChange={e => { this.setState({ acceptedFiles: [...e.target.files] }) }} ></input>
                <input id="importantFolders" name="folders" className="hidden" type="file" accept="*" webkitdirectory="true" multiple={true} onChange={e => { this.setState({ acceptedFolders: [...e.target.files] }) }} ></input>
              <div className="c2">
                <div className="b1"><button onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  document.querySelector('#importantFiles').click()
                }}><i className="fas fa-file"></i> &nbsp;Select Files</button></div>
                <div className="b1"><button onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  document.querySelector('#importantFolders').click()
                }}><i className="fas fa-folder"></i> &nbsp;Select Folders</button></div>
              </div>
            </div>
          </div>
          <div className="b1">{(this.state.acceptedFiles?.length > 0 || this.state.acceptedFolders?.length > 0) && <button id="uButton" type="submit" className="green-btn"><i className="fas fa-upload"></i> &nbsp;UPLOAD FILES</button>}</div>
        </form>
      </div>
    );
  }
}
export default Upload
