import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import Upload from "./components/upload/uploader.js";
import MyUploads from './components/upload/myuploads'
import HomePage from './components/homepage'
import Titles from "./components/title/titles.js";
import Title from './components/title/title'
import File from './components/file/file'
import Package from './components/package/package'
import Packages from './components/package/packages'
import CreatePackage from './components/package/create-package'
import CreateTitle from './components/title/create-title'
import HD from './components/hd/HD'
import HDlist from './components/hd/HDlist'
import NewUser from './components/account/newuser'
import NewHD from './components/hd/newhd'
import Error404 from './components/404.js'
import Users from './components/users'
import Settings from './components/settings'
import FileManager from './components/file/filemanager.js';
import Policy from './policy.js';

import Documents from './Document.js';
import config from './config.js';
import SopEditor from './sop.js';
const URL_REDIRECT=config.URL_REDIRECT
class Home extends Component {
    state = {
        view: false,
        innerWidth: window.innerWidth
    }
    resize = (e) => {
        this.setState({ innerWidth: window.innerWidth })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
        //window.app?.getTitles()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    maxStyle = { maxHeight: '100vh', display: 'inline-flex', flexDirection: 'column', overflowX: 'hidden', maxWidth: '100%', width: '100%', margin: 0, padding: 0, overflowY: 'scroll', alignItems: 'center', justifyContent: 'flex-start', height: 'auto', position: 'fixed', top: 0, left: 0, backgroundColor: 'var(--g)', zIndex: 10 }
    render() {
        window.home = this
        return (<Switch>
            <Route exact path={`${URL_REDIRECT}/`}>
                <HomePage innerWidth={this.state.innerWidth} />
            </Route>
            <Route exact path={`${URL_REDIRECT}/upload`}>
                <div className="b1" style={{ position: 'relative' }}>
                    {!this.state.view && <button className="fixedButton" onClick={e => {
                        this.setState({ view: !this.state.view })
                    }}>{'Show Past Uploads'}</button>}
                    <div className="b1"><Upload /></div>
                    {this.state.view && <div id="pastUploads" style={this.maxStyle}><MyUploads></MyUploads></div>}
                </div>
            </Route>
            <Route exact path={`${URL_REDIRECT}/my-uploads`} component={MyUploads}></Route>
            <Route exact path={`${URL_REDIRECT}/title`}><Title title={this.props.titles.find(u => u._id === window.app.queries['_id'])}></Title></Route>
            <Route exact path={`${URL_REDIRECT}/create-package`} component={CreatePackage}></Route>
            <Route exact path={`${URL_REDIRECT}/create-title`} component={CreateTitle}></Route>
            <Route exact path={`${URL_REDIRECT}/packages`} component={Packages}></Route>
            <Route exact path={`${URL_REDIRECT}/package`} component={Package}></Route>
            <Route exact path={`${URL_REDIRECT}/download-package`} component={Package}></Route>
            <Route exact path={`${URL_REDIRECT}/file`} component={File}></Route>
            <Route exact path={`${URL_REDIRECT}/titles`} ><Titles titles={this.props.titles} /></Route>
            <Route exact path={`${URL_REDIRECT}/hds`} component={HDlist}></Route>
            <Route exact path={`${URL_REDIRECT}/hard-drive`} component={HD}></Route>
            <Route exact path={`${URL_REDIRECT}/new-hd`} component={NewHD}></Route>
            <Route exact path={`${URL_REDIRECT}/new-user`} component={NewUser}></Route>
            <Route exact path={`${URL_REDIRECT}/files`} component={FileManager}></Route>
            <Route exact path={`${URL_REDIRECT}/user-list`} component={Users}></Route>
            <Route exact path={`${URL_REDIRECT}/settings`} component={Settings}></Route>
            <Route exact path={`${URL_REDIRECT}/policy`} component={Policy}></Route>
            <Route exact path={`${URL_REDIRECT}/sop`} component={SopEditor}></Route>
            <Route exact path={`${URL_REDIRECT}/document`} component={Documents}></Route>
            {this.state.type === 'admin' && <Route exact path={`${URL_REDIRECT}/admin-features`}>
                <div className="b1 n">
                    <h1>Admin Features</h1>
                    <span id="aWarn" className="warn"></span>
                    <input id="auth" type="password"></input>
                    {this.admin ? (<div className="b1">
                        <button onClick={() => fetch(window.API + '/delete-all-hds', { method: 'POST', body: JSON.stringify({ auth: document.getElementById('auth')?.value, userID: localStorage.getItem('_id') }), headers: { 'Content-Type': 'application/json' } }).then(u => u.json()).then(r => {
                            let w = document.getElementById('aWarn')
                            let warn = e => w.innerHTML = e
                            if (r.error) warn(r.message || JSON.stringify(r))
                        }).catch(e => console.log(e))}>DELETE ALL HDS</button>
                        <button onClick={() => fetch(window.API + '/delete-all-users', { method: 'POST', body: JSON.stringify({ auth: document.getElementById('auth')?.value, userID: localStorage.getItem('_id') }), headers: { 'Content-Type': 'application/json' } }).then(u => u.json()).then(r => {
                            let w = document.getElementById('aWarn')
                            let warn = e => w.innerHTML = e
                            if (r.error) warn(r.message || JSON.stringify(r))
                        }).catch(e => console.log(e))}>DELETE ALL USERS</button>
                        <button onClick={() => fetch(window.API + '/delete-all-checkouts', { method: 'POST', body: JSON.stringify({ auth: document.getElementById('auth')?.value, userID: localStorage.getItem('_id') }), headers: { 'Content-Type': 'application/json' } }).then(u => u.json()).then(r => {
                            let w = document.getElementById('aWarn')
                            let warn = e => w.innerHTML = e
                            if (r.error) warn(r.message || JSON.stringify(r))
                        }).catch(e => console.log(e))}>DELETE ALL CHECKOUTS</button></div>) : (<div><a href="/"><button>HOME</button></a></div>)}
                </div>
            </Route>}
            <Route path="*"><Error404 /></Route>
        </Switch>)
    }
}
export default Home