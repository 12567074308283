import React, { Component } from 'react'
import Spinner from '../helper/spinner'
import Notes from '../notes'
import TagList from '../helper/taglist'
import Accordian from '../helper/accordian.js';
import '../css/file.css'
import FileStatus from './filestatus';
import Preview from '../helper/preview';
class File extends Component {
    state = { users: [], taglist: [], notes: [] }
    file = this.props.file && typeof this.props.file !== 'object' ? true : false
    componentDidMount() {
        if (this.props.file && typeof this.props.file === 'object') {
            this.initFile(this.props.file)
        } else {
            this.getFile(this.props.file || window.app.queries['_id'])
        }
        this.getTaglist().then(r => {
            this.setState({ taglist: r })
        }).catch(e => {
            if (window.app.state.location === 'files' || window.app.state.location === 'titles') {
                window.flash(e)
            } else if (window.app.state.location === '/user-list') {
                window.app.request('/taglist').then(result => this.setState({taglist: result.tags})).catch(e => window.flash(e))
            }
        })
    }
    getFile(id) {
        window.app.request(`/files`, 'post', { files: [id] }).then(result => {
            if (!result?.files || result?.error) return this.setState({errored: 'Failed to load file.'})
            let file = result ? result.files[0] : null
            if (!file) return this.setState({errored: 'Missing file'})
            this.initFile(file)
        }).catch(e => this.setState({errored: JSON.stringify(e)}))
    }
    initFile(file) {
        let { _id, name, stats, path, title, tags, expires, dateAdded, uploader, type, link, changeLog, accessLog, notes } = file
        this.setState({ _id, name, stats, path, title, tags, expires, uploader, dateAdded, type, link, changeLog, accessLog })
        if (notes && notes.length > 0) this.getNotes(notes)
    }
    getNotes(list) {
        window.app.request('/notes', 'post', { notes: list }).then(result => {
            this.setState({ notes: result.notes })
        }).catch(e => console.log(e))
    }
    addNote = (note) => {
        if (!note) return
        window.app.request('/add-file-note', 'post', { file: window.file.state._id, note: note }).then(result => {
            if (window.file?.state?.notes) window.file.setState({ notes: [...window.file.state.notes, result.note] })
            if (window.TITLE?.state?.files) window.TITLE.setState({
                files: [...window.TITLE.state.files].map(u => {
                    if (u._id === window.file?.state?._id) u.notes = [...u.notes, result.note._id]
                    return u
                })
            })
        }).catch(e => { window.flash(e); console.log(e) })
    }
    removeNote = (id) => {
        window.app.request('/remove-file-note', 'post', { file: window.file.state._id, note: id }).then(result => {
            if (window.file?.state?.notes) window.file.setState({ notes: [...window.file.state.notes].filter(u => u._id !== id) })
            if (window.TITLE?.state?.files) window.TITLE.setState({
                files: [...window.TITLE.state.files].map(u => {
                    if (u._id === window.file?.state?._id) u.notes = [...u.notes].filter(u => u !== id)
                    return u
                })
            })
        }).catch(e => window.flash(e))
    }
    addTag(tag) {
        return new Promise((res, rej) => {
            window.app.request('/add-tag', 'post', { file: this.state._id, tag: tag.trim() }).then(() => {
                if (!this.state.taglist.includes(tag)) this.setState({ taglist: [...this.state.taglist, tag] })
                this.setState({ tags: [...this.state.tags, tag] })
                res()
            }).catch(e => rej(e))
        })
    }
    removeTag(tag) {
        return new Promise((res, rej) => {
            window.app.request('/remove-tag', 'post', { file: this.state._id, tag }).then(result => {
                this.setState({ tags: [...this.state.tags].filter(u => u !== tag) })
                res()
            }).catch(e => rej(e))
        })
    }
    getTaglist() {
        return new Promise((res, rej) => {
            if (!window.filemanager) return rej('missing file manager')
            if (!window.app?.filemanager?.state?.taglist) {
                window.app.request('/taglist').then(result => {
                    window.filemanager.setState({ taglist: result.tags })
                    res(result.tags)
                }).catch(e => rej(e))
            } else {
                return res(window.filemanager.state.taglist)
            }
        })
    }
    rename(name) {
        return new Promise((res, rej) => {
            window.app.request('/rename-file', 'post', { file: this.state._id, name }).then(result => {
                console.log('renamed', result)
                this.initFile(result.file)
                window.filemanager.setState({
                    files: [...window.filemanager.state.files].map(u => {
                        if (u._id === result.file._id) return result.file
                        return u
                    })
                })
                res(true)
            }).catch(e => rej(e))
        })
    }
    deleteFile() {
        window.app.request('/delete-file', 'post', { file: this.state._id }).then(result => {
            window.filemanager.setState({ files: [...window.filemanager.state.files].filter(u => u._id !== this.state._id), currentFile: null })
        }).catch(e => window.flash(e))
    }
    gettingUser = false
    getUserInfo(id) {
        return new Promise((res, rej) => {
            if (!id) return rej('Missing ID for request')
            let a = this.state.users.find(u => u._id === id)
            if (!a) {
                window.app.request('/user-info', 'post', { _id: id }).then(result => {
                    this.setState({ users: [...this.state.users, result.user] })
                    return res(result.user)
                }).catch(e => rej(e))
            } else {
                return res(a)
            }
        })
    }
    isAlphanumeric = str => /^[0-9a-zA-Z ,'-_]+$/.test(str)
    render() {
        window.file = this
        if (this.state.errored) return (<div id="fileInfo" className="b1"><h3>{this.state.errored}</h3></div>)
        if (!this.state._id) return (<div id="fileInfo" className="b1"><Spinner></Spinner><h3>Loading File</h3></div>)
        if (this.state.rename) return (<div className="b1" id="fileInfo">
            <form className="b1" onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                let form = e.target
                let warn = form.querySelector('.warn')
                let warning = e => { warn.innerHTML = e; setTimeout(() => { warn.innerHTML = '' }, 4000) }
                let name = form['name'].value
                if (!name) return warning('Please enter a valid name')
                if (!this.isAlphanumeric(name)) return warning('Names cannot contain ( ) \\ /')
                this.rename(name).then(() => { form.reset(); this.setState({ rename: false }) }).catch(e => warning(e))
            }}>
                <h3>Rename File</h3>
                <span className="warn"></span>
                <div className="b1">
                    <label htmlFor="name">Rename {this.state.name}</label>
                    <input type="text" placeholder={this.state.name} name="name"></input>
                </div>
                <div className="c2"><button type="submit">Change Name</button><button onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ rename: false })
                }}>Cancel</button></div>
            </form>
        </div>)
        return (<div id="fileInfo" className="b1">
            <div className="b2">
                <FileStatus />
            </div>
            <img className="smallIMG" src={window.fileIcon(this.state.type)} alt="icon" style={{ marginTop: '1em' }}></img>
            <h1>{this.state.name}</h1>
            <Preview type={this.state.type} size={this.state.size} link={this.state.link}/>
            <div className="tags">
                <TagList tags={this.state.tags}></TagList>

                <div className={!this.state.adding && !this.state.removing ? "tagedit" : 'hidden'}><button className='grey-btn' onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ adding: true })
                }}><i className="fas fa-plus"></i> Add Tag</button><button className='grey-btn' onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ removing: true })
                }}>Remove Tag</button></div>
                <form className={!this.state.adding ? "hidden" : 'b1 row'} onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => { warn.innerHTML = e; setTimeout(() => { warn.innerHTML = '' }, 4000) }
                    this.addTag(form['newTag'].value ? form['newTag'].value : form['tag'].value).then(() => {
                        form.reset()
                        this.setState({ adding: false })
                    }).catch(e => {
                        warning(e)
                        window.flash(e)
                    })
                }}>
                    <span className="warn"></span>
                    <div className="col-6">
                        <div className="b1">
                            <label htmlFor="tag"></label>
                            <select name="tag"><option>Select a tag</option>{this.state.taglist?.length > 0 ? this.state.taglist.map((u, i) => (<option key={i} value={u.tag}>{u.tag} ({u.count})</option>)) : ''}</select>
                        </div>
                        <div className="b1">
                            <label htmlFor="newTag"></label>
                            <input type="text" name="newTag" placeholder="Create a tag"></input>
                        </div>
                    </div>
                    <div className="col-6"><button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ adding: false })
                    }} className="red-btn">Cancel</button>
                        <button type="submit" className="green-btn"><i className="fas fa-plus"></i> Add Tag</button></div>
                </form>
                <form className={!this.state.removing ? "hidden" : 'b1'} onSubmit={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    let form = e.target
                    let warn = form.querySelector('.warn')
                    let warning = e => { warn.innerHTML = e; setTimeout(() => { warn.innerHTML = '' }, 4000) }
                    let tag = form['tag'].value || null
                    if (!tag) return warning('Please enter a tag.')
                    this.removeTag(tag).then(() => {
                        form.reset()
                        this.setState({ removing: false })
                    }).catch(e => {
                        warning(e)
                        window.flash(e)
                    })
                }}>
                    <p><strong>Remove a tag from {this.state.name}</strong></p>
                    <span className="warn"></span>
                    <div className="b1"><label htmlFor="tag">Select a tag:</label><select name="tag">{this.state.tags.map((u, i) => (<option key={i} value={u}>{u}</option>))}</select></div>
                    <div className="c2"><button type="submit" className="btn-grey"><i className="fas fa-times"></i> Remove Tag</button><button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ removing: false })
                    }}>Cancel</button></div>
                </form>
            </div>

            <br />
            {window.isInternal() && <Accordian items={[{
                title: 'Metadata', inners: (<div className="b2">
                    <ul>
                        <li><strong>First Added:</strong> {new Date(this.state.dateAdded).toLocaleString()}</li>
                        <li><strong>Uploaded By:</strong> {(() => {
                            let uploaded = this.state.uploader
                            let a = this.state.users.find(u => u._id === uploaded)
                            if (!a && !this.gettingUser) {
                                this.gettingUser = true
                                this.getUserInfo(uploaded).then(() => this.gettingUser = false).catch(e => window.flash(e))
                            }
                            return a ? `${a.firstName} ${a.lastName[0]}.` : uploaded
                        })()}</li>
                        <li><strong>Size:</strong> {window.fileSize(this.state.stats.size)}</li>
                        <li><strong>Type:</strong> {this.state.type}</li>
                    </ul>
                </div>)
            }, { title: 'Notes', inners: (<Notes add={(data) => this.addNote(data)} remove={(id) => this.removeNote(id)} notes={this.state.notes}></Notes>) }]}></Accordian>}
            <div className="b2">
                {window.filemanager && this.state.type === 'image/jpeg' && window.isInternal() && this.state.stats?.size < 1000*1000*2 && <button onClick={() => {
                    window.filemanager.setKeyArt(this.state._id)
                }}>Set As Title Key Art</button>}
                {window.filemanager && window.isInternal() && <button onClick={() => this.setState({ rename: true })} className='grey-btn'><i className="fas fa-pen"></i> Rename File</button>}
                {this.state.path && window.DESKTOP ? (<button id={this.state._id} onClick={e => window.app.handleDownload(e.target.id)}>Save File</button>) : this.state.link && <a href={this.state.link} target="_blank" rel="noreferrer"><button><i className="fas fa-download"></i> Download</button></a>}
                {window.filemanager && window.isInternal() && <button onClick={() => this.deleteFile()} className="red-btn"><i className="fas fa-trash"></i> Delete File</button>}
            </div>
        </div>)
    }
}
export default File