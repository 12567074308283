import React, { Component } from 'react'
import * as JsSearch from 'js-search'
import Spinner from '../helper/spinner'
import SelectDirectory from './selectdirectory';
import TagList from '../helper/taglist';
import '../css/file.css'
import '../css/title.css'
import FileStatus from '../file/filestatus';
import Pagination from '../helper/pagination';
import SearchBar from '../helper/searchbar';
class FileList extends Component {
    state = {
        sortMode: 'default',
        viewMode: 'grid',
        itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12,
        size: window.innerWidth,
        page: 0,
        selectedFiles: [],
        folderCount: window.innerWidth < 650 ? 3 : window.innerWidth < 1080 ? 6 : 8,
        folderPage: 0
    }
    resize = () => {
        this.setState({ size: window.innerWidth, itemCount: window.innerWidth < 650 ? 6 : window.innerWidth < 1080 ? 12 : 18 })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
    componentDidUpdate() {
        if (this.props.selectedFiles.length !== this.state.selectedFiles.length) {
            this.setState({ selectedFiles: this.props.selectedFiles })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    sortList(list) {
        switch (this.state.sortMode) {
            case 'date': {
                return list.sort((a, b) => new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()).reverse()
            }
            default: {
                return [...list].sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : a.name?.toLowerCase() === b.name?.toLowerCase() ? 0 : -1)
            }
        }
    }
    render() {
        return (<div className="b1 pushed">
            {this.props.folders?.length > 0 ? (<div className="popgrid rel">{window.index(this.sortList([...this.props.folders]), this.state.folderPage, this.state.folderCount).map((u, i) => (<div key={i} className={"listFile popitem folder"} onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                window.fileselector.setDirectory(e.target.id)
            }} id={u._id}>
                <h3 onClick={window.fileselector.parentClick}>{u.name}</h3>
            </div>))}</div>) : (<h3>No Subfolders</h3>)}
            <Pagination set={e => this.setState({ folderPage: e })} count={this.state.folderCount} page={this.state.folderPage} total={this.props.folders?.length || 0} />
            {this.props.files?.length > 0 ? (this.state.viewMode === 'grid' ? (<div className="c4 box">
                {typeof this.state.selectedFiles === 'object' && window.index(this.sortList([...this.props.files]), this.state.page, this.state.itemCount).filter(u => u).map((u, i) => (<div key={i} className={"listFile popitem " + (window.createPackage.state.selectedFiles?.includes(u._id) ? 'selectedFile' : '')} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.fileselector.selectFile(e.target.id)
                }} id={u._id}>
                    <h3 onClick={window.fileselector.parentClick}>{u.name}</h3>
                    <FileStatus short onClick={window.fileselector.parentClick} file={u}></FileStatus>
                    <TagList tags={u.tags}></TagList>
                </div>))}</div>) : (<div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={e => this.setState({ sortMode: 'name' })}>Name</th>
                                <th onClick={e => this.setState({ sortMode: 'date' })}>Date</th>
                                <th>Status</th>
                                <th>Tags</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.index(this.sortList([...this.props.files]), this.state.page).filter(u => u).map((u, i) => (
                                <tr key={i} id={u._id} onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    window.fileselector.selectFile(e.target.id)
                                }}>
                                    <td>{u.name}</td>
                                    <td>{window.formatDate(new Date(u.dateAdded).getTime())}</td>
                                    <td><FileStatus></FileStatus></td>
                                    <td><TagList tags={u.tags} max="3"></TagList></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>)) : (<div className="b1"><h3>No Files Listed</h3></div>)}
            <Pagination count={this.state.itemCount} page={this.state.page} total={this.props.files?.length || 0} set={e => this.setState({ page: e })} />
            <span id="fileNotif" ref={this.info} className="hidden"></span>
        </div>)
    }
}
class SelectTitle extends Component {
    state = {
        itemCount: 12,
        page: 0
    }
    render() {
        return (<div className="b1 rel">
            <div className="options">
                <SearchBar template={(u, i) => (<div key={i} className="jsb searchResult" id={u._id} onClick={e => { window.fileselector.setTitle(e.target.id) }} id={u._id}>
                    <h2 onClick={window.fileselector.parentClick} style={{ cursor: 'pointer' }}>{u.title.toUpperCase()}</h2>
                </div>)} indexes={['title']} mainKey={'_id'} item={window.app.state.titles} placeholder="Search Titles" />
            </div>
            <h3>SELECT A TITLE</h3>
            <div className="b1 pushed">
                <div className="c4" id="titleList">
                    {window.app.state.titles?.length > 0 && window.index([...window.app.state.titles].sort((a, b) => a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : a.title?.toLowerCase() === b.title?.toLowerCase() ? 0 : -1), this.state.page, this.state.itemCount).map((u, i) => (<div key={i} id={u._id} onClick={e => { window.fileselector.setTitle(e.target.id) }} id={u._id} className="b1 titleSelect">
                        <span onClick={e => window.fileselector.parentClick(e)} className="date" style={{ cursor: 'pointer' }}>{window.formatDate(new Date(u.dateAdded))}</span>
                        <h2 onClick={e => window.fileselector.parentClick(e)} style={{ cursor: 'pointer' }}>{u.title.toUpperCase()}</h2>
                    </div>))}
                </div>
                <Pagination total={window.app.state?.titles?.length || 0} count={this.state.itemCount} page={this.state.page} set={e => this.setState({ page: e })} />
            </div>
            {window.fileselector.state.selectTitle && <button onClick={() => window.fileselector.setState({ selectTitle: false })}>Close</button>}
        </div>)
    }
}
class FileSelector extends Component {
    state = {
        files: [],
        folders: [],
        titleFolders: [],
        titleFiles: [],
        titles: [],
        currentFolder: null,
        currentFile: null,
        uploader: false,
        title: '',
        selectTitle: false,
        createFolder: false,
        createTitle: false,
        titleName: '',
        selectedFiles: [],
        list: [],
        searching: ''
    }
    componentDidMount() {
        this.initTitle()
    }
    initTitle() {
        this.getTitles().then(result => {
            this.setState({ titles: result.titles, gotTitles: true })
        }).catch(e => console.log(e))
    }
    request(path, method, data) {
        return new Promise((res, rej) => {
            window.app.request(path, method, data).then(result => {
                if (result.error) return rej(result.message || JSON.stringify(result))
                res(result)
            }).catch(e => rej(e))
        })
    }
    getFiles(array) {
        return new Promise((res, rej) => {
            window.app.request('/files', 'post', { files: array }).then(result => res(result.files)).catch(e => rej(e))
        })
    }
    getDirectory(id) {
        return new Promise((res, rej) => {
            window.app.request(`/directory?_id=${id}&files=true&subfolders=true`, 'get').then(result => res(result)).catch(e => rej(e))
        })
    }
    setDirectory(id) {
        if (!id) {
            if (this.state.title) return this.setTitle(this.state.title)
            return window.flash('invalid directory')
        }
        this.getDirectory(id).then(result => {
            let { directory } = result
            let { files, subfolders } = directory
            this.getFiles(files).then(files => {
                this.setState({ files, folders: subfolders, currentFolder: directory })
            }).catch(e => {
                window.flash(e)
            })
        }).catch(e => window.flash(e))
    }
    getRootFolder(title) {
        return new Promise((res, rej) => {
            window.app.request(`/root-folders?title=${title}`, 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    async selectRoot() {
        let u = [...this.state.files].filter(u => !window.createPackage.state.selectedFiles.includes(u))
        for (let i = 0; i < u.length; i++) await window.createPackage.addFile(u[i]._id)
    }
    async deselectRoot() {
        let u = [...this.state.files].filter(u => window.createPackage.state.selectedFiles.includes(u))
        for (let i = 0; i < u.length; i++) await window.createPackage.addFile(u[i]._id)
    }
    async selectDirectory(id) {
        let dir = this.state.directories.find(u => u._id === id)
        if (!dir) return window.flash('Unable to locate directory')
        let { files } = dir
        let f = files.filter(u => !window.createPackage.state.selectedFiles.includes(u))
        for (let i = 0; i < f; i++) await window.createPackage.addFile(f[i])
    }
    async deselectDirectory(id) {
        let dir = this.state.directories.find(u => u._id === id)
        if (!dir) return window.flash('Unable to locate directory')
        let { files } = dir
        let f = files.filter(u => window.createPackage.state.selectedFiles.includes(u))
        for (let i = 0; i < f; i++) await window.createPackage.addFile(f[i])
    }
    selectFile(id) {
        window.createPackage.addFile(id)
    }
    setTitle(id) {
        if (!id) return window.flash('NO ID PASSED')
        this.fullTitle(id).then(result => {
            let { _id, files, directories, directoryList, title, fileList } = result.title
            console.log(result)
            this.setState({ directories: directoryList, currentFolder: '', files, folders: directories, changeTitle: false, title: _id, titleName: title, titleFiles: fileList, titleFolders: directories, gotTitles: true })
        }).catch(e => window.flash(e))
    }
    fullTitle(id) {
        return new Promise((res, rej) => {
            window.app.request(`/full-title?_id=${id}`).then(result => {
                res(result)
            }).catch(e => {
                rej(e)
            })
        })
    }
    getDirectories(title) {
        return new Promise((res, rej) => {
            window.app.request(title ? `/directories?title=${title}` : '/directories', 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    getTitles() {
        return new Promise((res, rej) => {
            window.app.request('/titles-files', 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    getTitle(id) {
        return new Promise((res, rej) => {
            if (!id) return rej('Missing id')
            window.app.request(`/title-files?title=${id}`, 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    parentClick(e) {
        e.preventDefault()
        e.stopPropagation()
        e.target.parentElement?.click()
    }
    render() {
        window.fileselector = this
        if (!this.state.gotTitles) return (<div className="b1"><h3>Loading titles</h3><Spinner></Spinner></div>)
        if ((!this.state.title && this.state.titles?.length > 0) || this.state.changeTitle) {
            return (<SelectTitle titles={this.state.titles}></SelectTitle>)
        } else if (this.state.titles.length < 1 && !this.state.title) {
            return (<div className="b1"><h3>No Titles Available</h3></div>)
        }
        return (<div id="fileManager" className="b1 rel">
            <SelectDirectory parent={this} directories={this.state.directories}></SelectDirectory>
            <div className="b1 rel">
                <SearchBar placeholder="Search Files and Folders" items={[...this.state.titleFiles, ...this.state.titleFolders]} indexes={['tags', 'name', '_id']} mainKey={'_id'} template={(u, i) => (<div key={i} className="b2 jsb searchResult">
                    <h3>{u.name.toUpperCase()} - {new Date(u.dateAdded).toLocaleDateString()}</h3>
                    <button id={u._id} className="smallbut" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        let id = e.target.id
                        if ([...this.state.titleFiles].find(a => a._id == id)) {
                            this.selectFile(id)
                        } else {
                            this.selectDirectory(id)
                        }
                    }}>Select</button></div>)} />
                <h1>File Selector</h1>
                <div className="b2 jsb fifty">
                    {this.state.currentFolder && <button onClick={() => { this.setDirectory(this.state.currentFolder.directory) }}><i className="fas fa-caret-left"></i> Previous Folder</button>}
                    {this.state.titles?.length > 0 && <button onClick={() => this.setState({ changeTitle: true })}><i className="fas fa-caret-left"></i> Change titles</button>}
                    {this.state.currentFolder?.files?.length > 0 && <button onClick={() => this.selectDirectory(this.state.currentFolder?._id)}>Select Directory</button>}
                    {this.state.currentFolder && window.fileselector?.state?.selectedFiles?.length > 0 && this.state.titlesFiles?.length > 0 && [...window.fileselector.state.selectedFiles].map(u => this.state.currentFolder.files.includes(u)).filter(u => u).length > 0 && <button onClick={() => this.deselectDirectory(this.state.currentFolder?._id)}>Deselect Directory Files</button>}
                    {!this.state.currentFolder && this.state.titleFiles?.length > 0 && <button onClick={() => this.selectRoot()}>Select Root Files</button>}
                    {!this.state.currentFolder && window.fileselector?.state?.selectedFiles?.length > 0 && this.state.titleFiles?.length > 0 && [...window.fileselector.state.selectedFiles].map(u => this.state.titleFiles.includes(u)).filter(u => u).length > 0 && <button onClick={() => this.deselectRoot(this.state.currentFolder?._id)}>Deselect Root Files</button>}
                </div>
                <div className="dirtree"><span className="dirtreespan" onClick={e => {
                    this.setDirectory()
                }}><strong>Title:</strong> {this.state.titleName.toUpperCase()}</span> {(() => {
                    if (!this.state.currentFolder?._id || !this.state.currentFolder?.directory) return
                    let temp = (obj, i) => {
                        return (<span className="dirtreespan" key={i} id={obj._id} onClick={e => {
                            this.setDirectory(e.target.id)
                        }}>{obj.name}</span>)
                    }
                    let ar = []
                    let addDir = (dir) => {
                        let a = this.state.directories.find(u => u._id === dir)
                        if (a) {
                            ar.push(a)
                            if (a.directory) addDir(a.directory)
                        }
                    }
                    addDir(this.state.currentFolder.directory)
                    return ar.reverse().map(temp)
                })()}{this.state.currentFolder && <span className="dirtreespan"><strong></strong> {this.state.currentFolder?.name}</span>}</div>
                <div className="b1 rel">
                    {(this.state?.files?.length > 0 || this.state?.folders?.length > 0) ? (<FileList selectedFiles={this.state.selectedFiles} selectedTitles={this.state.selectedTitles} title={this.state.title} directory={this.state.currentFolder} folders={this.state.folders} files={this.state.files}></FileList>) : <h3>No Files or Directories added</h3>}
                </div>
            </div>
        </div>)
    }
}
export default FileSelector