import React, { Component } from 'react'
import TagList from '../helper/taglist';
import FileStatus from './filestatus.js';
import Pagination from '../helper/pagination';
import Preview from '../helper/preview';
import '../css/file.css'
class Files extends Component {
    state = {
        sortMode: 'default',
        viewMode: 'grid',
        itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12,
        size: window.innerWidth,
        page: 0,
        folderCount: window.innerWidth < 650 ? 3 : window.innerWidth < 1080 ? 6 : 8,
        folderPage: 0
    }
    resize = () => {
        this.setState({ size: window.innerWidth, itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12 })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    sortList(list) {
        switch (this.state.sortMode) {
            case 'date': {
                return list.sort((a, b) => new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()).reverse()
            }
            default: {
                return [...list].sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : a.name?.toLowerCase() === b.name?.toLowerCase() ? 0 : -1)
            }
        }
    }
    fileExit(e) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.remove('fileFolder');
        e.target.classList.remove('fileFolder');
        let { currentTarget } = e
        currentTarget.classList.remove('fileFolder')
    }
    fileOver(e) {
        e.preventDefault()
        e.stopPropagation()
        e.target.classList.add('fileFolder');
        let { currentTarget } = e
        currentTarget.classList.add('fileFolder')
    }
    fileFolder(e) {
        e.preventDefault()
        e.stopPropagation()
        let id = e.dataTransfer.getData('text');
        let { currentTarget } = e
        currentTarget.classList.remove('fileFolder')
        if (window.filemanager.state.files.find(u => u._id === id)) {
            window.filemanager.addFileToFolder(id, currentTarget.id)
        } else {
            window.filemanager.addFolderToFolder(id, currentTarget.id)
        }
    }
    
    render() {
        let cp = {cursor: 'pointer'}
        let cd = {cursor: 'default'}
        return (<div className="b1 pushed">
            <div className="options">
                <div id="sortingMode"><div className="sortGrid"><span id="sorter">Sort By:</span><button className={this.state.sortMode === 'default' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'default' })}>Name</button><button className={this.state.sortMode === 'date' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'date' })}>Date</button></div></div>
                <div id="sortingMode"><div className="sortGrid"><span id="sorter">Display:</span><button className={this.state.viewMode === 'grid' ? 'activebutton' : ''} onClick={e => this.setState({ viewMode: 'grid' })}>Grid</button><button className={this.state.viewMode === 'table' ? 'activebutton' : ''} onClick={e => this.setState({ viewMode: 'table' })}>Table</button></div></div>
            </div>

            <div className="b1">
                <div className="b1">
                {this.props.folders?.length > 0 ? (<div className="popgrid">
                    {window.index(this.sortList(this.props.folders), this.state.folderPage, this.state.folderCount).map((u, i) => (
                        <div key={i}
                            onDrop={e => this.fileFolder(e)}
                            onDragExit={e => this.fileExit(e)}
                            onDragLeave={e => this.fileExit(e)}
                            onDragOver={e =>  this.fileOver(e) }
                            onDragStart={e => {
                                
                                e.dataTransfer.setData('text/plain', e.target.id);
                                window.filemanager.dragFile = true
                            }}
                            onDragEnd={e => {
                                window.filemanager.dragFile = false
                            }}
                            draggable={true}
                            className="listFile popitem folder"
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                window.filemanager.setDirectory(e.target.id)
                            }}
                            id={u._id}>

                            <button onClick={e => { window.filemanager.deleteFolder(e.target.parentElement?.id) }}><i className="fas fa-times"></i></button>
                            <h3 style={{wordBreak: 'break-all'}} onClick={window.filemanager.parentClick}>{u.name}</h3>
                        </div>))}
                    <button onClick={() => window.filemanager.setState({ createFolder: true })} className="grey-btn" ><i className="fas fa-folder-plus"></i></button>


                </div>) : (
                    <div>
                        <h3 >No Subfolders</h3>
                        <button onClick={() => window.filemanager.setState({ createFolder: true })} className="grey-btn" ><i className="fas fa-folder-plus"></i> New Folder</button> 
                    </div>)}
                    <Pagination set={e => this.setState({folderPage: e})} page={this.state.folderPage} count={this.state.folderCount} total={this.props.folders?.length || 0}/>
                </div>
                <div className="b1">

                {this.props.files?.length > 0 ? (this.state.viewMode === 'grid' ? (<div className="popgrid">
                    {window.index(this.sortList([...this.props.files]), this.state.page, this.state.itemCount).filter(u => u).map((u, i) => (<div key={i} className="listFile" onDragStart={e => {
                        e.dataTransfer.setData('text/plain', e.target.id);
                        window.filemanager.dragFile = true
                    }} onDragEnd={e => {
                        window.filemanager.dragFile = false
                    }} draggable={true} className="listFile popitem file" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        window.filemanager.viewFile(e.target.id)
                    }} id={u._id}>
                        <span onClick={window.filemanager.parentClick}>{u.name}</span>
                        <Preview link={u.link} size={u.stats.size} type={u.type} />
                        <FileStatus file={u} short />
                        <TagList tags={u.tags} max={3}></TagList>
                    </div>))}</div>) : (<div className="table">
                        <table>
                            <thead>
                                <tr style={cd}>
                                    <th style={cp} onClick={e => this.setState({ sortMode: 'name' })}>Name</th>
                                    <th style={cp} onClick={e => this.setState({ sortMode: 'date' })}>Date</th>
                                    <th style={cd}>Status</th>
                                    <th style={cd}>Tags</th>
                                </tr>
                            </thead>
                            <tbody>
                                {window.index(this.sortList([...this.props.files]), this.state.page, this.state.itemCount).filter(u => u).map((u, i) => (
                                    <tr id={u._id} className="file" key={i} onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        window.filemanager.setState({ currentFile: this.props.files.find(u => u._id === e.target.id) })
                                    }}>
                                        <td>
                                            <i className={"fas fa-" + window.app.state?.fileIcons[u?.name?.split('.').pop()]?.icon} style={{ color: window.app?.state?.fileIcons[u?.name?.split('.').pop()]?.color }}></i> &nbsp;
                                            {u.name}</td>
                                        <td>{window.formatDate(new Date(u.dateAdded).getTime())}</td>
                                        <td className="rel"><FileStatus file={u} /></td>
                                        <td><TagList tags={u.tags} max="3"></TagList></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>)) : (<div className="b1">
                        <h3 >No Files Listed</h3>
                        <button onClick={() => window.filemanager.setState({ uploader: true })} className="grey-btn"><i className="fas fa-upload"></i> Upload</button>
                    </div>)}
                    <Pagination set={e => this.setState({page: e})} page={this.state.page} count={this.state.itemCount} total={this.props.files?.length || 0}/>
                </div>
            </div>
        </div>)
    }
}
export default Files