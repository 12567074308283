import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Spinner from '../helper/spinner'
import '../css/hd.css'
import SearchBar from '../helper/searchbar';
import Pagination from '../helper/pagination';
class HDlist extends Component {
    sortMode = 'name'
    state = { SF: false, sortMode: 'name', list: [], searching: '', page: 0, itemCount: 24 }
    showHD(id) {
        if (!id) return
        window.showHD(this.state.hds.find(u => u._id === id))
    }
    sortList(list) {
        switch (this.state.sortMode) {
            case 'date': {
                let z = list.sort((a, b) => new Date(a.dateReceived).getTime() - new Date(b.dateReceived).getTime()).reverse()
                return z
            }
            default: {
                return [...list].sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : a.name?.toLowerCase() === b.name?.toLowerCase() ? 0 : -1)
            }
        }
    }
    getHDs() {
        return new Promise((res, rej) => {
            fetch(window.API + '/hard-drives', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID }) }).then(result => result.json()).then(result => {
                if (result.error) {
                    window.flash(result.message)
                   return  window.app.logOut()
                }
                let list = this.sortList(result.HD)
                this.setState({ hds: list })
                res(list)
            }).catch(e => {
                console.log(e)
                rej(e)
            })
        })
    }

    componentDidUpdate() {
        if (this.state.sortMode !== this.sortMode) {
            this.sortMode = this.state.sortMode
            this.setState({ hds: this.sortList([...this.state.hds]) })
        }
    }
    componentDidMount() {
        this.getHDs().catch(e => console.log(e))
    }
    setFocus(v) {
        if (v) clearTimeout(this.focusTime)
        this.setState({ SF: v })
    }
    removeFocus() {
        this.focusTime = setTimeout(() => this.setFocus(false), 1000)
    }
    render() {
        window.HDlist = this
        if (!this.state.hds) return (<div className="spin"><Spinner></Spinner><h3>Loading Hard Drives</h3></div>)
        return (<div id="hd-list" className="b1 pushed2 rel">
            <Link to="/new-hd" className="fixedButton"><button><i className="fas fa-plus"></i> &nbsp;New Hard Drive</button></Link>
            <div className="options">
                <SearchBar items={[...this.state.hds]} indexes={['name']} placeholder="Search Hard Drives" mainKey={'_id'} template={(u, i) => (<div key={i} className="b2 jsb searchResult" ><h3>{u.name.toUpperCase()} - {new Date(u.dateReceived).toLocaleDateString()}</h3><button id={u._id} className="smallbut" onClick={e => {
                    let id = e.target.id
                    if (!id) return
                    window.showHD(this.state.hds.find(u => u._id === id))
                }}>VIEW</button></div>)} />
                <div id="sortingMode"><div className="sortGrid"><span id="sorter">Sort By:</span><button className={this.state.sortMode === 'default' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'default' })}>Name</button><button className={this.state.sortMode === 'date' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'date' })}>Date</button></div></div>
            </div>
            <h1>All Hard Drives</h1>
            <span>{this.state.hds?.length || 0} Hard Drives</span>
            <div className="c4">{window.index([...this.state.hds], this.state.page, this.state.itemCount).map((u, i) => u ? (<div key={i} className={"listedHD" + (u.checkedOut ? ' checkedoutHD' : '')} onClick={e => {
                let id = e.target?.id
                this.showHD(id)
            }} id={u._id}>
                <div className="b1" onClick={e => {
                    let id = e.target?.parentElement?.id
                    this.showHD(id)
                }}><h3 onClick={e => {
                    let id = e.target?.parentElement?.parentElement?.id
                    this.showHD(id)
                }}>{u.name?.toUpperCase()}</h3><span onClick={e => {
                    let id = e.target?.parentElement?.parentElement?.id
                    this.showHD(id)
                }} style={{ color: u.checkedOut ? 'var(--s)' : 'inherit' }}><i className={u.checkedOut ? 'fas fa-times' : 'fas fa-check'}></i> {u.checkedOut ? 'Checked Out' : 'Checked In'}</span>
                    <p onClick={e => {
                        let id = e.target?.parentElement?.parentElement?.id
                        this.showHD(id)
                    }}>Date Received: {new Date(u.dateReceived).toLocaleDateString()}</p></div>
            </div>) : '')}</div>
            <Pagination count={this.state.itemCount} total={this.state.hds?.length  || 0 } page={this.state.page} set={e => this.setState({page: e})}/>
            </div>)
    }
}
export default HDlist