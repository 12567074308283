import '../css/notification.css'
import Pagination from '../helper/pagination';
import React, { Component } from 'react'
class NotificationCenter extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }
    state = { page: 0, count: 10, sort: true }
    closeNotifications() {
        window.app.closeNotifications()
    }
    viewed(id) {
        console.log(id)
        window.app.setState({
            notifications: [...window.app.state.notifications].map(u => {
                if (u._id === id) u.viewed = true
                return u
            })
        })
    }
    aV(u) {
        return u
    }
    oV(u) {
        return !u.viewed
    }
    notificationColor(type) {
        switch (type) {
            case 'alert': {
                return 'secondary'
            }
            case 'title announcement': {
                return 'primary'
            }
            case 'announcement': {
                return 'primary'
            }
            case 'folder created': {
                return 'primary'
            }
            case 'backblaze issue': {
                return 'danger'
            }
            case 'upload error': {
                return 'warning'
            }
            case 'package available': {
                return 'primary'
            }
            case 'ftp available': {
                return 'primary'
            }
            case 'new title': {
                return 'primary'
            }
            case 'package downloaded': {
                return 'info'
            }
            case 'major error': {
                return 'danger'
            }
            case 'upload complete': {
                return 'primary'
            }
            case 'message': {
                return 'info'
            }
            case 'warning': {
                return 'danger'
            }
            case 'error': {
                return 'warning'
            }
            case 'upload': {
                return 'primary'
            }
            default: {
                return 'info'
            }
        }
    }
    notificationIcon(type) {
        switch (type) {
            case 'alert': { return 'comment' }
            case 'title announcement': {
                return 'bullhorn'
            }
            case 'folder created': {
                return 'bullhorn'
            }
            case 'backblaze issue': {
                return 'exclamation'
            }
            case 'upload error': {
                return 'exclamation-triangle'
            }
            case 'package available': {
                return 'cloud-upload'
            }
            case 'ftp available': {
                return 'cloud-upload'
            }
            case 'new title': {
                return 'bullhorn'
            }
            case 'package downloaded': {
                return 'cloud-upload'
            }
            case 'major error': {
                return 'exclamation'
            }
            case 'upload complete': {
                return 'cloud-upload'
            }
            case 'message': {
                return 'comment'
            }
            case 'announcement': { return 'bullhorn' }
            case 'error': { return 'exclamation' }
            case 'warning': { return 'exclamation-triangle' }
            case 'upload': { return 'cloud-upload' }
            default: { return 'comment' }
        }
    }
    render() {
        return (<div id="notificationCenter" className="full" style={{ maxWidth: '100%' }}>
            <h1 className="formHead">Notification Center</h1>
            <button className="close" onClick={this.closeNotifications}><i className="fas fa-times"></i></button>
            <div className="b1 rel">
                <div className="b2 wrap" style={{ position: 'absolute', top: 0, right: 0, justifyContent: 'flex-end', alignItems: 'flex-start' }}><span style={{ cursor: 'pointer' }} onClick={() => this.setState({ sort: true })}>{!this.state.sort ? 'New' : <strong>New</strong>}</span><span style={{ cursor: 'pointer' }} onClick={() => this.setState({ sort: false })}>{!this.state.sort ? <strong>All</strong> : 'All'}</span></div>
                <ul className="b1 jfs" style={{ width: 'auto', padding: '10px', borderRadius: 'var(--radius)' }}>{this.props?.notifications?.length > 0 ? window.index([...this.props.notifications].filter(this.state.sort ? this.oV : this.aV), this.state.page, this.state.count).map((item, key) =>
                    <li key={key} id={item._id} style={{ borderRadius: 'var(--radius)', boxShadow: 'var(--shadow)' }} className={"b1 rel alert-" + this.notificationColor(item.type)} >
                        <h3><i className={"fas fa-" + this.notificationIcon(item.type)}></i> &nbsp;{item.subject}</h3>
                        <p style={{ maxWidth: '100%', padding: '10px' }}>{item.content}</p>
                        {!item.viewed && <i className="fal fa-times closebtn" style={{ cursor: 'pointer' }} data-bs-dismiss="alert" aria-label="Close" onClick={e => {
                            let id = e.target.parentElement?.id
                            if (id) this.viewed(id)
                        }}></i>}
                    </li>
                ) : <h3>No notifications yet</h3>}</ul>
                <Pagination count={this.state.count} set={e => this.setState({ page: e })} total={[...this.props.notifications]?.filter(u => !u.viewed)?.length || 0} page={this.state.page} />
            </div>
        </div>)
    }
}
export default NotificationCenter
