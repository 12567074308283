import React, { Component } from 'react'
import Spinner from '../helper/spinner'
import '../css/file.css'
class DirectoryListing extends Component {
    render() {
        return (<li className="treeFolder" onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            let a = e.target.querySelector('ul')
            if (a && a.classList.contains('hidden')) {
                a.classList.remove('hidden')
                window.fileselector.setDirectory(e.target.id)
            } else if (a) {
                a.classList.add('hidden')
            }
        }} id={this.props.directory._id}><h3 onClick={window.fileselector.parentClick}>
                {window.fileselector.state.titleFolders?.find(u => u.directory === this.props.directory._id) ? <i className="fas fa-level-up fa-rotate-90" style={{ marginRight: '0.5em' }}></i> : ""}

                {
                    (window.fileselector.state.currentFolder._id == this.props.directory._id) ?
                        <i className="fas fa-folder-open"></i> :
                        <i className="fas fa-folder"></i>
                }
                &nbsp;
                {this.props.directory.name}</h3>
            <ul className="hidden">{[...this.props.parent.props.directories].filter(u => u.directory === this.props.directory._id).map(this.props.parent.template)}</ul>
        </li>)
    }
}
class SelectDirectory extends Component {
    template = (u, i) => {
        return <DirectoryListing key={i} parent={this} directory={u}></DirectoryListing>
    }
    render() {
        if (typeof this.props.directories !== 'object') return (<ul id="directoryTree"><Spinner></Spinner> <h3>Loading Directories</h3></ul>)
        return (<ul id="directoryTree">
            <li><span>Directory Tree</span></li>
            <li className="treeFolder" onClick={(e) => {
                window.fileselector.setDirectory()
            }}><h3>Root</h3></li>
            <ul>{this.props.directories.length > 0 ? [...this.props.directories].filter(u => !u.directory).map(this.template) : <h3>No Directories Created</h3>}</ul>
        </ul>)
    }
}
export default SelectDirectory