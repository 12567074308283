import React, { Component } from 'react'
import * as JsSearch from 'js-search'
import File from './file'
import Files from './files'
import Upload from '../upload/uploader'
import Spinner from '../helper/spinner'
import CreateTitle from '../title/create-title'
import DirectoryTree from './directorytree'
import Pagination from '../helper/pagination'
import Waiting from '../helper/waiting'
import SearchBar from '../helper/searchbar'
import '../css/file.css'
import '../css/title.css'
function RenameFolder(props) {
    let name = window.filemanager.state.directories.find(u => u._id === (props.folder))?.name
    return (<form id={props.folder} className="b1 rel" onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        let form = e.target
        let id = form.id
        let name = form['name'].value
        if (!name) return window.flash('missing name')
        window.filemanager.renameFolder(id, name)
    }}>
        <h3>Rename Folder: {name}</h3>
        <div className="b1">
            <input defaultValue={name} placeholder={'Folder Name'} name="name" />
        </div>
        <div className="c2">
            <div className="b1">
                <button type="submit">Rename</button>
            </div>
            <div className="b1">
                <button onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.filemanager.setState({ renameFolder: false })
                }}>Close</button>
            </div>
        </div>
    </form>)
}
function RenameFile(props) {
    let name = window.filemanager.state.directories.find(u => u._id === (props.folder))?.name
    return (<form id={props.file} className="b1 rel" onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        let form = e.target
        let warn = form.querySelector('.warn')
        let warning = e => { warn.innerHTML = e; setTimeout(() => { warn.innerHTML = '' }, 4000) }
        let name = form['name'].value
        if (!name) return warning('Please enter a valid name')
        window.filemanager.renameFile(form.id, name)
    }}>
        <h3>Rename File</h3>
        <span className="warn"></span>
        <div className="b1">
            <label htmlFor="name">Rename {name}</label>
            <input type="text" defaultValue={name} placeholder={'File Name'} name="name"></input>
        </div>
        <div className="c2">
            <button type="submit">Change Name</button>
            <button onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                window.filemanager.setState({ renameFile: false })
            }}>Close</button>
        </div>
    </form>)
}
function MoveFolder(props) {
    return (<div className="b1 rel"><h3>Move Folder to another Folder</h3>
        <DirectoryTree directories={props.directories} draggable={false} clicks={false} />
        <div
            onDragStart={e => {

                e.dataTransfer.setData('text/plain', e.target.id);
                window.filemanager.dragFile = true
            }}
            onDragEnd={e => {
                window.filemanager.dragFile = false
            }}
            draggable={true}
            className="listFile popitem folder"
            id={props.folder}>
            <h3>{window.filemanager.state.directories.find(u => u._id === props.folder)?.name}</h3>
        </div>
        <button onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            window.filemanager.setState({ moveFolder: false })
        }}>Close</button></div>)
}
function MoveFile(props) {
    return (<div className="b1 rel"><h3>Move File to another Folder</h3>
        <DirectoryTree directories={props.directories} draggable={false} clicks={false} />
        <div
            onDragStart={e => {

                e.dataTransfer.setData('text/plain', e.target.id);
                window.filemanager.dragFile = true
            }}
            onDragEnd={e => {
                window.filemanager.dragFile = false
            }}
            draggable={true}
            className="listFile popitem file"
            id={props.file}>
            <h3>{window.filemanager.state.files.find(u => u._id === props.file)?.name}</h3>
        </div>
        <button onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            window.filemanager.setState({ moveFile: false })
        }}>Close</button></div>)
}
class SelectTitle extends Component {
    state = {
        itemCount: 12,
        page: 0
    }
    componentDidMount(){
        this.setState({page: 0})
    }
    render() {
        return (<div className="b1 rel">
            <div className="options">
                <SearchBar placeholder="Search Titles" indexes={['_id', 'title']} template={(u, i) => (<div key={i} className="jsb searchResult" id={u._id} onClick={e => { window.filemanager.setTitle(e.target.id) }} id={u._id}>
                        <h2 onClick={window.filemanager.parentClick} style={{ cursor: 'pointer' }}>{u.title.toUpperCase()}</h2>
                    </div>)} items={window.app.state.titles} mainKey={'_id'}></SearchBar>
            </div>
            <h3>SELECT A TITLE</h3>
            <div className="b1 pushed">
                <div className="c4" id="titleList">
                    {window.index([...window.app.state.titles].sort((a, b) => a.title > b.title ? 1 : a.title === b.title ? 0 : -1), this.state.page, this.state.itemCount).filter(u => u).map((u, i) => (<div key={i} id={u._id} onClick={e => { window.filemanager.setTitle(e.target.id) }} id={u._id} className="b1 titleSelect">
                        <span onClick={window.filemanager.parentClick} className="date" style={{ cursor: 'pointer' }}>{window.formatDate(new Date(u.dateAdded))}</span>
                        <h2 onClick={window.filemanager.parentClick} style={{ cursor: 'pointer' }}>{u.title.toUpperCase()}</h2>
                    </div>))}
                </div>
                <Pagination set={e => this.setState({page: e})} count={this.state.itemCount} total={window.app.state.titles?.length  || 0} page={this.state.page}/>
            </div>
            {window.isInternal() && <button onClick={() => window.filemanager.setState({ createTitle: true })}><i className="fas fa-plus"></i> &nbsp;Create Title</button>}
            {window.filemanager.state.selectTitle && <button onClick={() => window.filemanager.setState({ selectTitle: false })}>Close</button>}
        </div>)
    }
}
function CreateFolder(props) {
    return (<div className="b1 rel">
        <h3>Create a Folder</h3>
        <form onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            let form = e.target
            let warn = form.querySelector('.warn')
            let warning = e => warn.innerHTML = e
            let query = { name: form['name'].value, title: window.filemanager.state.title }
            if (window.filemanager?.state?.currentFolder?._id) query.directory = window.filemanager.state.currentFolder._id
            window.filemanager.request('/new-directory', 'post', query).then(result => {
                let s = { titleFolders: [...window.filemanager.state.titleFolders, result.directory], directories: [...window.filemanager.state.directories, result.directory], createFolder: false }
                if (window.filemanager.state.currentFolder?._id === result.directory.directory || !window.filemanager.state.currentFolder && !result.directory.directory) s.folders = [...window.filemanager.state.folders, result.directory]
                window.filemanager.setState(s)
            }).catch(e => warning(e))
        }}>
            <span className="warn"></span>
            <input name="name" placeholder="Folder Name"></input>
            <button className="green-btn" type="submit">Create</button>
            <button className="red-btn" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                window.filemanager.setState({ createFolder: false })
            }}>Cancel</button>
        </form>
    </div>)
}
class FileManager extends Component {
    constructor(props) {
        super(props)
        this.file = React.createRef()
        this.form = React.createRef()
        this.window = React.createRef()
        this.dropzone = React.createRef()
        this.info = React.createRef()
    }
    state = {
        files: [],
        folders: [],
        titleFolders: [],
        titleFiles: [],
        titles: [],
        currentFolder: null,
        currentFile: null,
        uploader: false,
        title: '',
        selectTitle: false,
        createFolder: false,
        createTitle: false,
        moveFolder: false,
        renameFolder: false,
        renameFile: false,
        moveFile: false,
        titleName: '',
        acceptedFiles: [],
        searching: '',
        list: [],
        waiting: false
    }
    componentDidMount() {
        if (this.props.title) {
            this.setState({ title: this.props.title })
            this.setTitle(this.props.title)
        } else {
            this.initTitle()
        }
        window.filemanager = this;
        document.addEventListener('contextmenu', this.handleContext, false);
    }
    componentWillUnmount() {
        document.removeEventListener('contextmenu', this.handleContext, false);
        window.filemanager = null
    }
    find = () => (() => {
        let s = new JsSearch.Search('_id');
        s.indexStrategy = new JsSearch.PrefixIndexStrategy()
        s.addIndex('tags')
        s.addIndex('name')
        s.addIndex('_id')
        return s
    })();
    search(value) {
        if ((!this.state.titleFiles || this.state.titleFiles.length < 1) && (!this.state.titleFolders || this.state.titleFolders.length < 1)) return []
        let s = this.find()
        s.addDocuments([...this.state.titleFiles, ...this.state.titleFolders])
        return s.search(value)
    }
    setKeyArt(id){
        if (!this.state.title || !id) return
        window.app.request('/set-title-key-art', 'post', {title: this.state.title, file: id}).then(result => {
            console.log(result)
        }).catch(e => window.flash(e))
    }
    handleContext(e) {
        if (e.shiftKey) return;
        e.preventDefault();
        e.stopPropagation()
        if (window.filemanager.state.title) {
            let { clientX, clientY, target, screenX, screenY, offsetX, offsetY } = e
            var options
            if (target.className.includes('folder')) {
                options = [
                    (<div><i className="fas fa-folder-open"></i> Open Folder</div>),
                    (<div key={1} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ renameFolder: e.target.id, filepop: null })
                    }}><i className="fas fa-pencil"></i> Rename Folder</div>),
                    (<div key={2} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ moveFolder: e.target.id, filepop: null })
                    }}><i className="fas fa-share"></i> Move Folder</div>),
                    (<div key={3} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ filepop: null })
                        window.filemanager.deleteFolder(e.target.id)
                    }}><i className="fas fa-trash"></i> Delete Folder</div>)
                ]
            } else if (target.className.includes('file')) {
                options = [
                    (<div key={0} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ currentFile: window.filemanager.state.files?.find(u => u._id === e.target.id), filepop: null })
                    }}><i className="fas fa-file-open"></i> Open File</div>),
                    (<div key={1} id={e.target.id} onClick={e => {
                        let id = e.target.id
                        let file = window.filemanager.state.files.find(u => u._id === id)
                        if (window.DESKTOP && file.path) {
                            window.app.handleDownload(id)
                        } else if (file.link) {                            
                            return window.filemanager.download(id)
                        } else {
                            window.flash('File is not available to download')
                        }
                        window.filemanager.setState({filepop: null})
                    }}><i className="fas fa-download"></i> {window.DESKTOP ? 'Save' : 'Download'} File</div>),
                    (<div key={2} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ moveFile: e.target.id, filepop: null })
                    }}><i className="fas fa-share"></i> Move File</div>),
                    (<div key={3} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ filepop: null })
                        window.filemanager.deleteFile(e.target.id)
                    }}><i className="fas fa-trash"></i> Delete File</div>)
                ]
            } else {
                options = [
                    (<div key={0} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ createFolder: true, filepop: null })
                    }}><i className="fas fa-folder-plus"></i> New Folder</div>),
                    (<div key={1} id={e.target.id} onClick={e => {
                        window.filemanager.setState({ uploader: true, filepop: null })
                    }}><i className="fas fa-folder-plus"></i> Upload Files</div>)
                ]
                if (window.filemanager.state.currentFolder) {
                    options.push(
                        (<div key={2} onClick={e => {
                            window.filemanager.setState({ renameFolder: window.filemanager.state.currentFolder?._id, filepop: null })
                        }}><i className="fas fa-pencil"></i> Rename Current Folder</div>),
                        (<div key={3} onClick={e => {
                            window.filemanager.setState({ moveFolder: window.filemanager.state.currentFolder?._id, filepop: null })
                        }}><i className="fas fa-share"></i> Move Current Folder</div>),
                        (<div key={4} onClick={e => {
                            window.filemanager.setState({ filepop: null })
                            window.filemanager.deleteFolder(window.filemanager.state.currentFolder?._id)
                        }}><i className="fas fa-trash"></i> Delete Current Folder</div>))
                }
            }
            window.filemanager.setState({ filepop: { clientX, clientY, target, screenX, screenY, offsetX, offsetY, options, show: true } })
        }
    }
    initTitle() {
        this.getTitles().then(result => {
            this.setState({ titles: result.titles, gotTitles: true })
        }).catch(e => console.log(e))
    }
    request(path, method, data) {
        return new Promise((res, rej) => {
            window.app.request(path, method, data).then(result => {
                if (result.error) return rej(result.message || JSON.stringify(result))
                res(result)
            }).catch(e => rej(e))
        })
    }
    getFiles(array) {
        return new Promise((res, rej) => {
            window.app.request('/files', 'post', { files: array }).then(result => res(result.files)).catch(e => rej(e))
        })
    }
    getDirectory(id) {
        return new Promise((res, rej) => {
            window.app.request(`/directory?_id=${id}&files=true&subfolders=true`, 'get').then(result => res(result)).catch(e => rej(e))
        })
    }
    deleteFolder(id) {
        if (!id) return window.flash('Missing id')
        window.app.request(`/delete-directory`, 'post', {directory: id}).then(result => {
            if (!window.filemanager.state.currentFolder?._id === id) { window.filemanager.setDirectory(window.filemanager.state.currentFolder?._id) } else { window.filemanager.setDirectory() }
        }).catch(e => window.flash(e))
    }
    download(id) {
        let file = window.filemanager.state.files.find(u => u._id === id)
        if (!file) return window.flash('Invalid file for download')
        if (!file.link) return window.flash('File is not available for download')
        let a = document.createElement('a')
        a.href = file.link
        a.target = '_blank'
        a.rel = 'noreferrer'
        a.click()
        a.remove()
    }
    renameFolder(id, name) {
        if (!id || !name) window.flash('missing name || id')
        window.app.request('/rename-directory', 'post', { name, directory: id }).then(result => {
            console.log(result)
            window.filemanager.setState({renameFolder: false})
            
            if (window.filemanager.state.currentFolder?._id === id) window.filemanager.setState({ currentFolder: ({ ...window.filemanager.state.currentFolder, name }) })

        }).catch(e => window.flash(e))
    }
    renameFile(id, name) {
        if (!id || !name) return window.flash('missing name or id')
        window.app.request('/rename-file', 'post', { file: id, name }).then(result => {
            console.log(result)
            window.flash('Renamed file!')
            window.filemanager.setState({
                files: [...window.filemanager.state.files].map(u => {
                    if (u._id === result.file._id) return result.file
                    return u
                }), renameFile: false
            })
        }).catch(e => window.flash(e))
    }
    deleteFile(id) {
        if (!id) return window.flash('Missing id')
        window.app.request('/delete-file', 'post', { file: id }).then(result => {
            console.log(result)
            window.filemanager.setState({ files: [...window.filemanager.state.files].filter(u => u._id !== id), currentFile: null })
        }).catch(e => window.flash(e))
    }
    setDirectory(id) {
        if (!id) {
            if (this.state.title) return this.setTitle(this.state.title)
            return window.flash('invalid directory')
        }
        this.getDirectory(id).then(result => {
            let { directory } = result
            let { files, subfolders } = directory
            this.getFiles(files).then(files => {
                this.setState({ files, folders: subfolders, currentFolder: directory })
            }).catch(e => {
                window.flash(e)
            })
        }).catch(e => window.flash(e))
    }
    addFolderToFolder(id, folder) {
        if (!id || !folder) return window.flash('Invalid selection')
        this.request('/move-directory-to-directory', 'post', { directory: id, destination: folder }).then(result => {
            this.setState({moveFolder: false, filepop: null})
            this.getDirectories(this.state.title).then(result => {
                let { directories } = result
                this.setState({ directories })
                this.setDirectory(this.state.currentFolder?._id)
            }).catch(e => window.flash(e))
        }).catch(e => window.flash(e))
    }
    addFileToFolder(file, folder) {
        if (!file || !folder) return window.flash('Invalid selection')
        window.app.request('/move-file-to-directory', 'post', { file, directory: folder }).then(result => {
            this.setState({moveFile: false, filepop: null})
            this.setDirectory(this.state.currentFolder?._id)
        }).catch(e => window.flash(e))
    }
    addFileToRoot(id) {
        if (!id) return window.flash('Invalid selection')
        window.app.request('/move-file-to-root', 'post', { _id: id }).then(result => {
            this.setState({filepop: null, moveFile: false})
            this.setDirectory(this.state.currentFolder?._id)
        }).catch(e => window.flash(e))
    }
    addDirectoryToRoot(id) {
        if (!id) return window.flash('Invalid selection')
        window.app.request('/move-directory-to-root', 'post', { _id: id }).then(result => {
            this.getDirectories(this.state.title).then(result => {
                let { directories } = result
                this.setState({ directories, filepop: null, moveFolder: false })
                this.setDirectory(this.state.currentFolder?._id)
            }).catch(e => window.flash(e))
        }).catch(e => window.flash(e))
    }
    getRootFolder(title) {
        return new Promise((res, rej) => {
            window.app.request(`/root-folders?title=${title}`, 'post').then(result => res(result)).catch(e => rej(e))
        })
    }

    viewFile(id) {
        this.setState({ currentFile: this.state.files.find(u => u._id === id) || id })
    }
    setTitle(id) {
        if (!id) return window.flash('NO ID PASSED')
        this.fullTitle(id).then(result => {
            let { _id, files, directories, directoryList, title, fileList } = result.title
            console.log(result)
            this.setState({ directories: directoryList, currentFolder: '', files, folders: directories, changeTitle: false, title: _id, titleName: title, titleFiles: fileList, titleFolders: directories, gotTitles: true })
        }).catch(e => window.flash(e))
    }
    fullTitle(id) {
        return new Promise((res, rej) => {
            window.app.request(`/full-title?_id=${id}`).then(result => {
                res(result)
            }).catch(e => {
                rej(e)
            })
        })
    }
    getDirectories(title) {
        return new Promise((res, rej) => {
            window.app.request(title ? `/directories?title=${title}` : '/directories', 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    getTitles() {
        return new Promise((res, rej) => {
            window.app.request('/titles-files', 'post').then(result => res(result)).catch(e => rej(e))
        })
    }
    getTitle(id) {
        return new Promise((res, rej) => {
            if (!id) return rej('Missing id')
            window.app.request(`/title-files?title=${id}`, 'post').then(result => res(result)).catch(e => rej(e))
        })
    }

    parentClick(e) {
        e.preventDefault()
        e.stopPropagation()
        e.target.parentElement?.click()
    }
    fileExit(e) {
        e.preventDefault()
        e.stopPropagation()
        let { currentTarget } = e
        currentTarget.classList.remove('fileFolder')
    }
    fileOver(e) {
        e.preventDefault()
        e.stopPropagation()
        let { currentTarget } = e
        currentTarget.classList.add('fileFolder')
    }
    fileFolder(e) {
        e.preventDefault()
        e.stopPropagation()
        let id = e.dataTransfer.getData('text');
        let { currentTarget } = e
        console.log(currentTarget, e)
        currentTarget.classList.remove('fileFolder')
        if (window.filemanager.state.files.find(u => u._id === id)) {
            window.filemanager.addFileToFolder(id, currentTarget.id)
        } else {
            window.filemanager.addFolderToFolder(id, currentTarget.id)
        }
    }
    rootFolder(e) {
        e.preventDefault()
        e.stopPropagation()
        let id = e.dataTransfer.getData('text');
        let { currentTarget } = e
        currentTarget.classList.remove('fileFolder')
        if (window.filemanager.state.files.find(u => u._id === id)) {
            window.filemanager.addFileToRoot(id, currentTarget.id)
        } else {
            window.filemanager.addDirectoryToRoot(id, currentTarget.id)
        }
    }
    onDrop(ev) {
        ev.stopPropagation();
        ev.preventDefault();
        if (this.dragFile) return
        let input = this.file.current
        input.files = ev.dataTransfer.files;
        let f = []
        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    console.log('... file[' + i + '].name = ' + file.name);
                    f.push(file)
                }
            }
        }
        this.setState({ acceptedFiles: f })
        this.info.current.innerHTML = `Accepted ${f.length} Files`
        let w = this.window?.current
        if (w) {
            w.classList.remove('hidden')
            w.scrollIntoView({ behavior: 'smooth' })
        }
        this.dropzone.current.classList.remove('backed')
    }
    dragOver(e) {
        e.stopPropagation();
        e.preventDefault()
        if (this.dragFile) return
        this.dropzone.current.classList.add('backed')
        this.info.current.classList.remove('hidden')
        this.info.current.innerHTML = 'Drop files to upload'.toUpperCase()
    }
    dragStop(e) {
        e.stopPropagation();
        e.preventDefault()
        if (this.dragFile) return
        this.dropzone.current.classList.remove('backed')
        this.info.current.innerHTML = ''
        this.info.current.classList.add('hidden')
        this.window?.current?.classList.add('hidden')
    }
    async desktopUpload() {
        let er = e => {
            this.setState({ show: false, progress: 0, waiting: false, uploader: false })
            window.flash('File Transfer Error: ' + (e.message || JSON.stringify(e)))
            this.uploading = false
            this.dropzone.current?.classList?.remove('backed')
        }
        let { title, acceptedFiles, currentFolder } = this.state
        let directory = currentFolder?._id
        let files = [...acceptedFiles]
        this.setState({waiting: `Copying ${files.length} files...`})
        window.app.handleUpload(title, files, directory).then(result => {
            if (result?.error) return er(result.message || JSON.stringify(result))
            if (this.info.current) this.info.current.classList.add('hidden')
            this.window.current.classList.add('hidden')
            this.setState({ acceptedFiles: [], waiting: false, uploader: false })
        }).catch(e => {
            er(`Failed to upload files: ${JSON.stringify(files)} | ERROR:${JSON.stringify(e)}`)
        })
    }
    uploadFiles() {
        if (this.uploading) return
        let form = this.form.current
        this.uploading = true
        this.setState({ show: true })
        let fd = new FormData(form)
        fd.append('files', this.file.current.files)
        let er = e => {
            this.setState({ show: false, progress: 0, uploader: false, acceptedFiles: [] })
            window.flash('File Transfer Error: ' + (e.message || JSON.stringify(e)))
            this.uploading = false
            this.dropzone.current.classList?.remove('backed')
        }
        let that = new XMLHttpRequest()
        let timeStarted = new Date()
        let onProgress = (evt) => {
            if (evt.lengthComputable) {
                let percent = parseInt((evt.loaded / evt.total) * 100);
                let timeElapsed = ((new Date()) - timeStarted) / 1000
                let uploadSpeed = evt.loaded / (timeElapsed)
                let remaining = ((evt.total - evt.loaded) / uploadSpeed)
                this.setState({ progress: percent, uploadSpeed, timeRemaining: remaining, timeElapsed, loaded: evt.loaded, total: evt.total })
                if (percent === 100) window.flash('Processing upload, please wait', 1000)
            }
        }
        that.upload.onprogress = onProgress
        that.upload.onload = onProgress
        that.onerror = e => er(e)
        that.onabort = e => er(e)
        that.onreadystatechange = e => {
            if (that.readyState === 4) {
                let response
                try {
                    response = JSON.parse(that.responseText)
                } catch (e) {
                    return er(e)
                }
                if (response.error) return er(response)
                window.filemanager.setDirectory(window.filemanager.state.currentFolder?._id)
                form.reset()
                this.dropzone.current?.classList?.remove('backed')
                if (this.info.current) this.info.current.classList.add('hidden')
                this.window.current.classList.add('hidden')
                this.uploading = false
                this.setState({ show: false, progress: 0, acceptedFiles: [], uploader: false})
            }
        }
        that.open('POST', window.API + "/upload", true)
        that.setRequestHeader('authorization', window.app.state.auth)
        that.setRequestHeader('userid', window.app.state.userID)
        that.send(fd)
    }
    render() {
        window.filemanager = this
        if (!this.state.gotTitles) return (<div className="b1"><h3>Loading titles</h3><Spinner></Spinner></div>)
        if (this.state.createTitle) return (<div className="b1 rel"><button className="close" onClick={() => this.setState({ createTitle: false })}><i className="fas fa-times"></i></button><CreateTitle createTitle={true}></CreateTitle></div>)
        if ((!this.state.title && this.state.titles?.length > 0) || this.state.changeTitle) {
            return (<SelectTitle titles={this.state.titles}></SelectTitle>)
        } else if (this.state.titles.length < 1 && !this.state.title) {
            return (<div className="b1"><h3>No Titles Available</h3></div>)
        }
        if (this.state.createFolder) return (<div className="b1 rel"><button className="close" onClick={() => { window.filemanager.setState({ createFolder: false }) }}><i className="fas fa-times"></i></button><CreateFolder></CreateFolder></div>)
        if (this.state.renameFolder) return (<div className="b1 rel"><button className="close" onClick={() => { window.filemanager.setState({ renameFolder: false }) }}><i className="fas fa-times"></i></button><RenameFolder folder={this.state.renameFolder}></RenameFolder></div>)
        if (this.state.moveFolder) return (<div className="b1 rel"><button className="close" onClick={() => { window.filemanager.setState({ moveFolder: false }) }}><i className="fas fa-times"></i></button><MoveFolder folder={this.state.moveFolder} directories={this.state.directories}></MoveFolder></div>)
        if (this.state.renameFile) return (<div className="b1 rel"><button className="close" onClick={() => { window.filemanager.setState({ renameFile: false }) }}><i className="fas fa-times"></i></button><RenameFile file={this.state.renameFile}/></div>)
        if (this.state.moveFile) return (<div className="b1 rel"><button className="close" onClick={() => { window.filemanager.setState({ moveFile: false }) }}><i className="fas fa-times"></i></button><MoveFile file={this.state.moveFile} directories={this.state.directories}/></div>)
        if (this.state.uploader) return (<div className="b1 rel"><button onClick={() => this.setState({ uploader: false })} className="close"><i className="fas fa-times"></i></button><Upload directory={this.state.currentFolder?._id} title={this.state.title} /></div>)
        if (this.state.currentFile) return (<div id="file" className="b1 rel"><button className="close" onClick={() => this.setState({ currentFile: null })}><i className="fas fa-times"></i></button><File file={this.state.currentFile}></File></div>)
        return (<div className="dropper" ref={this.dropzone} onDrop={e => this.onDrop(e)} onDragExit={e => this.dragStop(e)} onDragLeave={e => this.dragStop(e)} onDragOver={e => this.dragOver(e)} >
            <div id="fileManagerSearch" onMouseLeave={e => { this.setState({ searching: '' }) }}>
                <div id="searchbar">
                    <input id="searchbarInput" placeholder="Search Files and Folders" type="text" autoComplete="off" value={this.state.searching} onChange={e => this.setState({ list: this.search(e.target.value), searching: e.target.value })}></input>
                    <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                        <div className="b1 jsa">Search Results</div>
                        {this.state.list?.length <= 0 ? (<h3>No Results for {this.state.searching || 'Null'}</h3>) : this.state.list.map((u, i) => (<div key={i} className="b2 jsb searchResult">
                            <h3>{u.name.toUpperCase()}{u.dateAdded && ` - ${new Date(u.dateAdded).toLocaleDateString()}`}</h3>
                            <button id={u._id} className="smallbut" onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                let id = e.target.id
                                if ([...this.state.titleFiles].find(a => a._id == id)) {
                                    console.log('title files')
                                    window.filemanager.viewFile(id)
                                } else {
                                    console.log('directory')
                                    window.filemanager.setDirectory(id)
                                }
                            }}>VIEW</button></div>))}
                    </div>
                </div>
            </div>
            {this.state.acceptedFiles && <><form encType="multipart/form-data" id="form" ref={this.form} className="hidden" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); this.uploadFiles(e) }}>
                <input ref={this.file} name={'file'} className="hidden" type="file" accept="*" multiple={true}></input>
                {this.state.currentFolder?._id && <input name="directory" type="hidden" value={this.state.currentFolder._id}></input>}
                <input name="title" type="hidden" value={this.state.title}></input>
            </form>
                <div ref={this.window} id="window" className="hidden">
                    <span>Selected Files {this.state.acceptedFiles?.length}</span>
                    {this.state.acceptedFiles?.length > 0 && <div className="c3">
                        {this.state.acceptedFiles.map((file, i) => (<li key={i} className="uploadedFile b1">
                            <p><b>{file.name}</b> - {window.fileSize(file.size)}</p>
                        </li>))}
                    </div>}
                    <div className="b2">
                        <button onClick={e => { window.DESKTOP ? this.desktopUpload() : this.uploadFiles() }}>Upload Files</button> <button onClick={e => {
                            if (this.info.current) this.info.current.classList.add('hidden')
                            this.window.current.classList.add('hidden')
                            this.setState({ acceptedFiles: [] })
                        }}>Cancel</button>
                    </div>
                    {this.state.show && <div className="b1">
                        <Spinner></Spinner>
                        <div className="b1">
                            {this.state.timeRemaining && <span><strong>Estimated time remaining:</strong> {this.state.timeRemaining < 60 ? `${Math.floor(this.state.timeRemaining)} seconds` : this.state.timeRemaining < 60 * 60 ? `${Math.floor(this.state.timeRemaining / 60)} minutes` : this.state.timeRemaining < 60 * 60 * 24 ? `${Math.floor(this.state.timeRemaining / 60 / 60)} hours` : `${Math.floor(this.state.timeRemaining / 60 / 60 / 24)} days`} | <strong>It has been:</strong> {this.state.timeElapsed < 60 ? `${Math.floor(this.state.timeElapsed)} seconds` : this.state.timeElapsed < 60 * 60 ? `${Math.floor(this.state.timeElapsed / 60)} minutes` : this.state.timeElapsed < 60 * 60 * 24 ? `${Math.floor(this.state.timeElapsed / 60 / 60)} hours` : `${Math.floor(this.state.timeElapsed / 60 / 60 / 24)} Days`} | <strong>Average Upload Speed:</strong> {window.fileSize(this.state.uploadSpeed / 1000)}/s</span>}
                            <div className={"progress"}>
                                <div className="progress-bar progress-bar-success" role="progressbar" style={{ width: this.state.progress + '%' }}>
                                    {this.state.progress + "%"} Complete
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <span id="fileNotif" ref={this.info} className="hidden"></span></>}<div id="fileManager" className="b1 rel">
                {this.state.waiting && <Waiting message={this.state.waiting}/>}
                <div className="corner-buttons">
                    <button onClick={() => window.filemanager.setState({ createFolder: true })} className="grey-btn"><i className="fas fa-folder-plus"></i></button>
                    <button onClick={() => window.filemanager.setState({ uploader: true })} className="grey-btn"><i className="fas fa-upload"></i></button>
                </div>
                <DirectoryTree directories={this.state.directories}></DirectoryTree>
                <div className="b1 rel">
                    {this.state.filepop && (<div onMouseLeave={e => this.setState({ filepop: null })} id="filePop" style={{ zIndex: 1000, left: this.state.filepop.clientX - 10, top: this.state.filepop.clientY - 10, position: 'fixed' }} className={this.state.filepop.show ? '' : 'hidden'}>
                        {this.state.filepop.options}
                    </div>)}
                    <h1>File Management</h1>
                    <>
                        {this.state.titles?.length > 0 && <button onClick={() => this.setState({ changeTitle: true })}>Change Title</button>}
                        {this.state.currentFolder && <button className="back" onClick={() => { this.setDirectory(this.state.currentFolder?.directory) }} ><i className="fas fa-caret-left"></i> Previous Folder</button>}
                    </>
                    <div className="breadcrumbs">
                        <span
                            onClick={e => { this.setDirectory() }}
                            onDrop={e => this.rootFolder(e)}
                            onDragExit={e => this.fileExit(e)}
                            onDragLeave={e => this.fileExit(e)}
                            onDragOver={e => { this.fileOver(e) }}
                        ><strong>Title:</strong> {this.state.titleName.toUpperCase()}</span>

                        {(() => {
                            if (!this.state.currentFolder?._id) return
                            let ar = []
                            let temp = (obj, i) => {
                                return (
                                    <span key={i} id={obj._id} className={(obj._id == this.state.currentFolder?._id ? ' currentBread' : '')}
                                        onClick={e => { this.setDirectory(e.target.id) }}
                                        onDrop={e => this.fileFolder(e)}
                                        onDragExit={e => this.fileExit(e)}
                                        onDragLeave={e => this.fileExit(e)}
                                        onDragOver={e => { this.fileOver(e) }}
                                    >
                                        <i className={"far fa-angle-right"}></i>
                                        {obj.name}</span>
                                )
                            }
                            let addDir = (dir) => {
                                let a = this.state.directories.find(u => u._id == dir)
                                if (a) {
                                    ar.push(a)
                                    if (a.directory) addDir(a.directory)
                                }
                            }
                            addDir(this.state.currentFolder?._id)
                            return ar.reverse().map(temp)
                        })()}
                    </div>
                    <div id="filecont" className="b1">
                        {<Files title={this.state.title} directory={this.state.currentFolder} folders={this.state?.folders?.length > 0 && this.state.folders.filter(u => !u.directory)} files={this.state?.files?.length > 0 && this.state.files}></Files>}
                    </div>
                </div>
            </div></div>)
    }
}
export default FileManager