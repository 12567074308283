import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './helper/svgs'
import Toggle from './helper/toggle'
import config from '../config'
import Spinner from './helper/spinner'

const URL_REDIRECT=config.URL_REDIRECT
class HomePage extends Component {
    state = { 
        notif: window.app.state.notifications?.length > 0 ? true : false,
        docTypes:[],
        gotPolicies:true,
        newDoc:false,
        nameError:false,
        nameEmptyError:false,
        docType:''
    }
    componentWillMount() {
		window.homepage = this;
	}
    componentDidMount() {
        window.addEventListener('resize', this.resize)
        this.getPolicies().catch((e) => window.flash(e));
        this.getCategories().catch((e) => window.flash(e));
        //window.app?.getTitles()
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    getCategories = () => {
		return new Promise((res, rej) => {
			this.gettingCategories = true;
			window.app
				.request("/categories", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingCategories = false;
					let { categories = [] } = r;
					window.app.setState({
						categories: [...categories],
						gotCategories: true,
					});
					this.setState({
						categories: [...categories],
						gotCategories: true,
					});
					res(categories);
				})
				.catch((e) => {
					this.gettingCategories = false;
					rej(e);
				});
		});
	};
    getPolicies = (docTypes) => {
		return new Promise((res, rej) => {
            this.state.gotPolicies=false
			window.app.request("/all-policies",
					"post",{
						type: { $in: docTypes || ["policies"] },
					}).then((r) => {
                        this.state.gotPolicies=true
                    this.setState({
                        policies: r.policies,
                        docTypes: r.policies?.reduce((a, b) => {
                            if (b.docTypes)
                                b.docTypes.forEach((u) => {
                                    if (!a.includes(u) && u!=null) a.push(u);
                                });
                            return a;
                        }, []),
                    })
                    window.app.setState({
                        policies: r.policies,
						fpolicies: r.policies,
                        reload:false,
						gotPolicies: true,
						docTypes: r.policies?.reduce((a, b) => {
							if (b.docTypes)
								b.docTypes.forEach((u) => {
									if (!a.includes(u)) a.push(u);
								});
							return a;
						}, []),
                    })
				}).catch((e) => {
					window.flash(
						"Unable to authenticate you, please log in again."
					);
					rej(e);
				});
		});
	};
    new = () => {
		this.setState({ newDoc: true });
	};
    render() {
        if (this.state.newDoc) {
            return (
                <div
                    className="App"
                    style={{
                        color: "red",
                        paddingTop: "20px",
                    }}
                >
                    {this.state.nameError && (
                        <span>We already have that type of document</span>
                    )}
                    {this.state.nameEmptyError && (
                        <span>Type of document can't be blank</span>
                    )}
                   
                        <div className='row' style={{ textAlign:'center', }}>
                        <div className='col-lg-12' style={{ marginBottom:'10px', }}>
                        <input
                        style={{ color: "var(--d)" }}
                        value={this.state.docType}
                        placeholder="Type of document"
                        onChange={(e) =>{
                                this.setState({
                                    docType: e.target.value,
                                    nameError: false,
                                })  
                        }     
                        }
                    />
                        </div>
                        <div className='col-lg-12'>
                        <button style={{ marginRight:'10px', }}
                        onClick={(e) => {
                            if (
                                !this.state?.docTypes
                                    .map((u) => u.toUpperCase())
                                    .includes(this?.state?.docType?.toUpperCase()) 
                            ) {
                                if (this?.state?.docType?.trim()=='') {
                                    this.setState({ nameEmptyError: true });
                                }else{
                                    this.setState({ nameEmptyError: false });
                                    this.setState({ newDoc: false });
                                    localStorage.setItem('policyType', this?.state?.docType?.trim())
                                    window.redirect(URL_REDIRECT+"/policy?type="+this?.state?.docType?.trim().toLowerCase())
                                }
         
                            } else {
                                this.setState({ nameError: true });
                            }
                        }}
                    >
                        Accept Name
                    </button>
                    <button
                        onClick={(e) =>
                            this.setState({ newDoc: false, docType: "" })
                        }
                    >
                        Cancel
                    </button>
                        </div>
                        
                            
                            
                        </div>


                    
                </div>
            );
        }else{
        return (
            <>
            {!this.state.gotPolicies ? (<div style={{marginTop:200,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',alignSelf:'center'}}><h3>Loading...</h3><Spinner /></div>) :
            <div className="b1 full rel">
                <div className="b1" style={{marginTop: '25vh'}}>
                    {this.state.notif && <div id="notifcount" className="b2 rel">
                        <div style={{maxWidth: '1rem'}}><bell-icon></bell-icon></div>
                        <span>You have <strong>{window.app.state?.notifications ? [...window.app.state.notifications].filter(u => !u.viewed).length : 0 }</strong> new notifications.</span>
                        <div onClick={() => this.setState({ notif: false })} id="xbutton" className="xbutton">X</div>
                    </div>}
                    <div className="b1"><h2 style={{ color: 'var(--p)' }}>Welcome to Delivery Minds Portal</h2></div>
                    <div className="b2 wrap fifty">
                        <div className="b1">
                            <div className="homebutton" onClick={() => {window.app.showNotifications()}}>Notifications</div>
                        </div>
                        <div className="b1">
                            <Link to={`${URL_REDIRECT}/my-uploads`}><div className="homebutton">My Uploads</div></Link>
                        </div>
                        <div className="b1">
                            <Link to={`${URL_REDIRECT}/titles`}><div className="homebutton">Titles</div></Link>
                        </div>
                        {window.isInternal() && <div className="b1">
                            <Link to={`${URL_REDIRECT}/files`}><div className="homebutton">Files</div></Link>
                        </div>}
                        {window.isInternal() && <div className="b1">
                            <Link to={`${URL_REDIRECT}/packages`}><div className="homebutton">Packages</div></Link>
                        </div>}
                        {window.isInternal() && <div className="b1">
                            <Link to={`${URL_REDIRECT}/hds`}><div className="homebutton">Hard Drives</div></Link>
                        </div>}
                        
                        {
                        this.state.docTypes.map((item)=>
                            <div className="b1" key={item}>
                                <Link to={`${URL_REDIRECT}/policy?type=${item}`} onClick={()=>{localStorage.setItem('policyType',item)}} ><div className="homebutton">{item=='sop'?item.toUpperCase():item.charAt(0).toUpperCase() + item.slice(1)}</div></Link>
                            </div>
                        )
                        }
                        {window.isAdmin() && <div className="b1">
                            <Link to={`${URL_REDIRECT}/user-list`}><div className="homebutton">Users</div></Link>
                        </div>}
                        {window.isAdmin() && <div className="b1">
                            <Link to={`${URL_REDIRECT}/settings`}><div className="homebutton">Settings</div></Link>
                        </div>}
                    </div>
                </div>
                <span onClick={e => window.app.logOut()} style={{cursor: 'pointer', fontSize: '.9rem', marginTop: '50px', textTransform: 'uppercase'}}>Log Out</span>
                <button onClick={e => this.new()} style={{cursor: 'pointer', fontSize: '.9rem', marginTop: '20px', textTransform: 'uppercase'}}>New Type</button> 
                
            </div>
          }
        </>
        )
     }
    }
}
export default HomePage