import React, { Component } from 'react'
import Spinner from '../helper/spinner'
class Directory extends Component {
    render() {
        return (<li className="treeFolder" onClick={(this.props.clicks !== false ? e => {
            e.preventDefault()
            e.stopPropagation()
            let a = e.target.querySelector('ul')
            if (a && a.classList.contains('hidden')) {
                a.classList.remove('hidden')
                window.filemanager.setDirectory(e.target.id)
            } else if (a) {
                a.classList.add('hidden')
            }
        } : (e) => {
            e.preventDefault()
            e.stopPropagation()
            let a = e.target.querySelector('ul')
            if (a && a.classList.contains('hidden')) {
                a.classList.remove('hidden')
            } else if (a) {
                a.classList.add('hidden')
            }
        })} id={this.props.directory._id}><h3 id={this.props.directory._id}
            onDrop={e => window.filemanager.fileFolder(e)}
            onDragExit={e => window.filemanager.fileExit(e)}
            onDragLeave={e => window.filemanager.fileExit(e)}
            onDragOver={e => { window.filemanager.fileOver(e) }}
            onDragStart={e => {
                e.dataTransfer.setData('text/plain', e.target.parentElement.id);
            }} draggable={this.props.draggable !== false ? true : false} onClick={window.filemanager.parentClick}>
                {(window.filemanager.state.titleFolders?.find(u => u.directory === this.props.directory.directory) && this.props.directory.directory) ? <i className="fas fa-level-up fa-rotate-90" style={{ marginRight: '0.5em' }}></i> : ""}

                {
                    (window.filemanager.state.currentFolder?._id === this.props.directory?._id) ?
                        <i className="fas fa-folder-open"></i> :
                        <i className="fas fa-folder"></i>
                }
                &nbsp;
                {this.props.directory.name}</h3>
            <ul className="hidden">{[...this.props.parent.props.directories].filter(u => u.directory === this.props.directory._id).map(this.props.parent.template)}</ul>
        </li>)
    }
}
class DirectoryTree extends Component {
    template = (u, i) => {
        return <Directory key={i} parent={this}  draggable={this.props.draggable !== false ? true : false} clicks={this.props.clicks} directory={u}></Directory>
    }
    render() {
        if (typeof this.props.directories !== 'object') return (<ul id="directoryTree"><Spinner></Spinner> <h3>Loading Directories</h3></ul>)
        return (<ul id="directoryTree">
            <li className="treeFolder" style={{padding: '0'}} ><h3 onClick={(this.props.clicks !== false ? () => { window.filemanager.setDirectory() } : ()=>{}) } onDrop={e => window.filemanager.rootFolder(e)} onDragExit={e => window.filemanager.fileExit(e)} onDragLeave={e => window.filemanager.fileExit(e)} onDragOver={e => { window.filemanager.fileOver(e) }}>{window.filemanager.state.titleName}</h3></li>
            <ul>{this.props.directories.length > 0 ? [...this.props.directories].filter(u => !u.directory).map(this.template) :
                <h3>No Directories Created</h3>}</ul>
        </ul>)
    }
}
export default DirectoryTree