import React, { Component } from 'react'
import '../css/accordian.css'
class AccordianPart extends Component {
    state = {
        visible: this.props.open === true ? true : this.props.first ? true : false,
        open: false
    }
    componentDidUpdate(){
        let a = (this.props.open === true ? true : false)
        if (a !== this.state.visible) {
            this.setState({visible: a})
        }
    }
    render() {
        return (<div className="accordianHolder b1 jfs">
            <div className="b2 jfs" onClick={() => this.setState({ open: !this.state.open })}><h3>{this.props.title}</h3>
            </div>
            <div className={this.state.open ? "b1 accordianContent" : this.state.visible ? "b1 accordianContent" : 'hidden'}>{this.props.inners}</div>
        </div>)
    }
}
function Accordian(props) {
    return (<div className="accordian b1 jfs">{props.items.map((u, i) => <AccordianPart first={!props.first && i === 0} open={props.open} key={i} inners={u.inners} title={u.title}></AccordianPart>)}</div>)
}
export default Accordian