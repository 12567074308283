
import { Component } from "react";
 import Docs from "./Docs";
import Spinner from "./components/helper/spinner";
import config from "./config";
const URL_REDIRECT=config.URL_REDIRECT
 class EditorConvertToHTML extends Component {
  queries() {
		let search = this.props.location.search;
		if (!search) return [];
		search = search.split("");
		// let s = search.shift();
		let items = search.join("").split("&");
		return items
			.map((u) => {
				let a = u.split("=");
				if (a && a[0] && a[1]) return { key: a[0], value: a[1] };
				return null;
			})
			.filter((u) => u);
	}
  state = {
		location: this.props.location.pathname,
		queries: this.queries(),
		auth: localStorage.getItem("auth"),
		userid: localStorage.getItem("userID"),
		type: localStorage.getItem("type"),
		policies: [],
		docType: localStorage.getItem('policyType'),
		docTypes: [],
		fpolicies: [],
		filteredCats: [],
		view_type: "grid",
		gotPolicies:false
	};
	componentWillMount() {
		window.homepage = this;
	}
	async componentDidMount() {
		if (this.state.location !== this.props.location.pathname) {
			this.setState({
				...this.state,
				location: this.props.location.pathname,
				queries: this.queries(),
				docType:this.queries().find((u) => u.key === "?type")?.value,
			});
		}
		if (window && window.app.state.policies && !window.app.state.reload){
			this.setState({
					...this.state,
					policies: window.app?.state?.policies,
					fpolicies: window.app?.state?.policies,
					gotPolicies: true,
					docType:this.queries().find((u) => u.key === "?type")?.value,
				});
			window.app.setState({
				...this.state,
				policies: window.app?.state?.policies,
				fpolicies: window.app?.state?.policies,
				gotPolicies: true,
				docType:this.queries().find((u) => u.key === "?type")?.value,
				docTypes: window.app?.state?.policies?.reduce((a, b) => {
					if (b.docTypes)
						b.docTypes.forEach((u) => {
							if (!a.includes(u)) a.push(u);
						});
					return a;
				}, []),
			})
		} else{
			this.getPolicies().catch((e) => window.flash(e));
		}
	}
	componentDidUpdate() {
		if (this.state.location !== this.props.location.pathname) {
			this.setState({
				...this.state,
				location: this.props.location.pathname,
				queries: this.queries(),
				docType:this.queries().find((u) => u.key === "?type")?.value,
			});
		}
	}
	
  getPolicies = (docTypes) => {
		return new Promise((res, rej) => {
			this.gettingPolicies = true;
			window.app
				.request("/all-policies",
					"post",
					{
						type: { $in: docTypes || ["policies"] },
					}
				)
				.then((r) => {
					this.gettingPolicies = false;
					window.app.setState({
						...this.state,
						policies: r.policies,
						fpolicies: r.policies,
						gotPolicies: true,
						docTypes: r.policies?.reduce((a, b) => {
							if (b.docTypes)
								b.docTypes.forEach((u) => {
									if (!a.includes(u)) a.push(u);
								});
							return a;
						}, []),
					})
					this.setState(
						{
							...this.state,
							policies: r.policies,
							fpolicies: r.policies,
							gotPolicies: true,
							docTypes: r.policies?.reduce((a, b) => {
								if (b.docTypes)
									b.docTypes.forEach((u) => {
										if (!a.includes(u)) a.push(u);
									});
								return a;
							}, []),
						},
						() => {
							res(r.policies);
						}
					);
					//console.log("state value", this.state.policies);
				})
				.catch((e) => {
					//console.log("fetch error", e);
					this.gettingPolicies = false;
					window.flash(
						"Unable to authenticate you, please log in again."
					);
					// window.app.logOut();
					rej(e);
				});
		});
	};
	request(page, method, data) {
		return new Promise((res, rej) => {
			fetch(window.API + page, {
				method,
				body: JSON.stringify(data),
				headers: {
					authorization: this.state.auth,
					userid: this.state.userid,
					"Content-Type": "application/json",
				},
			})
				.then((r) => r.json())
				.then((r) => {
					if (!r || r.error)
						return rej(r ? r.message : JSON.stringify(r));
					res(r);
				})
				.catch((e) => rej(e));
		});
	}
	new = () => {
		this.setState({ newDoc: true });
	};
	logOut = () => {
		localStorage.removeItem("userID");
		localStorage.removeItem("auth");
		localStorage.removeItem("type");
		this.setState({
			auth: null,
			type: null,
			userID: null,
			authenticated: false,
		});
		if (window.DESKTOP) {
			window.DESKTOP.LogOut(
				window.location = URL_REDIRECT+ "/logout-cookie"
				//"https://policies.octanemultimedia.com/logout-cookie"
			)
				// window.DESKTOP.LogOut(window.API + "/logout-cookie")
				.then(() => {
					window.redirect(URL_REDIRECT+"/");
				})
				.catch((e) => window.flash(e));
		} else {
			 window.location = URL_REDIRECT+ "/logout-cookie";
			// window.location = "http://localhost:3000/logout-cookie";
			// window.location =
			// 	"https://policies.octanemultimedia.com/logout-cookie";
		}
	};
	extractContent(text) {
		try {
			return JSON.parse(text)
				.blocks.map((u) => u.text)
				.join(" ");
		} catch (e) {
			console.log(e);
			return "";
		}
	}
  render() {

  return (
    <div>
		
		{!this.state.gotPolicies ? (<div style={{marginTop:200,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',alignSelf:'center'}}><h3>Loading {this.state.docType}</h3><Spinner /></div>) :
        <Docs
              id={this.state.current}
			  policies={this.state.policies || []}
			  docType={this.state.docType}
			  type={this.state.type}
			  location={this.state.location}
			  queries={this.state.queries}/>}
       
    </div>
  )
  }
}


export default EditorConvertToHTML;