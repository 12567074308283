import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import Title from './title'
import '../css/title.css'
import Pagination from '../helper/pagination';
import SearchBar from '../helper/searchbar';
class Titles extends Component {
    state = {
        sortMode: 'default',
        itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 8 : 12,
        searching: '',
        page: 0,
        viewing: '',
        list: []
    }
    resize = () => {
        this.setState({ itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 8 : 12 })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    sortList(list) {
        switch (this.state.sortMode) {
            case 'date': {
                let z = list.sort((a, b) => new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()).reverse()
                return z
            }
            default: {
                return [...list].sort((a, b) => a.title?.toUpperCase() > b.title?.toUpperCase() ? 1 : a.title?.toUpperCase() === b.title?.toUpperCase() ? 0 : -1)
            }
        }
    }
    render() {
        window.titles = this
        let failed
        if (this.state.viewing) {
            let title = this.props.titles.find(u => u._id === this.state.viewing)
            if (title) return (<div className="b1" style={{ position: 'relative', minHeight: '100vh', minWidth: '100%' }}><button hidden style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 100 }} onClick={() => this.setState({ viewing: '' })}><i className="fas fa-times"></i> Close Title</button><Title title={title}></Title></div>)
            failed = 'Failed to find title: ' + this.state.viewing
        }
        return (<div className="b1 pushed2 rel">
            <div className="options">
                <SearchBar items={[...this.props.titles]} indexes={['title']} mainKey={'_id'} placeholder="Search Titles" template={(u, i) => (
                        <div key={i} className="jsb searchResult" >
                            <h3 id={u._id} onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                this.setState({ viewing: e.target.id })
                            }}>{u.title.toUpperCase().replace(/ *\([^)]*\) */g, "")}</h3><span>{new Date(u.dateAdded).toLocaleDateString()}</span> <i className="far fa-angle-right"></i></div>)} />
                <div id="sortingMode"><div className="sortGrid"><span id="sorter">Sort By:</span><button className={this.state.sortMode === 'default' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'default' })}>Name</button><button className={this.state.sortMode === 'date' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'date' })}>Date</button></div></div>
            </div>
            {window.isInternal() && <Link to="/create-title"><button className="fixedButton"><i className="fas fa-plus"></i> &nbsp;Create Title</button></Link>}
            <h1>HOP Title Info</h1>
            {failed && <h3>{failed}</h3>}
            <span>{this.props.titles?.length || 0} Titles</span>
            <div className="c4 box" id="titleList">
                {this.props.titles && this.state.sortMode && window.index(this.sortList([...this.props.titles].filter(u => u)), this.state.page, this.state.itemCount).filter(u => { if (!u) { console.log(u) }; return u }).map((u, i) => (<div key={i} id={u._id} onClick={e => this.setState({ viewing: e.target.id })} className="b1 titleSelect">
                    <span className="date">{window.formatDate(new Date(u.dateAdded))}</span>
                    <h2 onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ viewing: e.target.parentElement.id })
                    }} style={{ cursor: 'pointer' }}>{u.title.replace(/ *\([^)]*\) */g, "")}</h2>
                </div>))}
            </div>
           <Pagination count={this.state.itemCount} total={this.props.titles?.length || 0} page={this.state.page} set={e => this.setState({page: e})} />
        </div>)
    }
}
export default Titles