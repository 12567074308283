import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import Sure from '../helper/sure'
import '../css/package.css'
import FileSelector from './fileselector';
import Spinner from '../helper/spinner'
import TagList from '../helper/taglist';
import FileStatus from '../file/filestatus';
import Pagination from '../helper/pagination';
class FileList extends Component {
    state = {
        itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12,
        size: window.innerWidth,
        page: 0,
        files: []
    }
    resize = () => {
        this.setState({ size: window.innerWidth, itemCount: window.innerWidth < 650 ? 6 : window.innerWidth < 1080 ? 12 : 18 })
    }
    componentDidMount() {
        window.addEventListener('resize', this.resize)
        if (this.props.files) { this.getFiles() } else { this.setState({ files: [] }) }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    sortList(list) {
        return [...list].sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : a.name?.toLowerCase() === b.name?.toLowerCase() ? 0 : -1)
    }
    getFiles = () => {
        return new Promise((res, rej) => {
            window.app.request('/files', 'post', { files: this.props.files }).then(result => {
                console.log(result, this.props.files)
                this.setState({ files: result.files, gotFiles: true })
            }).catch(e => { window.flash(e); this.setState({ gotFiles: true }) })
        })
    }
    index(ar, page) {
        let s = this.state.itemCount
        let a = []
        if (ar.length <= s) return ar
        if (ar.length > s) {
            if (ar.length - s * page >= 0) {
                for (let i = s * page; i < s * (page + 1); i++) a.push(ar[i])
            } else {
                for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++) a.push(ar[z])
            }
        }
        return a
    }
    render() {
        if (!this.state.gotFiles) return (<div className="spin"><Spinner></Spinner><h3>Loading Files</h3></div>)
        return (<div className="b1">
            {this.state?.files?.length > 0 && (<table className="tableSelection">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {this.index(this.sortList([...this.state.files]), this.state.page).filter(u => u).map((u, i) => (
                        <tr key={i} id={u._id} onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            window.fileselector.selectFile(e.target.id)
                        }}>
                            <td>{u.name}</td>
                            <td>{window.formatDate(new Date(u.dateAdded).getTime())}</td>
                            <td><FileStatus></FileStatus></td>
                            <td><TagList tags={u.tags} max="3"></TagList></td>
                        </tr>
                    ))}
                </tbody>
            </table>)}
            <Pagination total={this.state.files?.length || 0} page={this.state.page} count={this.state.itemCount} set={e => this.setState({ page: e })} />
            <span id="fileNotif" ref={this.info} className="hidden"></span>
        </div>)
    }
}
class CreatePackage extends Component {
    state = {
        selectedTitles: [],
        selectedFiles: [],
        recipients: [],
        contacts: [],
        expires: null,
        ftpUserName: '',
        ftpPassword: '',
        ftpExpires: null,
        requiresFTP: false,
        showPass: false,
        size: 0,
        title: {},
        titles: [],
        files: [],
        view: 'info',
        showFileLists: false,
        name: ''
    }
    componentDidMount() {
        this.getRecipients()
        this.getTitles().then(result => {
            let reduced = [...result.titles].reduce((all, k) => {
                all.push(...k.fileList)
                return all
            }, [])
            console.log(result.titles.length)
            this.setState({ titles: result.titles, files: reduced, gotTitles: true })
        }).catch(e => { window.flash(e); this.setState({ gotTitles: true }) })
    }
    getFiles(files) {
        window.app.request(`/files`, 'post', { files }).then(result => this.setState({ files: result.files })).catch(e => console.log(e))
    }
    getTitles() {
        return new Promise((res, rej) => {
            window.app.request('/select-titles-files').then(result => {
                res(result)
            }).catch(e => { console.log(e); rej(e) })
        })
    }
    getRecipients() {
        window.app.request(`/recipients`, 'post').then(result => this.setState({ contacts: result.recipients })).catch(e => console.log(e))
    }
    addFile(id) {
        if (this.state.selectedFiles.includes(id)) {
            let title = this.state?.titles?.find(u => u.fileList.find(a => a._id == id))?._id
            if (!title) return
            let f = [...this.state.files]?.filter(u => title.fileList?.find(a => a._id == u._id)) || []
            this.setState({ selectedFiles: [...this.state.selectedFiles].filter(u => u !== id), selectedTitles: f.length >= 1 ? this.state.selectedTitles : [...this.state.selectedTitles].filter(u => u !== title._id) })
        } else {
            let title = this.state?.titles?.find(u => u.fileList.find(a => a._id == id))?._id
            if (!title) return
            if (!this.state.selectedTitles.includes(title)) this.setState({ selectedTitles: [...this.state.selectedTitles, title] })
            this.setState({ selectedFiles: [...this.state.selectedFiles, id] })
        }
    }
    addRecipient(email) {
        this.setState({ recipients: [...this.state.recipients, email] })
    }
    removeRecipient(email) {
        this.setState({ recipients: [...this.state.recipients].filter(u => u === email) })
    }
    sure(message, data, callback) {
        ReactDOM.render((<Sure confirm={data => { callback(data); this.removeSure() }} data={data} message={message} close={this.removeSure}></Sure>), document.getElementById('pop'))
        document.getElementById('popup').classList.remove('hidden');
    }
    removeSure() {
        try {
            ReactDOM.unmountComponentAtNode(document.getElementById('pop'))
            document.querySelector('#popup').classList.add('hidden');
        } catch (e) {
            console.log('error', e)
        }
    }
    async submit() {
        let { recipients, expires, requiresFTP, ftpUserName, ftpPassword, ftpExpires, name } = this.state
        let files = this.state.selectedFiles
        let titles = this.state.selectedTitles
        if (!titles) return window.flash('Please select some files for the package.')
        let data = {
            titles,
            files,
            recipients,
            expires,
            ftp: requiresFTP ? {
                username: ftpUserName,
                password: ftpPassword,
                expires: ftpExpires
            } : null,
            name
        }
        window.app.request('/new-package', 'post', data).then(() => {
            window.flash('Package Created!')
            window.redirect('/packages')
        }).catch(e => {
            window.flash(e, 10000)
        })
    }
    render() {
        window.createPackage = this
        if (!window.app.state.titles?.length > 0) return (<div className="b1"><h2>No titles listed</h2></div>)
        if (this.state.showFileLists) {
            return (<div className="b1 form formtop">
                <h1 className="formHead">Confirm Package Details</h1>
                <h3  className="formHead">Package Info</h3>
                <span>Name: {this.state.name}</span>
                <h3  className="formHead">Package Recipients ({this.state.recipients?.length || 0})</h3>
                <div className="b1">{this.state.recipients?.length > 0 && [...this.state.contacts].filter(u => this.state.recipients.includes(u._id)).map((u, i) => (<div key={i} id={u._id} className="confirmRecipient">{u.firstName} {u.lastName[0]}. - {u.email}</div>))}</div>
                <h3  className="formHead">Package Titles ({this.state.selectedTitles?.length || 0})</h3>
                <div className="b2 wrap">{this.state.selectedTitles?.length > 0 && [...window.app.state.titles].filter(u => this.state.selectedTitles.includes(u._id)).map((u, i) => (<p key={i} className="selectedTitleB" id={u._id}><strong>{u.title}</strong></p>))}</div>
                <h3  className="formHead">Package Files ({this.state.selectedFiles?.length || 0})</h3>
                <div className="flexer"><div id="selectedTitles">{(this.state.selectedTitles?.length > 0) ? [...this.state.selectedTitles].map((u, i) => {
                    let title = this.state.titles.find(a => a._id === u)
                    let files = [...this.state.selectedFiles].filter(a => title.fileList.find(u => u._id == a))
                    return (<div key={i} id={u} className="selectedTitle"><h3 className="titleSelectedA">{title.title}</h3>
                        <div id="selectedFiles"><FileList files={files}></FileList></div>
                    </div>)
                }) : ''}</div></div>
                <h3 className="formHead">Package Permissions</h3>
                <div className="b1">
                    <span><strong>Packge Expires:</strong> {this.state.expires.toLocaleDateString()}</span>
                    {this.state.requiresFTP && <div className="b2">
                        <span><strong>Username:</strong> {this.state.ftpUserName}</span>
                        <span><strong>Password:</strong> {this.state.ftpPassword}</span>
                        <span><strong>FTP Expires:</strong> {this.state.ftpExpires.toLocaleDateString()}</span>
                    </div>}
                </div>
                <div className="b2 jsa cento">
                    <button onClick={e => this.setState({ showFileLists: false })}>Continue Editing</button>
                    <button onClick={() => this.submit()}>Confirm Details</button>
                </div>
            </div>)
        }
        return (<div className="b1 rel form formtop">
            <div className="b1">
                <h1 className="formHead">{this.state.name ? this.state.name : 'Create a Package'}</h1>
                <div className="b2">
                    <span><strong>Files:</strong> {this.state.selectedFiles.length}</span>
                    <span><strong>Titles:</strong> {this.state.selectedTitles.length}</span>
                </div>
                <div className="b2" style={{ position: 'absolute', top: '5px', right: '5px', justifySelf: 'flex-end', alignSelf: 'flex-end', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                    <button style={{ margin: '0 5px' }} className={this.state.view === 'info' ? 'activebutton' : ''} onClick={() => this.setState({ view: 'info' })}><i className="fas fa-info-circle"></i> Info</button>
                    <button style={{ margin: '0 5px' }} className={this.state.view === 'files' ? 'activebutton' : ''} onClick={() => this.setState({ view: 'files' })}><i className="fas fa-file"></i> Files</button>
                    <button style={{ margin: '0 5px' }} className={this.state.view === 'permissions' ? 'activebutton' : ''} onClick={() => this.setState({ view: 'permissions' })}><i className="fas fa-lock"></i> Permissions</button>
                </div>
                <div className="b1">
                    <div className={(this.state.view !== 'files' ? "b1 " : 'flexer ') + (this.state.view === 'info' ? '' : 'hidden')}>
                        <h3 className="formHead">Package Info</h3>
                        <div className="b1">
                            <label htmlFor="">Package Name</label>
                            <input type="text" placeholder="Enter Package Name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })}></input>
                        </div>
                        <div className="b1">
                            <h3 className="formHead">Package Recipients</h3>
                            {this.state.recipients?.length > 0 ? (<div id="packageRecipients">
                                {[...this.state.contacts].filter(u => u && this.state.recipients.includes(u._id)).map((u, i) => (<div key={i} id={u._id} onClick={e => {
                                    let recipient = this.state.contacts.find(u => u._id === e.target.id)
                                    if (!recipient) return
                                    this.sure(`Are you sure you want to remove ${recipient.firstName} ${recipient.lastName[0]}.`, recipient._id, this.removeRecipient)
                                }}>{u.firstName} {u.lastName[0]}. - {u.email}</div>))}
                            </div>) : (<p>Please select some recipients</p>)}
                            {!this.state.cr && <div className="b1 cento">
                                <label htmlFor="selectRecipient">Select a Recipient</label>
                                <select name="selectRecipient"><option value="undefined">Select One</option>{this.state.contacts?.length > 0 && [...this.state.contacts].filter((u) => !this.state.recipients.includes(u._id)).map((u, i) => <option key={i} value={u._id} className="recipient">{u.firstName} {u.lastName[0]}. - {u.email}</option>)}</select>
                                <div className="b2 cento"><button onClick={e => {
                                    let val = e.target?.parentElement?.parentElement?.querySelector('select')?.value
                                    console.log(val)
                                    if (val && val !== 'undefined') this.addRecipient(val)
                                }}><i className="fas fa-user-plus"></i> Add Recipient</button>
                                    <button onClick={e => this.setState({ cr: true })}>Create New Recipient</button></div>
                            </div>}
                            {this.state.cr && <form className="b1 form" onSubmit={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                let form = e.target
                                let warn = form.querySelector('.warn')
                                let warning = e => warn.innerHTML = e
                                let data = {
                                    firstName: form['firstName'].value,
                                    lastName: form['lastName'].value,
                                    email: form['email'].value,
                                    companyName: form['companyName'].value,
                                    password: form['password'].value
                                }
                                if (!data.firstName || !data.lastName || !data.email || !data.password) return warning('Missing required fields')
                                if (data.password !== form['confirmPassword'].value) return warning('Password is ')
                                window.app.request('/new-recipient', 'post', data).then(result => {
                                    form.reset()
                                    this.setState({ cr: false, recipients: [...this.state.recipients, result.recipient._id], contacts: [...this.state.contacts, result.recipient] })
                                }).catch(e => warning(e))
                            }}>
                                <h3 className="formHead">Create a recipient</h3>
                                <span className="warn"></span>
                                <div className="b2">
                                    <div className="b1 fifty">
                                        <label htmlFor="">First Name</label>
                                        <input type="text" name="firstName"></input>
                                    </div>
                                    <div className="b1 fifty">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input type="text" name="lastName"></input>
                                    </div>
                                </div>
                                <div className="b2">
                                    <div className="b1 fifty">
                                        <label htmlFor="companyName">Company Name</label>
                                        <input type="text" name="companyName"></input>
                                    </div>
                                    <div className="b1 fifty">
                                        <label htmlFor="email">Email Address</label>
                                        <input type="email" name="email"></input>
                                    </div></div>
                                <div className="b2">
                                    <div className="b1 fifty">
                                        <label htmlFor="password">Password</label>
                                        <input name="password" type={this.state.showPass ? 'text' : 'password'}></input>
                                    </div>
                                    <div className="b1 fifty">
                                        <label htmlFor="password">Confirm Password</label>
                                        <input name="confirmPassword" autoComplete="new-password" type={this.state.showPass ? 'text' : 'password'}></input>
                                    </div>
                                </div>
                                <div className="b2 cento"><button type="submit">Add</button><button onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    this.setState({ cr: false })
                                }}>Cancel</button></div>
                            </form>}
                        </div>
                    </div>
                    <div className={"flexer rel " + (this.state.view === 'files' ? '' : 'hidden')}>
                        <FileSelector />
                    </div>
                    <div className={"b1 " + (this.state.view === 'permissions' ? '' : 'hidden')}>
                        <h3 className="formHead">Package Permissions</h3>
                        <div className="b1 fifty">
                            <label htmlFor="expires">Select a Package Expiration Date</label>
                            <input type="date" name="expires" onChange={e => this.setState({ expires: new Date(e.target.value) })}></input>
                        </div>
                        <div className="checkInput">
                            <label htmlFor="requiresFTP">Does the client require an FTP download?</label>
                            <input type="checkbox" value={this.state.requiresFTP} onChange={e => this.setState({ requiresFTP: !this.state.requiresFTP })}></input>
                        </div>
                        {this.state.requiresFTP && <div className="b1 fifty">
                            <h3 className="formHead">FTP INFO</h3>
                            <div className="b2">
                                <div className="b1 fifty">
                                    <label htmlFor="ftpUserName">Username</label>
                                    <input type="text" value={this.state.ftpUserName} onChange={e => this.setState({ ftpUserName: e.target.value })} name="ftpUserName"></input>
                                </div>
                                <div className="b1 fifty">
                                    <label htmlFor="ftpUserName">Password</label>
                                    <div className="rel">
                                        <input value={this.state.ftpPassword} onChange={e => this.setState({ ftpPassword: e.target.value })} type={this.state.showPass ? 'text' : "password"} name="ftpPassword"></input>
                                        <div onClick={e => this.setState({ showPass: !this.state.showPass })} style={{ position: 'absolute', zIndex: 1, cursor: 'pointer', top: '10px', right: '-15px' }}>{this.state.showPass ? 'HIDE' : 'SHOW'}</div>
                                    </div>
                                </div>
                                <div className="b1 fifty">
                                    <label htmlFor="ftpExpires">Expires</label>
                                    <input type="date" onChange={e => this.setState({ ftpExpires: new Date(e.target.value) })} name="ftpExpires"></input>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <button className="cento green-btn" style={{ width: '80%' }} onClick={e => {
                    if (!this.state.recipients?.length > 0) {
                        return window.flash('Missing Recipients', 5000)
                    } else if (!this.state.expires || typeof this.state.expires !== 'object' || typeof this.state.expires.getDate !== 'function') {
                        return window.flash('Please choose a valid date for package expiration.')
                    } else if (this.state.expires && typeof this.state.expires === 'object' && typeof this.state.expires.getDate === 'function' && this.state.expires.getTime() - new Date().getTime() < 1000 * 60 * 60 * 24) {
                        return window.flash('Please choose a package expiration date at least 24 hours out. You may remove the package after a shorter period of time manually.', 10000)
                    } else if (this.state.requiresFTP && (this.state.ftpExpires && typeof this.state.ftpExpires === 'object' && typeof this.state.ftpExpires.getDate === 'function' && this.state.ftpExpires.getDate() - this.state.expires.getTime() > 0)) {
                        return window.flash('Please choose a ftp expiration date before or the same as the package expiration date.', 7000)
                    } else if (this.state.selectedFiles?.length < 1) {
                        return window.flash('Please select some files.', 4000)
                    } else {
                        this.setState({ showFileLists: true })
                    }
                }}>Create Package</button>
            </div>
        </div>)
    }
}
export default CreatePackage