import React, { Component } from 'react'
import Toggle from './helper/toggle'
class Settings extends Component {
    state = {
        colorMode: false
    }
    componentDidMount(){
        //this.getUserSettings()
    }
    getUserSettings(){
        window.app.request('/user-settings').then(result => {
            this.setState({...result.settings})
        }).catch(e => window.flash(e))
    }
    saveUserSettings(){
        window.app.request('/user-settings', 'post', this.state).then(result => {
            this.setState({...result.settings})
        }).catch(e => window.flash(e))
    }
    render() {
        return (<div id="settingsPage" className="b1 full rel">
            <div style={{ marginTop: '4vh' }} className="b1">
                <h1>Settings</h1>
                {this.state.notifications && <div className="b1">
                    <h2>Notification Settings</h2>
                    <h3>Notification Methods</h3>
                    <div className="b2 fifty">
                        <div className="b1">
                            <label htmlFor="">Email Notifications</label>
                            <Toggle init={this.state.notifications.email} change={e => this.setState({notifications: {...this.state.notifications, email: e}})} />
                        </div>
                        <div className="b1">
                            <label htmlFor="">App Notifications</label>
                            <Toggle init={this.state.notifications.app} change={e => this.setState({notifications: {...this.state.notifications, app: e}})} />
                        </div>
                        <div className="b1">
                            <label htmlFor="">Text Notifications</label>
                            <Toggle init={this.state.notifications.text} change={e => this.setState({notifications: {...this.state.notifications, text: e}})} />
                        </div>
                    </div>
                    <h3>Notification Types</h3>
                    <div className="b2 fifty">
                        <div className="b1">
                            <label htmlFor="">Upload Notifications</label>
                            <Toggle init={this.state.notifications.uploadNotifications} change={e => this.setState({notifications: {...this.state.notifications, uploadNotifications: e}})} />
                        </div>
                        <div className="b1">
                            <label htmlFor="">Download Notifications</label>
                            <Toggle init={this.state.notifications.downloadNotifications} change={e => this.setState({notifications: {...this.state.notifications, downloadNotifications: e}})} />
                        </div>
                        {window.app.state.type === 'admin' && <div className="b1">
                            <label htmlFor="">File Change Notifications</label>
                            <Toggle init={this.state.notifications.fileChangeNotifications} change={e => this.setState({notifications: {...this.state.notifications, fileChangeNotifications: e}})} />
                        </div>}
                        {window.app.state.type === 'admin' && <div className="b1">
                            <label htmlFor="">Title Change Notifications</label>
                            <Toggle init={this.state.notifications.titleChangeNotifications} change={e => this.setState({notifications: {...this.state.notifications, titleChangeNotifications: e}})} />
                        </div>}
                    </div>
                </div>}
                <div className="b1">
                    <h2>App Settings</h2>
                    <div className="b2">
                        <div className="b1">
                            <label htmlFor="colorMode">Color Preference</label>
                            <div className="b2">
                            <span>{window.app.state.colorMode === 'dark' ? 'Dark Mode' : 'Light Mode'}</span><Toggle change={e => window.app.toggleDarkmode()} init={window.app.state.colorMode === 'dark'}></Toggle>
                            </div>
                        </div>
                    </div>
                </div>
                {window.app.state.type === 'super' && false && <div className="b1">
                    <h2>Admin Settings</h2>
                    <div className="b2">
                        <div className="b1">
                            <label htmlFor="bucketType">Bucket Type</label>
                            <span>{this.state.bucketType === 'allPublic' ? 'Public' : 'Private'}</span>
                            <input type="checkbox" checked={this.state.bucketType === 'allPublic' ? false : true} onChange={e => this.setState({bucketType: e.target.checked ? 'allPrivate' : 'allPublic'})} name="bucketType"></input>
                        </div>
                    </div>
                </div>}
                {false && <button onClick={e => {
                    this.saveUserSettings()
                }}>Save Settings</button>}
            </div>
        </div>)
    }
}
export default Settings