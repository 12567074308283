import React, { Component } from 'react'
import './helper/logo'
import './helper/svgs'
import { Link } from "react-router-dom";
import NotificationIcon from './notification/notificationIcon'
import config from '../config';
const URL_REDIRECT=config.URL_REDIRECT
function SideBar (props) {
  let close = () => {
    window.app.setState({ showNav: false, showNotifications: false })
  }
  let open = () => {
    window.app.setState({ showNav: true })
  }
  let toggle = () => {
    window.app.setState({showNav: !window.app.state.showNav})
  }
    return (<div id="sidebar">
      <div id="logo" className="navtop" >
        <stacked-logo style={{ cursor: 'pointer' }} onClick={() => {
          window.app.setState({ showNav: false })
          window.redirect(URL_REDIRECT+'/')
        }}></stacked-logo>
        <div onClick={toggle} id="openSidebar"><i className="fas fa-bars"></i></div>
      </div>
      {window.app.state.auth && window.app.state.userID ? (<div className="navcent">
        <Link to={`${URL_REDIRECT}/`}><button onClick={close} className={(props.location === URL_REDIRECT+'/' || props.location === URL_REDIRECT+'/login') ? 'active' : ''}><i className="navIcon"><home-icon color={(props.location === URL_REDIRECT+'/' || props.location === URL_REDIRECT+'/login') ? 'var(--p)' : 'var(--g)'}></home-icon></i>{window.app.state.showNav && <span>Home</span>}</button></Link>
        <button style={props.Notifications && { color: 'var(--p)!important'}} className={props.showNotifications ? 'active' : ''} style={{padding: 0, overflow: 'visible', maxWidth: '40px'}}><NotificationIcon notifications={window.app.state.notifications} click={() => {
          window.app.setState({ showNav: false, showNotifications: !window.app.state.showNotifications })
        }}></NotificationIcon></button>
        <Link to={`${URL_REDIRECT}/titles`}><button onClick={close} className={(props.location === URL_REDIRECT+'/titles') ? 'active' : ''}><i className="navIcon"><film-icon color={(props.location === URL_REDIRECT+'/titles') ? 'var(--p)' : 'var(--g)'}></film-icon></i>{window.app.state.showNav && <span>Titles</span>}</button></Link>
        <Link to={`${URL_REDIRECT}/upload`}><button onClick={close} className={(props.location === URL_REDIRECT+'/upload') ? 'active' : ''}><i className="navIcon"><file-icon color={(props.location === URL_REDIRECT+'/upload') ? 'var(--p)' : 'var(--g)'}></file-icon></i>{window.app.state.showNav && <span>Upload</span>}</button></Link>
        {window.isInternal() && false && <Link to="/files"><button onClick={close} className={(props.location === URL_REDIRECT+'/files') ? 'active' : ''}><i className="navIcon"><file-icon color={(props.location === URL_REDIRECT+'/files') ? 'var(--p)' : 'var(--g)'}></file-icon></i>{window.app.state.showNav && <span>Files</span>}</button></Link>}
        {<Link to={`${URL_REDIRECT}/packages`}><button onClick={close} className={(props.location === URL_REDIRECT+'/packages') ? 'active' : ''}><i className="navIcon"><package-icon color={(props.location === URL_REDIRECT+'/packages') ? 'var(--p)' : 'var(--g)'}></package-icon></i>{window.app.state.showNav && <span>Packages</span>}</button></Link>}
        {window.isInternal() && <Link to={`${URL_REDIRECT}/hds`}><button onClick={close} className={(props.location === URL_REDIRECT+'/hds') ? 'active' : ''}><i className="navIcon"><ssd-icon color={(props.location === URL_REDIRECT+'/hds') ? 'var(--p)' : 'var(--g)'}></ssd-icon></i>{window.app.state.showNav && <span>Hard Drives</span>}</button></Link>}
        {window.isAdmin() && <Link to={`${URL_REDIRECT}/user-list`}><button onClick={close} className={(props.location === URL_REDIRECT+'/new-user') ? 'active' : ''}><i className="navIcon"><newuser-icon color={(props.location === URL_REDIRECT+'/new-user') ? 'var(--p)' : 'var(--g)'}></newuser-icon></i>{window.app.state.showNav && <span>New User</span>}</button></Link>}
      </div>) : (<div className="b1"><Link to={`${URL_REDIRECT}/`}><button onClick={close}><i className="navIcon"><home-icon color={'var(--g)'}></home-icon></i>{window.app.state.showNav && <span>Home</span>}</button></Link></div>)}
      <div className="navbot">{window.app.state.auth && window.app.state.userID && <button onClick={() => {close(); window.app.logOut()}}><logout-icon color={'var(--g)'}></logout-icon><i className="navIcon"></i>{window.app.state.showNav && ' Log Out'}</button>}</div>
    </div>)
}
export default SideBar