import { Route } from "react-router-dom";
import { Component } from "react";
import PDF_ICON from "./assets/pdf.svg";
import VIDEO_ICON from "./assets/video.svg";
import LIST_ICON from "./assets/list.png";
import GRID_ICON from "./assets/grid.jpg";
import config from "./config";
const URL_REDIRECT=config.URL_REDIRECT
class Docs extends Component {
	state = {
		document: "",
		name: "",
		current: "",
		edit: false,
		toggle: false,
		draft: false,
		published: false,
		gotPolicies: false,
		stored: null,
		categories: [],
		namefilter: null,
		filteredCats: [],
		filteredTags: [],
		gotCategories:false,
		taglist: [],
		docTypes: [],
		category: "",
		tags: [],
		file_url: null,
		autosave: localStorage.getItem("autosave") === "true" ? true : false,
		isLoading: false,
		isDraftLoading: false,
		isRevertDraftLoading: false,
		document_type: "pdf",
		view_type: "grid",
	};
	componentWillMount() {
		window.docs = this;
	}
	async componentDidMount() {
		window.app.setState({
			namefilter: null,
		});
		if (!this.props.docType)
			if (
				this.props.policies?.length > 0 &&
				(this.props.location === process.env.PUBLIC_URL + "/" ||
					this.props.location ===
						process.env.PUBLIC_URL + "/policy")
			) {
				if (
					!this.state.current &&
					this.props.queries.find((u) => u.key === "?id")
				) {
					let id = this.props.queries.find(
						(u) => u.key === "?id"
					).value;
					let policy = this.props.policies.find((u) => u._id === id);
					if (policy) {
						let {
							content,
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							docTypes = [],
							document_type,
							file_name
						} = policy;
						let file_url =
							window.API + "/public/pdf/" + policy?.file_name;
						let docType =
							this.props.docType ||
							(docTypes?.length > 0 ? docTypes[0] : null) ||
							"policy";
						if (!this.props.docType)
							window.app.setState({ docType: docType });
							window.docs.setState({ name: name });
						if (!docTypes?.length > 0) docTypes = [docType];
						window.docs.setState({ name: name });
						this.setState({
							current: policy._id,
							name,
							document:
								this.state.edit && draft
									? draftContent
									: content,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							docTypes,
							file_url,
							document_type,
							file_name
						});
					} else {
						window.redirect(process.env.PUBLIC_URL + "/");
					}
				}
			}
		if (!this.state.gotTags && !this.gettingTags)
			this.getTaglist().catch((e) => window.flash(e));
		if (!this.state.gotCategories && !this.gettingCategories)
			this.getCategories().catch((e) => window.flash(e));
	}
	setTypes = (types) => {
		this.setState({ docTypes: types });
	};
	componentDidUpdate() {
		// console.log(this.props.queries.find((u) => u.key === "?type"))
		if (
			this.props.location === process.env.PUBLIC_URL + "/policy" &&
			this.props.policies?.length > 0 &&
			!this.state.current &&
			this.props.queries.find((u) => u.key === "?id")
			) {
			console.log('this.props.queries');
			let id = this.props.queries.find((u) => u.key === "?id").value;
			let policy = this.props.policies.find((u) => u._id === id);
			
			if (policy) {
				let {
					content,
					name,
					draftContent,
					draft,
					published,
					created,
					modified,
					category,
					tags,
					docTypes = [],
					document_type,
					file_name
				} = policy;
				let file_url = window.API + "/public/pdf/" + policy?.file_name;
				let docType =
					this.props.docType ||
					(docTypes?.length > 0 ? docTypes[0] : null) ||
					"policy";
				if (!this.props.docType)
					window.app.setState({ docType: docType });
				if (!docTypes?.length > 0) docTypes = [docType];
				this.setState({
					current: policy._id,
					name,
					document: this.state.edit && draft ? draftContent : content,
					draft,
					published,
					created,
					modified,
					category,
					tags,
					docTypes,
					file_name,
					file_url,
					document_type,
				});
			} else {
				window.redirect(process.env.PUBLIC_URL + "/");
			}
		}
		if (!this.state.gotTags && !this.gettingTags)
			this.getTaglist().catch((e) => window.flash(e));
		if (!this.state.gotCategories && !this.gettingCategories)
			this.getCategories().catch((e) => window.flash(e));
	}
	getTaglist = () => {
		return new Promise((res, rej) => {
			this.gettingTags = true;
			window.app
				.request("/taglist2", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingTags = false;
					let { tags } = r;
					this.setState({ taglist: tags, gotTags: true });
					window.app.setState({ taglist: tags, gotTags: true });
					res(tags);
				})
				.catch((e) => {
					this.gettingTags = false;
					rej(e);
				});
		});
	};
	getCategories = () => {
		return new Promise((res, rej) => {
			this.gettingCategories = true;
			window.app
				.request("/categories", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingCategories = false;
					let { categories = [] } = r;
					window.app.setState({
						categories: [...categories],
						gotCategories: true,
					});
					this.setState({
						categories: [...categories],
						gotCategories: true,
					});
					res(categories);
				})
				.catch((e) => {
					this.gettingCategories = false;
					rej(e);
				});
		});
	};
	getPolicies = () => {
		return new Promise((res) => {
			this.setState(
				{
					policies: window.app.state.policies?.filter(
						(a) => a.docTypes.includes(this.props.docType) || []
					),
					gotPolicies: true,
				},
				() => res()
			);
		});
	};
	
	new = () => {
		//console.log('tapped here');
		this.setState({ current: "", document: "", name: "", edit: !this.state.edit }, () =>{
		    window.app.setState({edit_policy:[],edit: true,toggle:true})
			window.redirect(process.env.PUBLIC_URL + "/document?type="+window.app.state.docType)
		}
		);
	};
	set = (id) => {
		let policy = window.app.state.policies?.find((u) => u._id === id);
		//console.log('9999',window.app.state);
		if (!policy) return window.flash("Unable to load document" + id);
		window.app.setState({edit_policy:policy,edit: false,toggle:false})
		//this.press.bind(this)
		let {
			file_name,
			content,
			name,
			draftContent,
			draft,
			published,
			created,
			modified,
			category,
			tags,
			docTypes = [],
			document_type,
		} = policy;
		let file_url = window.API + "/public/pdf/" + policy?.file_name;
		let docType =window.app.state.docType ||
			 this.props.docType ||
			 (docTypes?.length > 0 ? docTypes[0] : null) ||
			 "policy";
		if (!this.props.docType) window.app.setState({ docType: window.app.state.docType });
		if (!docTypes?.length > 0) docTypes = [docType];
		if (window.app.state.auth && draft) {
			this.setState(
				{
					current: id,
					document: draftContent,
					draft: true,
					published,
					category,
					tags,
					name,
					edit: true,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() =>
					window.redirect(
						process.env.PUBLIC_URL + `/document?id=${id}`
					)
			);
			//console.log(2222,this.state)
		} else if (
			!window.app.state.auth ||
			window.app.state.type !== "admin"
		) {
			window.docs.setState(
				{
					current: id,
					document: content,
					name,
					category,
					tags,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() => {
					// window.redirect(process.env.PUBLIC_URL + `/document?id=${id}`)
					if (file_name) {
						window.open(`${file_url}`, "_blank");
					} else {
						window.redirect(process.env.PUBLIC_URL + `/`);
					}
				}
			);
		} else if (window.app.state.auth) {
			this.setState(
				{
					current: id,
					edit: false,
					document: content,
					draft: false,
					published,
					category,
					tags,
					name,
					created,
					modified,
					docTypes,
					file_url,
					file_name,
					document_type,
				},
				() =>
					window.redirect(
						process.env.PUBLIC_URL + `/document?id=${id}`
					)
			);
		}
	};
	edit = () => {
		this.setState({ edit: !this.state.edit }, () => {
			if (this.state.edit === false) {
				this.getPolicies().catch((e) => window.flash(e));
			} else if (this.state.edit === true && this.state.toggle) {
				let policy = this.props.policies.find(
					(u) => u._id === this.state.current
				);
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft
						? content
						: this.state.stored
						? this.state.stored
						: draftContent;
					this.setState(
						{
							toggle: !this.state.toggle,
							draft: !this.state.draft,
							document,
						},
						() => {
							if (window.editor)
								window.editor.setState({
									editor: window.editor.init(),
								});
						}
					);
				}
			} else if (this.state.edit === true) {
				let policy = this.props.policies.find(
					(u) => u._id === this.state.current
				);
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft ? content : draftContent;
					this.setState({ draft, document }, () => {
						if (window.editor)
							window.editor.setState({
								editor: window.editor.init(),
							});
					});
				}
			}
		});
	};
	delete = () => {
		window.app
			.request("/delete-policy", "post", { _id: this.state.current })
			.then((r) => {
				window.flash("Deleted Document");
				window.app
					.getPolicies()
					.then(() => {
						window.redirect(process.env.PUBLIC_URL + "/");
					})
					.catch((e) => window.flash(e));
			})
			.catch((e) => window.flash(e));
	};
	

	addTag = (tag) => {
		this.setState({
			tags: [...this.state.tags, tag].reduce((a, b) => {
				if (!a.includes(b)) a.push(b);
				return a;
			}, []),
		});
	};
	removeTag = (tag) => {
		this.setState({ tags: [...this.state.tags].filter((u) => u !== tag) });
	};
	changeCategory = (category) => {
		this.setState({ category });
	};
	halfA(array, half) {
		let a = [];
		if (!array) return a;
		for (let i = half === 1 ? 0 : 1; i < array.length; i += 2) {
			a.push(array[i]);
		}
		return a;
	}
	divideArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) o[i] = [];
		let c = 0;
		return Object.entries(
			array.reduce((a, b) => {
				if (c < parts) {
					a[c].push(b);
				} else {
					c = 0;
					a[c].push(b);
				}
				c++;
				return a;
			}, o)
		).map((u) => u[1]);
	}
	splitArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) {
			o[i] = [];
			let dec = Math.floor((array.length / parts) * i);
			let inc = Math.floor((array.length / parts) * (i + 1));
			for (let z = dec; z < inc && z < array.length; z++) {
				if (array[z]) o[i].push(array[z]);
			}
		}
		return Object.entries(o).reduce((a, b) => {
			a.push(b[1]);
			return a;
		}, []);
	}
	nameSort(array) {
		return array.sort((a, b) =>
			a.name < b.name ? -1 : a.name === b.name ? 0 : 1
		);
	}
	handleSave = () => {
		clearTimeout(this.save);
		this.save = setTimeout(() => {
			if (this.saving) return;
			let policy = this.props.policies.find(
				(u) => u._id === this.state.current
			);
			if (policy) {
				let { draft, published, content, draftContent } = policy;
				this.savePolicy({
					name: this.state.name,
					content: draft ? content : this.state.document,
					draftContent:
						this.state.draft && this.state.edit
							? this.state.document
							: draftContent,
					published,
					draft,
					tags: this.state.tags,
					category: this.state.category,
					_id: this.state.current,
				})
					.then((policy) => {
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
						} = policy;
						this.setState(
							{
								current: policy._id,
								name,
								document: draftContent,
								draft,
								published,
								created,
								modified,
								category,
								tags,
							},
							() => {
								if (window.editor)
									window.editor.setState({
										editor: window.editor.init(),
									});
							}
						);
					})
					.catch((e) => window.flash(e));
			}
		}, 2150);
	};
	uploadImage(e) {
		return new Promise((res, rej) => {
			let data = new FormData();
			data.append("image", e);
			let that = new XMLHttpRequest();
			that.onerror = (e) => {
				window.flash(e);
				rej();
			};
			that.onabout = (e) => {
				window.flash(e);
				rej();
			};
			that.onreadystatechange = () => {
				if (that.readyState === 4) {
					let d = JSON.parse(that.responseText);
					if (d.error) {
						window.flash(d.message);
						return rej();
					}
					res({ data: { link: d.link } });
				}
			};
			that.open("POST", window.API + "/upload-image", true);
			that.setRequestHeader("authorization", window.app.state.auth);
			that.send(data);
		});
	}
	extractContent(text) {
		try {
			return JSON.parse(text)
				.blocks.map((u) => u.text)
				.join(" ");
		} catch (e) {
			console.log(e);
			return "";
		}
	}
	documentSearch(event) {
		const newValue = event.target.value;
		if (newValue) {
			window.app.setState({
				namefilter: newValue,
			});
		} else {
			window.app.setState({
				namefilter: null,
			});
		}
	}

	toogleCategory(event) {
		const newValue = event.target.value;
		if (newValue !== "all") {
			window.app.setState({
				filteredCats: newValue,
			});
		} else {
			window.app.setState({
				filteredCats: [],
			});
		}
	}

	render() {
		window.docs = this;
		return (
			<div className="App">
					<div className="w-100">
						<div className="doc-title">
							<div className="d-flex">
								<h1>
									{this.props?.docType?.toUpperCase()}{" "}
									DOCUMENTS
								</h1>
								<div className="view-toogle d-none">
									<img
										onClick={(e) => {
											let view_type =
												window.app.state.view_type;
											switch (view_type) {
												case "grid":
													view_type = "list";
													break;

												default:
													view_type = "grid";
													break;
											}
											window.app.setState({
												view_type: view_type,
											});
										}}
										className="icon"
										src={
											window.app.state.view_type ===
											"grid"
												? GRID_ICON
												: LIST_ICON
										}
										alt="icon"
									/>
									{/* <button
										className={
											window.app.state.view_type ===
											"grid"
												? ""
												: "active"
										}
										onClick={(e) => {
											window.app.setState({
												view_type: "grid",
											});
										}}
									>
										GRID
									</button> */}
								</div>
							</div>
							<p>
								{this.props?.docType?.toUpperCase() === "POLICY"
									? "These company policies are guidelines to help the employer, employee and contractor know their expectations with the company."
									: this.props?.docType?.toUpperCase() ===
									  "SOP"
									? "These company standard operating procedures provide detailed instructions for specific tasks to streamline processes and achieve maximum efficiency."
									: ""}
							</p>
						</div>
						<div className="search-filter mt-4">
							<div className="buttonb3">
								<button onClick={()=>{
									window.app.setState({
										namefilter: null,
									});
									window.redirect(URL_REDIRECT+"/")
									}}>
								Home
								</button>
								<button onClick={window.docs.new}>
									New {window.app.state.docType}
								</button>
							</div>
							 <div class="catsearch">
								<div className="category">
									<label htmlFor="categories">Category</label>
									<select
										onChange={this.toogleCategory}
										name="categories"
										id="categories"
									>
										<option value="all">All</option>
										{window.app.state.categories &&
											window.app.state.categories.map((u, i) => (
												<option
													selected={this.state.filteredCats.includes(
														u
													)}
													value={u}
													key={i}
												>
													{u.toUpperCase()}
												</option>
											))}
									</select>
								</div>
								<div className="mobsearch">
									<label for="search" className="catserch">
										Search
									</label>
									<input
										onChange={this.documentSearch}
										type="text"
										placeholder="Search"
										id="searchkey"
										name="searchkey"
									></input>
								</div>
							</div>
						</div>
						

						<div className="list-inner">
							<div className="list-wrapper">
								<div className="list-row" id="policy-list">
									{this.nameSort(
										(this.props.type === "admin"
											? this.props.policies
											: this.props.policies.filter(
													(u) => u.published
											  )
										).filter(
											(a) =>
												a.docTypes.includes(
													this.props.docType
												) &&
												(window.app.state.filteredCats
													?.length > 0
													? window.app.state.filteredCats.includes(
															a.category
													  )
													: true) &&
												(window.app.state.namefilter
													? a.content
															.toLowerCase()
															.includes(
																window.app.state.namefilter
																	.trim()
																	.toLowerCase()
															) ||
													  a.name
															.toLowerCase()
															.includes(
																window.app.state.namefilter
																	.trim()
																	.toLowerCase()
															)
													: true)
										)
									).map((u, i) => {
										if (!u) return null;
										return (
											<div
												className={
													window.app.state
														.view_type === "grid"
														? "p-box"
														: "p-list"
												}
											>
												<div
													className="policy-item"
													key={i}
													id={u._id}
													onClick={(e) => {
														this.set(u._id);
													}}
												>
													<div className="img">
														<img
															src={
																u?.document_type ===
																"video"
																	? VIDEO_ICON
																	: PDF_ICON
															}
															alt="icon"
														/>
													</div>
													<span>
														{u.name ||
															`Untitled ${window.capitalize(
																this.props
																	.docType
															)}`}
													</span>
												</div>
											</div>
										);
									})}
									{!(
										this.props.type === "admin"
											? this.props.policies
											: this.props.policies.filter(
													(u) => u.published
											  )
									).filter(
										(a) =>
											a.docTypes.includes(
												this.props.docType
											) &&
											(window.app.state.filteredCats
												?.length > 0
												? window.app.state.filteredCats.includes(
														a.category
												  )
												: true) &&
											(window.app.state.namefilter
												? a.content
														.toLowerCase()
														.includes(
															window.app.state.namefilter
																.trim()
																.toLowerCase()
														) ||
												  a.name
														.toLowerCase()
														.includes(
															window.app.state.namefilter
																.trim()
																.toLowerCase()
														)
												: true)
									).length && (
										<div className="no-items">
											<span>NO ITEMS FOUND</span>
										</div>
									)}
								</div>
							</div>
						</div>
					</div> 
			</div>
		);
	}
}
export default Docs;
