import { Route } from "react-router-dom";
import { Component } from "react";
import { TextEditor, Preview } from "./texteditor";
import config from "./config";
import Spinner from "./components/helper/spinner";
class Documents extends Component {
	queries() {
		let search = this.props.location.search;
		if (!search) return [];
		search = search.split("");
		let items = search.join("").split("&");
		return items
			.map((u) => {
				let a = u.split("=");
				if (a && a[0] && a[1]) return { key: a[0], value: a[1] };
				return null;
			})
			.filter((u) => u);
	}
	state = {
		document: "",
		edit_policy: window.app.state.edit_policy,
		name: window.app.state.edit_policy?.name,
		current: "",
		edit: window.app.state.edit,
		toggle: window.app.state.toggle,
		draft: false,
		published: false,
		gotPolicies: false,
		stored: null,
		categories: window.app.state.categories,
		namefilter: null,
		filteredCats: [],
		filteredTags: [],
		taglist: window.app.state.taglist,
		docType:localStorage.getItem('policyType') || window.app.state.docType ,
		docTypes:window.app.state?.edit_policy?.docTypes || [localStorage.getItem('policyType')],
		category: window.app.state.edit_policy?.category?window.app.state.edit_policy.category:'',
		tags: window.app.state.edit_policy?.tags?window.app.state.edit_policy.tags:[],
		file_url: window.API + "/public/pdf/" + window.app.state.edit_policy?.file_name,
		autosave: localStorage.getItem("autosave") === "true" ? true : false,
		isLoading: false,
		isDraftLoading: false,
		isRevertDraftLoading: false,
		document_type:window.docs?.state?.document_type || "pdf",
		view_type: "grid",
		gettingPolicies:false,
		gotCategories:false,
	};

	componentWillMount() {
		window.docs = this;
	}
	
	componentDidMount() {
		if (!window.app.state.edit_policy) {
			this.state.gettingPolicies = true;
			this.getPolicies().catch((e) => window.flash(e));
			this.getCategories().catch((e) => window.flash(e));
		}	
	}
	setTypes = (types) => {
		this.setState({ docTypes: types });
	};
	componentDidUpdate() {
		if (!window.app.state.edit_policy) {
			//window.redirect(process.env.PUBLIC_URL + "/")
			//this.getPolicies().catch((e) => window.flash(e))
			//console.log(2222,this.props.queries.find())
		} 
	}

	getPolicies = (docTypes) => {
		return new Promise((res, rej) => {
			window.app.request("/all-policies",
					"post",
					{
						type: { $in: docTypes || ["policies"] },
					}
				).then((r) => {
					this.state.gettingPolicies = false;
					window.app.setState({
						...this.state,
						policies: r.policies,
						fpolicies: r.policies,
						gotPolicies: true,
						docTypes: r.policies?.reduce((a, b) => {
							if (b.docTypes)
								b.docTypes.forEach((u) => {
									if (!a.includes(u)) a.push(u);
								});
							return a;
						}, []),
					})
					if (!this.state.current && this.queries().find((u) => u.key === "?id")) {
						let id = this.queries().find((u) => u.key === "?id").value;
							let policy = r.policies.find((u) => u._id === id);
							if (policy) {
								let {
									content,
									name,
									draftContent,
									draft,
									published,
									created,
									modified,
									category,
									docType,
									tags,
									docTypes = [],
									document_type,
									file_name
								} = policy;
								let file_url =
									window.API + "/public/pdf/" + policy?.file_name;	
								if (!this.props.docType)
									window.app.setState({ docType: docType });
								if (docTypes?.length > 0) 
									docTypes = [docType];
								
								this.setState({
									edit_policy:policy,
									current: policy._id,
									name,
									document:
										this.state.edit && draft
											? draftContent
											: content,
									draft,
									published,
									created,
									modified,
									category,
									tags,
									docTypes:policy.docTypes || docTypes,
									docType:localStorage.getItem('policyType'),
									file_url,
									document_type,
									file_name
								});
									
							}		
					}else{
						let doctype=localStorage.getItem('policyType')
					    this.setState({ current: "", document: "", name: "", edit: !this.state.edit,docType:doctype }, () =>{
					})
					}
					if (!this.state.gotTags && !this.gettingTags)
						this.getTaglist().catch((e) => window.flash(e));
					if (!this.state.gotCategories && !this.gettingCategories)
						this.getCategories().catch((e) => window.flash(e));
				})
				.catch((e) => {
					console.log("fetch error", e);
					this.state.gettingPolicies = false;
					window.flash(
						"Unable to authenticate you, please log in again."
					);
					rej(e);
				});
		});
	};
	getTaglist = () => {
		return new Promise((res, rej) => {
			this.gettingTags = true;
			window.app
				.request("/taglist2", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingTags = false;
					let { tags } = r;
					this.setState({ taglist: tags, gotTags: true });
					res(tags);
				})
				.catch((e) => {
					this.gettingTags = false;
					rej(e);
				});
		});
	};
	getCategories = () => {
		return new Promise((res, rej) => {
			this.gettingCategories = true;
			window.app
				.request("/categories", "post", { docType: this.props.docType })
				.then((r) => {
					this.gettingCategories = false;
					let { categories = [] } = r;
					this.setState({
						categories: [...categories],
						gotCategories: true,
					});
					window.app.setState({
						categories: [...categories],
						gotCategories: true,
					});
					res(categories);
				})
				.catch((e) => {
					this.gettingCategories = false;
					rej(e);
				});
		});
	};
	changeCategory = (category) => {
		this.setState({ category });
	};
	
	savePolicy = (policy) => {
		return new Promise((res, rej) => {
			if (!policy) return rej("NO POLICY PASSED");
			if (!policy?.name) return rej("Document title is missing");
			this.saving = true;
			fetch(window.API + "/save-policy", {
				method: "post",
				headers: {
					authorization: window.app.state.auth,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					policy: {
						...policy,
						docTypes:
							this.state.docTypes?.length < 1
								? [this.props.docType]
								: [...this.state.docTypes]
										.reduce((a, b) => {
											if (!a.includes(b)) a.push(b);
											return a;
										}, [])
										.filter((a) => a),
					},
				}),
			})
				.then((r) => r.json())
				.then((r) => {
					this.saving = false;
					if (r.error) {
						if (r.message && /not authorized/.test(r.message)) {
							window.flash(
								"Issue authenticating you, please log in again."
							);
							window.app.logOut();
						} else {
							return rej(r.message || JSON.stringify(r));
						}
					}
					let { policy } = r;
					window.flash(
						`${policy.draft ? "Draft" : "Document"} Saved`
					);
					res(policy);
				})
				.catch((e) => rej(e));
		});
	};
	

	edit = () => {
		this.setState({ edit: !this.state.edit }, () => {
			if (this.state.edit === false) {
				//this.getPolicies().catch((e) => window.flash(e));
			} else if (this.state.edit === true && this.state.toggle) {
                let policy=this.state.edit_policy
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft
						? content
						:draftContent;
					this.setState(
						{
							toggle: !this.state.toggle,
							draft: !this.state.draft,
							document,
						},
						() => {
							if (window.editor)
								window.editor.setState({
									editor: window.editor.init(),
								});
						}
					);
				}
			} else if (this.state.edit === true) {
                let policy=this.state.edit_policy
				if (policy) {
					let { draft, content, draftContent } = policy;
					let document = !draft ? content : draftContent;
					this.setState({ draft, document }, () => {
						if (window.editor)
							window.editor.setState({
								editor: window.editor.init(),
							});
					});
				}
			}
		});
	};
	delete = () => {
		let id = window.app.state.edit_policy._id
		window.app
			.request("/delete-policy", "post", { _id: id })
			.then((r) => {
				window.flash("Deleted Document");
				window.app.setState({
					reload:true
				   })
					window.redirect(config.URL_REDIRECT+'/policy?type='+this.state.docType)
			})
			.catch((e) => window.flash(e));
	};
	setDraft = (revert) => {
		let id = this.state?.edit_policy?._id?this.state?.edit_policy?._id:this.queries().find((u) => u.key === "?id")?.value;
		if (revert) {
			if (!id) {
				//this.new();
			} else {
				let policy = this.state.edit_policy?this.state.edit_policy:window.app.state.edit_policy;
				if (policy && policy.published) {
					policy.draftContent = policy.content;
					this.setState({ isRevertDraftLoading: true });
					this.savePolicy(policy)
						.then((policy) => {
							this.setState({ isRevertDraftLoading: false });
							let {
								name,
								draftContent,
								draft,
								published,
								created,
								modified,
								category,
								tags,
								document_type,
							} = policy;
							this.setState(
								{
									edit_policy:policy,
									current: policy._id,
									name,
									document: draftContent,
									draft,
									published,
									created,
									modified,
									category:category?category:this.state.category,
									tags,
									document_type:this.state.document_type,
								},
								() => {
									if (window.editor)
										window.editor.setState({
											editor: window.editor.init(),
										});
								}
							);
						})
						.catch((e) => {
							this.setState({ isRevertDraftLoading: false });
							window.flash(e);
						});
				} else {
					this.setState({ document: null });
				}
			}
		} else {
			if (!id) {
				let policy = {
					name: this.state.name?this.state.name:window.docs.state.name,
					content: this.state.document,
					draftContent: this.state.document,
					category:this.state.category,
					tags:this.state.tags,
					published: false,
					draft: true,
					document_type:this.state.document_type,
				};
				this.setState({ isDraftLoading: true });
				this.savePolicy(policy)
					.then((policy) => {
						this.setState({ isDraftLoading: false });
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						} = policy;
						this.setState({
							edit_policy:policy,
							current: policy._id,
							name,
							document: draftContent,
							draft,
							published,
							created,
							modified,
							category:category?category:this.state.category,
							tags,
							document_type,
						});
						window.app.setState({
							reload:true
						   })
						window.redirect(config.URL_REDIRECT+'/policy?type='+this.state.docType)
					})
					.catch((e) => {
						this.setState({ isDraftLoading: false });
						window.flash(e);
					});
			} else {
				let policy = this.state.edit_policy?this.state.edit_policy:window.app.state.edit_policy;
				if (!policy.draft) policy.draft = true;
				policy.draftContent = this.state.document;
				policy.category=this.state.category;
				policy.tags=this.state.tags;
				policy.document_type=this.state.document_type;
				this.setState({ isDraftLoading: true });
				this.savePolicy(policy)
					.then((policy) => {
						this.setState({ isDraftLoading: false });
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
							document_type,
						} = policy;
						this.setState({
							edit_policy:policy,
							current: policy._id,
							name,
							document: draftContent,
							draft,
							published,
							created,
							modified,
							category:category?category:this.state.category,
							tags,
							document_type,
						});
						window.app.setState({
							reload:true
						   })
						window.redirect(config.URL_REDIRECT+'/policy?type='+this.state.docType)
					})
					.catch((e) => {
						this.setState({ isDraftLoading: false });
						window.flash(e);
					});
			}
		}
	};
	publish() {
		let id = this.state?.edit_policy?._id?this.state?.edit_policy?._id:this.queries().find((u) => u.key === "?id")?.value;
		if (id) {
			let policy = this.state.edit_policy?this.state.edit_policy:window.app.state.edit_policy;
			if (!id) return window.flash("Issue publishing policy");
			policy.name = this.state.name?this.state.name:window.docs.state.name;
			policy.draft = false;
			policy.published = true;
			policy.modified = new Date();
			policy.content = window.docs.state.document;
			policy.draftContent = "";
			policy.tags = window.docs.state.tags;
			policy.category = window.docs.state.category;
			policy.document_type = window.docs.state.document_type;
			this.setState({ isLoading: true });
			window.docs
				.savePolicy(policy)
				.then((policy) => {
					this.setState({ isLoading: false });
					let {
						name,
						content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					} = policy;
					let state = {
						current: policy._id,
						name,
						document: content,
						draft,
						published,
						created,
						modified,
						category:category?category:this.state.category,
						tags,
					};
					window.docs.setState(state);
					window.app.setState({
						reload:true
					   })
					window.redirect(config.URL_REDIRECT+'/policy?type='+this.state.docType)
				})
				.catch((e) => {
					this.setState({ isLoading: false });
					window.flash(e);
				});
		} else {
			let policy = {
				name: window.docs.state.name,
				content: window.docs.state.document,
				published: true,
				draft: false,
				tags: window.docs.state.tags,
				category: window.docs.state.category,
				document_type: window.docs.state.document_type,
			};
			this.setState({ isLoading: true });
			window.docs
				.savePolicy(policy)
				.then((policy) => {
					this.setState({ isLoading: false });
					let {
						name,
						content,
						draft,
						published,
						created,
						modified,
						category,
						tags,
					} = policy;
					let state = {
						current: policy._id,
						name,
						document: content,
						draft,
						published,
						created,
						modified,
						category:category?category:this.state.category,
						tags,
					};
					window.docs.setState(state);
					window.app.setState({
						reload:true
					   })
					window.redirect(config.URL_REDIRECT+'/policy?type='+this.state.docType)
				})
				.catch((e) => {
					this.setState({ isLoading: false });
					window.flash(e);
				});
		}
	}
	toggleDoc = () => {
		let { current, draft } = this.state;
		if (current) {
			let policy = this.props.policies.find((u) => u._id === current);
			if (policy) {
				if (draft) {
					this.setState(
						{
							edit: false,
							draft: false,
							stored: this.state.document,
							document: policy.content,
							toggle: !this.state.toggle,
						},
						() => {
							if (window.editor)
								window.editor.setState({ preview: true });
						}
					);
				} else {
					this.setState({
						edit: false,
						draft: true,
						document: this.state.stored || policy.draftContent,
						toggle: !this.state.toggle,
					});
				}
			}
		}
	};
	addTag = (tag) => {
		this.setState({
			tags: [...this.state.tags, tag].reduce((a, b) => {
				if (!a.includes(b)) a.push(b);
				return a;
			}, []),
		});
	};
	removeTag = (tag) => {
		this.setState({ tags: [...this.state.tags].filter((u) => u !== tag) });
	};
	
	halfA(array, half) {
		let a = [];
		if (!array) return a;
		for (let i = half === 1 ? 0 : 1; i < array.length; i += 2) {
			a.push(array[i]);
		}
		return a;
	}
	divideArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) o[i] = [];
		let c = 0;
		return Object.entries(
			array.reduce((a, b) => {
				if (c < parts) {
					a[c].push(b);
				} else {
					c = 0;
					a[c].push(b);
				}
				c++;
				return a;
			}, o)
		).map((u) => u[1]);
	}
	splitArray(array, parts) {
		let o = {};
		if (parts < 1) return [];
		for (let i = 0; i < parts; i++) {
			o[i] = [];
			let dec = Math.floor((array.length / parts) * i);
			let inc = Math.floor((array.length / parts) * (i + 1));
			for (let z = dec; z < inc && z < array.length; z++) {
				if (array[z]) o[i].push(array[z]);
			}
		}
		return Object.entries(o).reduce((a, b) => {
			a.push(b[1]);
			return a;
		}, []);
	}
	nameSort(array) {
		return array.sort((a, b) =>
			a.name < b.name ? -1 : a.name === b.name ? 0 : 1
		);
	}
	handleSave = () => {
		clearTimeout(this.save);
		this.save = setTimeout(() => {
			if (this.saving) return;
			let policy = this.props.policies.find(
				(u) => u._id === this.state.current
			);
			if (policy) {
				let { draft, published, content, draftContent } = policy;
				this.savePolicy({
					name: this.state.name,
					content: draft ? content : this.state.document,
					draftContent:
						this.state.draft && this.state.edit
							? this.state.document
							: draftContent,
					published,
					draft,
					tags: this.state.tags,
					category: this.state.category,
					_id: this.state.current,
				})
					.then((policy) => {
						let {
							name,
							draftContent,
							draft,
							published,
							created,
							modified,
							category,
							tags,
						} = policy;
						this.setState(
							{
								current: policy._id,
								name,
								document: draftContent,
								draft,
								published,
								created,
								modified,
								category,
								tags,
							},
							() => {
								if (window.editor)
									window.editor.setState({
										editor: window.editor.init(),
									});
							}
						);
					})
					.catch((e) => window.flash(e));
			}
		}, 2150);
	};
	uploadImage(e) {
		return new Promise((res, rej) => {
			let data = new FormData();
			data.append("image", e);
			let that = new XMLHttpRequest();
			that.onerror = (e) => {
				window.flash(e);
				rej();
			};
			that.onabout = (e) => {
				window.flash(e);
				rej();
			};
			that.onreadystatechange = () => {
				if (that.readyState === 4) {
					let d = JSON.parse(that.responseText);
					if (d.error) {
						window.flash(d.message);
						return rej();
					}
					res({ data: { link: d.link } });
				}
			};
			that.open("POST", window.API + "/upload-image", true);
			that.setRequestHeader("authorization", window.app.state.auth);
			that.send(data);
		});
	}
	extractContent(text) {
		try {
			return JSON.parse(text)
				.blocks.map((u) => u.text)
				.join(" ");
		} catch (e) {
			console.log(e);
			return "";
		}
	}
	documentSearch(event) {
		const newValue = event.target.value;
		if (newValue) {
			window.app.setState({
				namefilter: newValue,
			});
		} else {
			window.app.setState({
				namefilter: null,
			});
		}
	}
	toogleCategory(event) {
		const newValue = event.target.value;
		if (newValue !== "all") {
			window.app.setState({
				filteredCats: newValue,
			});
		} else {
			window.app.setState({
				filteredCats: [],
			});
		}
	}
	render() {
		window.docs = this;
		return (
			<div className="App">
				<Route exact path={process.env.PUBLIC_URL + "/document"}>
				{this.state.gettingPolicies==true ? (<div style={{marginTop:200,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',alignSelf:'center'}}><h3>Loading Policy</h3><Spinner /></div>) :
					!this.state.edit && !this.state.toggle ? (
						<Preview
							docType={this.state.docType}
							docTypes={this.state.docTypes}
							category={this.state.category}
							categories={this.state.categories}
							tags={this.state.tags?this.state.tags:this.state.edit_policy.tags}
							file_url={this.state.file_url}
							draft={this.state.edit_policy?.draft?this.state.edit_policy?.draft:this.state.draft}
							created={this.state.created?this.state.created:this.state.edit_policy?.created}
							modified={this.state.modified?this.state.modified:this.state.edit_policy?.modified}
							file_name={this.state.file_name?this.state.file_name:this.state.edit_policy?.file_name}
							edit={() => this.edit()}
							delete={() => this.delete(this.state.current)}
							document={this.state.document?this.state.document:
							            !this.state.edit_policy?.draft
								        ? this.state.edit_policy?.content
								        :this.state.edit_policy?.draftContent}
							name={this.state.name}
						/>
						
					) : (
						<TextEditor
							categories={this.state.categories}
							taglist={this.state.taglist}
							category={this.state.category}
							tags={this.state.tags}
							document={this.state.document}
							name={this.state.name}
							draft={this.state.draft}
							published={this.state.published}
							created={this.state.created}
							modified={this.state.modified}
							file_name={this.state.file_name}
							callback={(state) => {
								this.setState({ document: state }, async () => {
									if (
										this.state.name &&
										this.state.autosave &&
										this.state.current
									) {
										if (!this.state.draft && !this.saving)
											await this.setDraft();
										this.handleSave();
									}
								});
							}}
							docType={this.state.docType}
							docTypes={this.state.docTypes}
							setName={(e) => this.setState({ name: e })}
						></TextEditor>
					)}
				</Route>
			</div>
		);
	}
}
export default Documents;

