import React, { Component } from 'react'
import CheckList from '../helper/checklist'
import Notes from '../notes'
import '../css/hd.css'

class HD extends Component {
    queries = (() => {
        let p = (window.location?.search ? window.location.search.substr(1) : '').split('=')
        let ar = []
        let pageQueries = []
        for (let i = 0; i < p.length; i++) ar.push(...p[i].split('&'))
        for (let i = 0; i < ar.length; i += 2) {
            let k = ar[i]
            let v = ar[i + 1]
            if (k && v) pageQueries.push({ key: k, value: v })
        }
        return pageQueries
    })()
    state = {offset: 0, showOffset: false, notes: [], reassociate: false}
    setCheckout(a) {
        if (a) return this.setState({ hd: { ...this.state.hd, checkedOut: true } })
        this.setState({ hd: { ...this.state.hd, checkedOut: false } })
    }
    addCheck(name) {
        console.log(name)
        return new Promise((res, rej) => {
            if (window.app.sales) return res()
            console.log('add check', name)
            fetch(window.API+'/add-check', { method: 'POST', body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: window.HD.state.hd._id, name }), headers: { 'Content-Type': 'application/json' } }).then(result => result.json()).then(result => {
                console.log(result)
                if (result.error) {
                    console.log(result.message || JSON.stringify(result))
                    if (result.kick) window.app.logOut()
                    rej(result.message || JSON.stringify(result))
                } else {
                    let { hd } = result
                    console.log(hd)
                    window.HD.setState({ hd, list: hd.checkList })
                    res()
                }
            }).catch(e => {window.flash(e); rej(e)})
        })
    }
    removeCheck(name) {
        console.log(name)
        return new Promise((res, rej) => {
            if (window.app.sales) return res()
            fetch(window.API+'/remove-check', { method: 'POST', body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: window.HD.state.hd._id, name }), headers: { 'Content-Type': 'application/json' } }).then(result => result.json()).then(result => {
                if (result.error) {
                    console.log(result.message || JSON.stringify(result))
                    if (result.kick !== false) window.app.logOut()
                    rej(result.message || JSON.stringify(result))
                } else {
                    let { hd } = result
                    console.log(hd)
                    window.HD.setState({ hd, list: hd.checkList })
                    res()
                }
            }).catch(e => rej(e))
        })
    }
    getNotes(list) {
        console.log(list)
        window.app.request('/notes', 'post', { notes: list }).then(result => {
            this.setState({ notes: result.notes })
        }).catch(e => console.log(e))
    }
    addNote = (note) => {
        if (!note) return console.log('NO NOTE')
        window.app.request('/add-hd-note', 'post', {_id: window.HD.state.hd._id, note: note}).then(result => {
            window.HD.setState({notes: [...window.HD.state.notes, result.note]})
        }).catch(e => {
            console.log(e)
            window.flash(e, 5000)
        })
    }
    removeNote = (e) => {
        let id = e
        if (!id) return
        window.app.request('/remove-hd-note', 'post', { note: id, hd: window.HD?.state?.hd?._id }).then(() =>{
            window.HD.setState({notes: [...window.HD.state.notes].filter(u => u._id !== id)})
        }).catch(e => {console.log(e); window.flash(e, 5000)})
    }
    addChecklistItem(name, value) {
        return new Promise((res, rej) => {
            if (window.app.sales) return res()
            fetch(window.API+'/add-checklist-item', { method: 'POST', body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: window.HD.state.hd._id, name, value }), headers: { 'Content-Type': 'application/json' } }).then(result => result.json()).then(result => {
                if (result.error) {
                    console.log(result.message || JSON.stringify(result))
                    if (result.kick) return window.app.logOut()
                    rej(result.message || JSON.stringify(result))
                } else {
                    let { hd } = result
                    console.log(hd)
                    window.HD.setState({ hd, list: hd.checkList })
                    res()
                }
            }).catch(e => rej(e))
        })
    }
    removeChecklistItem(name) {
        return new Promise((res, rej) => {
            if (window.app.sales) return res()
            fetch(window.API+'/remove-checklist-item', { method: 'POST', body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: window.HD.state.hd._id, name }), headers: { 'Content-Type': 'application/json' } }).then(result => result.json()).then(result => {
                if (result.error) {
                    console.log(result.message || JSON.stringify(result))
                    if (result.kick !== false) window.app.logOut()
                    rej(result.message || JSON.stringify(result))
                } else {
                    let { hd } = result
                    console.log(hd)
                    window.HD.setState({ hd, list: hd.checkList })
                    res()
                }
            }).catch(e => rej(e))
        })
    }
    getCheckouts() {
        if (this.getting || window.app.state.type !== 'admin') return
        this.getting = true
        fetch(window.API+'/checkouts', { method: 'POST', body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: this.state.hd }), headers: { 'Content-Type': 'application/json' } }).then(result => result.json()).then(result => {
            this.getting = false
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            }
            this.setState({ checkouts: result.checkouts })
        }).catch(e => {
            window.flash(e)
            window.app.logOut()
        })
    }
    componentDidMount() {
        if (window.app.state?.titles) this.setState({titles: [...window.app.state.titles].filter(u => !u.hardDrive)})
        if (this.props.hd && typeof this.props.hd === 'object') {
            this.setState({ hd: this.props.hd })
            if (this.props.hd.notes) {
                this.getNotes(this.props.hd.notes)
            }
        } else if (this.props.hd) {
            fetch(`${window.API}/hd`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ _id: this.props.hd, auth: window.app.state.auth, userID: window.app.state.userID }) }).then(result => result.json()).then(result => {
                if (result.error) {
                    window.flash(result.message || JSON.stringify(result))
                    if (result.kick) return window.app.logOut()
                    return
                }
                this.setState({ hd: result.hd })
                if (result.hd.notes) {
                    this.getNotes(result.hd.notes)
                }
            }).catch(e => {
                window.flash(e)
            })
        } else {
            let a = this.queries.find(u => u.key == '_id')
            if (a) {
                fetch(window.API+'/hd', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ _id: a.value, auth: window.app.state.auth, userID: window.app.state.userID }) }).then(result => result.json()).then(result => {
                    if (result.error) {
                        console.log(result.message || JSON.stringify(result))
                        return window.app.logOut()
                    }
                    this.setState({ hd: result.hd })
                    if (result.hd.notes) {
                        this.getNotes(result.hd.notes)
                    }
                }).catch(e => {
                    console.log(e)
                    window.app.logOut()
                })
            } else {
                return window.redirect('/')
            }
        }
    }
    render() {
        window.HD = this
        if (!this.state.hd) return (<div></div>)
        return (<div className="b1" id="hardDrive">
            <div className="wpeo">
                {window.app.state.type === 'admin' ? (<img src={this.state.hd.qr} alt="QR" className="QR"></img>) : ''}
                <div className="b1">
                    <h1>{this.state.hd.name.toUpperCase()}</h1>
                    {this.state.hd.location ? (<p>Location: {this.state.hd.location}</p>) : ''}
                    {(window.app.state.type === 'admin' || window.app.state.type === 'tech') ? (<h2 style={{ color: this.state.hd.checkedOut ? 'var(--g)' : 'var(--l)' }}>{this.state.hd.checkedOut ? 'CHECKED OUT' : 'CHECKED IN'}</h2>) : ''}
                    <span><strong>Received:</strong> {new Date(this.state.hd.dateReceived).toLocaleDateString()}{window.app.state.type === 'admin' && this.state.hd.userEmail ? (` <strong>By:</strong> ${this.state.hd.receiverEmail}`) : ''}</span>
                </div>
            </div>
            <div className="b1">
                <CheckList items={this.state.hd?.checkList} addCheck={this.addCheck} removeCheck={this.removeCheck} addItem={this.addChecklistItem} removeItem={this.removeChecklistItem}></CheckList>
                {this.state.showOffset ? (<div className="b1">
                        <h1>Print QR</h1>
                        <h3>How many many spaces to offset?</h3>
                        <input type="number" id="offset" value={this.state.offset} onChange={e => this.setState({offset: e.target.value})}></input>
                        <div className="b2 jsa">
                            <a href={window.API+`/printqr?code=${this.state?.hd?._id}&offset=${this.state.offset}`} target="_blank"><button>Generate</button></a>
                            <button onClick={e => this.setState({showOffset: false})}>CANCEL</button>
                        </div>
                        </div>) : ''}
                {(window.app.state.type === 'admin' || window.app.state.type === 'tech') ? (<div id="hdNav" className="b2">
                    <button onClick={() => {
                        if (this.state.hd.checkedOut) {
                            window.checkIn(this.state.hd._id).then(() => {
                                if (window.HD) window.HD.setCheckout(false)
                            }).catch(e => {
                                console.log(e)
                                localStorage.clear()
                                window.redirect('/')
                            })
                        } else {
                            window.checkOut(this.state.hd._id).then(() => {
                                if (window.HD) window.HD.setCheckout(true)
                            }).catch(e => {
                                console.log(e)
                                localStorage.clear()
                                window.redirect('/')
                            })
                        }
                    }}>{this.state.hd.checkedOut ? 'Check In' : 'Check Out'}</button>
                    {this.state.hd && window.app.state.type === 'admin' && !this.showOffset ? (<button onClick={e => {
                        this.setState({showOffset: true})
                    }}>Print QR Label</button>) : ''}
                    {this.state.checkouts && window.app.state.type === 'admin' ? (this.state.checkouts.length > 0 ? (<div class="rel"><button id="refresh" onClick={() => this.getCheckouts()}>Refresh Checkouts</button><div className="grid" style={{ maxHeight: '60vh', maxWidth: '100%', minWidth: '50%', overflowY: 'scroll' }} id="checkouts">{this.state.checkouts ? this.state.checkouts.map((u, i) => (<div key={i} className="checkout b2" id={u._id}>
                        <div className="b1">
                            <h3>Checked Out</h3>
                            <span>Date: {u.dateOut ? `${new Date(u.dateOut).toLocaleDateString()} @ ${new Date(u.dateOut).getHours()}:${new Date(u.dateOut).getMinutes()}` : 'null'} by {u.firstName} {u.lastName}, Email: {u.email}</span></div>
                        <div className="b1">
                            <h3>Checked In</h3>
                            <span>Date: {u.dateIn ? `${new Date(u.dateOut).toLocaleDateString()} @ ${new Date(u.dateOut).getHours()}:${new Date(u.dateOut).getMinutes()} by ${u.checkinFirstName} ${u.checkinLastName}, ${u.checkinEmail}` : 'STILL OUT!'}</span></div>
                            <div className="b1">
                                <button onClick={e => {
                                    window.sure(e.target?.parentElement?.parentElement.id)
                                }}>Remove Check Out</button>
                            </div>
                    </div>)) : ''}</div></div>) : (<div class="b1"><h3>Nobody has checked out this hard drive yet.</h3><button onClick={() => this.getCheckouts()}>Refresh Checkouts</button></div>)) : ''}
                    {window.app.state.type === 'admin' ? (<button id="viewCheckouts" className={this.state.checkouts ? 'hidden' : ''} onClick={e => {
                        e.target.innerHTML = 'Getting Checkouts'
                        this.getCheckouts()
                    }}>View Checkouts</button>) : ''}
                    {window.app.state.type === 'admin' ? (<button onClick={() => window.sure(this.state.hd?._id, this.state.hd?.name)}>Delete HD</button>) : ''}
                </div>) : ''}
            </div>
            <div className="b1">
                <h3>Notes</h3>
                <Notes add={this.addNote} remove={this.removeNote} notes={this.state.notes}></Notes>
            </div>
            {!this.state.hd.title && <button onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({associate: !this.state.associate})
            }}>{this.state.associate ? 'Close' : 'Associate Title'}</button>}
            {this.state.associate && <form className="b1" onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            let form = e.target
            let warn = form.querySelector('.warn')
            let warning = e => warn.innerHTML = e
            let title = this.state.associated
            if (!title) return warning('Please select a title')
            fetch(window.API+'/associate-drive-title', {method: 'post', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({hd: this.state.hd._id, title})}).then(result => result.json()).then(result => {
                if (result.error) return warning(result.message || JSON.stringify(result))
                this.setState({hd: result.hd, associate: false})
            }).catch(e => {
                console.log(e)
                warning(e)
            })
            }}>
                <h3>Associate Hard Drive to Title</h3>
                <span className="warn"></span>
                <div className="b1">
                    <label htmlFor="hardDrive">Select Title</label>
                    <select value={this.state.associated} onChange={e => this.setState({associated: e.target.value})}>
                        <option>Choose a Title</option>
                        {this.state.titles?.length > 0 && this.state.titles.sort((a,b) => a.title > b.title ? 1 : a.title === b.title ? 0 : -1).map((u,i) => (<option key={i} value={u._id}>{u.title}</option>))}
                    </select>
                    <button type="submit">Associate</button>
                </div>
            </form>}
            {this.state.hd?.title && !this.state.reassociate && (<div className="b1"><h3>Associated to {window.app.state.titles?.find(u => u._id == this.state.hd?.title)?.title}</h3><button onClick={() => this.setState({reassociate: true})}>Reassociate</button></div>)}
            {this.state.reassociate && this.state.hd?.title && <form className="b1" onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            let form = e.target
            let warn = form.querySelector('.warn')
            let warning = e => warn.innerHTML = e
            let title = this.state.associated
            if (!title) return warning('Please select a title')
            fetch(window.API+'/associate-title-hd', {method: 'post', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({hd: this.state.hd._id, title})}).then(result => result.json()).then(result => {
                if (result.error) return warning(result.message || JSON.stringify(result))
                this.setState({hd: result.hd, associate: false})
            }).catch(e => {
                console.log(e)
                warning(e)
            })
            }}>
                <h3>Associate Hard Drive to Title</h3>
                <span className="warn"></span>
                <div className="b1">
                    <label htmlFor="hardDrive">Select Title</label>
                    <select value={this.state.associated} onChange={e => this.setState({associated: e.target.value})}>
                        {window.app.state.titles?.length > 0 && window.app.state.titles.sort((a,b) => a.title > b.title ? 1 : a.title === b.title ? 0 : -1).map((u,i) => (<option key={i} value={u._id}>{u.title}</option>))}
                    </select>
                    <button type="submit">Reassociate</button><button onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({reassociate: false})
                    }}>Cancel</button>
                </div>
            </form>}
        </div>)
    }
}
export default HD