import React, { Component } from 'react'
import '../css/hd.css'

class HDSure extends Component {
    state = {
        clickedYes: false
    }
    componentDidMount(){
        if (this.props.id) this.setState({id: this.props.id})
        if (this.props.name) this.setState({name: this.props.name})
    }
    render(){
        if (!this.state.id) return (<div>NO ID</div>)
        return (<div className="b1">
            {this.state.name ? (<h1>Are you sure you want to delete: {this.state.name}</h1>) : (<h1>Are you sure you want to delete this item?</h1>)}
            {this.state.clickedYes ? <span>DOUBLE CLICK FOR YES</span> : ''}
            <span className="warn" id="sureWarn"></span>
            <div className="b2 jsa">
                <button onClick={() => {
                    window.hideSure()
                }}>NO</button>
                <button onClick={() => {
                    window.removeHD(this.state.id)
                }}>YES</button>
            </div>
        </div>)
    }
}
export default HDSure