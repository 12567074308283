import React, { Component } from 'react'
import Spinner from '../helper/spinner'
import Package from './package'
import Pagination from '../helper/pagination';
import SearchBar from '../helper/searchbar';
import '../css/package.css'
import { Link } from 'react-router-dom'
class Packages extends Component {
    state = {
        sortMode: 'default',
        itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12,
        searching: '',
        size: window.innerWidth,
        page: 0,
        list: [],
        gotPackages: false
    }
    resize = () => {
        this.setState({ size: window.innerWidth, itemCount: window.innerWidth < 650 ? 5 : window.innerWidth < 1080 ? 10 : 12 })
    }
    componentDidMount() {
        this.getPackages()
        window.addEventListener('resize', this.resize)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }
    getPackages() {
        if (this.props.title) {
            window.app.request(`/packages?title=${this.props.title}`, 'post').then(result => {
                if (result.error) {
                    window.flash(result.message || JSON.stringify(result))
                    return window.app.logOut()
                }
                this.setState({ packages: result.packages, gotPackages: true })
            }).catch(e => {
                window.flash(e)
                window.app.logOut()
            })
        } else {
            window.app.request('/packages', 'post').then(result => {
                if (result.error) {
                    window.flash(result.message || JSON.stringify(result))
                    return window.app.logOut()
                }
                this.setState({ packages: result.packages, gotPackages: true })
            }).catch(e => {
                window.flash(e)
                window.app.logOut()
            })
        }
    }
    sortList(list) {
        switch (this.state.sortMode) {
            case 'date': {
                let z = list.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).reverse()
                return z
            }
            case 'expires': {
                let z = list.sort((a, b) => new Date(a.expires).getTime() - new Date(b.expires).getTime()).reverse()
                return z
            }
            default: {
                return [...list].sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : a.name?.toLowerCase() === b.name?.toLowerCase() ? 0 : -1)
            }
        }
    }
    render() {
        window.packages = this
        if (!this.state.gotPackages) return (<div className="spin"><Spinner></Spinner><h3>Loading Packages</h3></div>)
        if (this.state.viewing) {
            let p = this.state.packages.find(u => u._id === this.state.viewing)
            if (p) return (<div className="b1" style={{ position: 'relative', minHeight: '50vh', minWidth: '100px' }}><button style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 10 }} onClick={() => this.setState({ viewing: '' })}><i className="fas fa-times"></i> Close Package</button><Package package={p}></Package></div>)
        }
        return (<div className="b1 full pushed2 rel">
            {!this.props.title && <Link to="/create-package"><button className="fixedButton"><i className="fas fa-plus"></i> &nbsp;Create Package</button></Link>}
            <div className="options">
                <SearchBar placeholder="Search Packages" items={[...this.state.packages.map(u => ({ ...u, titles: window.app.state.titles.filter(a => u.titles.includes(a._id)).map(u => u.title) }))]} indexes={['titles', 'name']} mainKey={'_id'} template={(u, i) => (<div key={i} className="b2 jsb searchResult">
                    <h3>{u.name.toUpperCase()} - {new Date(u.date).toLocaleDateString()} - {u.titles.length < 4 ? JSON.stringify(u.titles) : `${u.titles.length} Titles`}</h3>
                    <button id={u._id} className="smallbut" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.setState({ viewing: e.target.id })
                    }}>VIEW</button></div>)} />
                <div id="sortingMode"><div className="sortGrid"><span id="sorter">Sort By:</span><button className={this.state.sortMode === 'default' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'default' })}>Name</button><button className={this.state.sortMode === 'date' ? 'activebutton' : ''} onClick={e => this.setState({ sortMode: 'date' })}>Date</button></div></div>
            </div>
            <h1>Packages</h1>
            <span>{this.state.packages?.length || 0} Packages</span>

            {this.state.packages?.length > 0 ? (<div className="c4 box">{window.index([...this.state.packages], this.state.page, this.state.itemCount).filter(u => u).map((u, i) => (<div key={i} className="listedPackage" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.setState({ viewing: e.target.id })
            }} id={u._id}>
                <h3 onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ viewing: e.target.parentElement.id })
                }}>{u.name}</h3>
                <p onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ viewing: e.target.parentElement.id })
                }}>{u.recipients?.length || 0} Recipients - CREATED: {new Date(u.date).toLocaleDateString()}</p>
                <span onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.setState({ viewing: e.target.parentElement.id })
                }} className="warn">PACKAGE EXPIRES: {new Date(u.expires).toLocaleDateString()}</span>
            </div>))}</div>) : (<div className="b1"><h3>No Packages Listed</h3></div>)}
            <Pagination set={e => this.setState({ page: e })} count={this.state.itemCount} total={this.state.packages?.length || 0} page={this.state.page} />
        </div>)
    }
}
export default Packages