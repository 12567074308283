import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import HDSure from './components/hd/hdsure'
import HD from './components/hd/HD'
import { BrowserRouter as Router } from 'react-router-dom';
window.DEV = true;
window.API = window.DEV ? 'https://portal.deliveryminds.com' : 'https://portal.deliveryminds.com'
window.API_FRONTEND = window.DEV ? 'http://portal.deliveryminds.com':'https://portal.deliveryminds.com'
window.WSURI = window.DEV ? 'wss://ws.portal.deliveryminds.com' : 'ws://localhost:2800'
window.reconnect = 0
window.flash = (e, time, clr) => {
  var node = document.createElement("div");
  node.className = "snackbar show";
  node.style.backgroundColor = clr || '#333'
  node.innerHTML = e;
  document.querySelector('body').append(node);
  setTimeout(function () { node.remove() }, time || 5000);
}
window.index = (ar, page, s) => {
  let a = []
  if (ar.length <= s) return ar
  if (ar.length > s) {
    if (ar.length - s * page >= 0) {
      for (let i = s * page; i < s * (page + 1); i++) if (ar[i]) a.push(ar[i])
    } else {
      for (let z = ar.length - (ar.length % s) - 1; z < ar.length; z++) if (ar[z]) a.push(ar[z])
    }
  }
  return a
}
window.formatDate = (date) => {
  let d = date && typeof date.getTime === 'function' ? date : new Date(date)
  let month = d.getMonth() + 1
  let day = d.getDate()
  return `${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}-${d.getFullYear()}`
}
window.parentClick = (e) => {
  e.preventDefault()
  e.stopPropagation()
  e.target.parentElement?.click()
}

window.capitalize = (string) => {
	if (typeof string !== "string") return string;
	let a = string.split("");
	if (a && a[0]) a[0] = a[0].toUpperCase();
	return a.join("");
};
document.querySelector('#closepopup').addEventListener('click', () => {
  try {
    ReactDOM.unmountComponentAtNode(document.getElementById('pop'))
    document.querySelector('#popup').classList.add('hidden');
  } catch (e) {
    console.log('error', e)
  }
})
window.lastPing = null
window.lastPingTime = null
window.currentPing = 0
window.sendPing = () => {
  window.lastPing = Math.floor(Math.random() * 10000)
  window.lastPingTime = new Date().getTime()
  window.ws.sendData({ type: 'ping', data: window.lastPing })
}
window.fileSize = size => {
  if (!size) return `${0} Bites`
  return size < 1000 ? `${size} Bites` : size < (1000 * 1000) ? `${(size / 1000).toFixed(2)}Kb` : size < (1000 * 1000 * 1000) ? `${(size / (1000 * 1000)).toFixed(2)}Mb` : size < 1000 * 1000 * 1000 * 1000 ? `${(size / (1000 * 1000 * 1000)).toFixed(2)}Gb` : `${(size / (1000 * 1000 * 1000 * 1000 * 1000)).toFixed(2)}Tb`
}
window.notificationFetch = () => {
  clearTimeout(window.notifTimer)
  window.notifTimer = setTimeout(() => {
    if (window.ws && window.ws.readyState === 1) window.ws.sendData({ type: 'fetch-notifications' })
  }, 60000)
}
window.fileIcon = type => {
  let images = ['image/png', 'image/jpeg', 'image/svg+xml']
  let files = []
  let sounds = ['audio/mpeg']
  let movies = ['video/mp4']
  let imageIcon = ''
  let fileIcon = 'https://docs.microsoft.com/en-us/windows/apps/design/signature-experiences/images/iconography_anatomy1.svg'
  let soundIcon = 'https://static.thenounproject.com/png/133988-200.png'
  let movieIcon = ''
  let standardIcon = 'https://cdn3.iconfinder.com/data/icons/files-buttons/512/File_Settings-512.png'
  return images.includes(type) && imageIcon ? imageIcon : files.includes(type) && fileIcon ? fileIcon : sounds.includes(type) ? soundIcon && soundIcon : movies.includes(type) && movieIcon ? movieIcon : standardIcon
}
window.notifs = [{
  subject: "Basic Notification",
  content: "This is a basic notification.  It is grey and bland, but don't let it fool you.  The text content can be as wild as you like.",
  type: "alert"
},
{
  subject: "Title Announcement",
  content: "Your title <b>American Hunt</b> has been successfully distributed to Tubi.  Look out for an email with the pre-save streaming links.",
  type: "announcement"
}, {
  subject: "File Upload Error",
  content: "There was an error uploading your file <b>I-Love-Cats.jpg</b>.  Do not worry, we are on it and will be in touch.",
  type: "error"
}, {
  subject: "Major Problem",
  content: "There is a major error going down.  The NAS has crashed.  We must fix it ASAP, or else Galen will be angry.",
  type: "warning"
}, {
  subject: "Your File is Done Uploading",
  content: "Your file is done uploading.  It was full of bits & bites, buy our computers have successfully processed your assets.  Time to celebrate.",
  type: "upload"
}, {
  subject: "New Message From Josh",
  content: "Howdy there.  I hope you got my upload.  BackBlaze is legit, heck yeah!",
  type: "message"
}].map((u, i )=> {
  u.viewed = false
  u._id = `ID${i}`
  return u
})
window.checkws = () => {
  if (window.ws && window.ws.readyState !== WebSocket.OPEN) {
    window.newSocket().catch(e => { window.flash(`WS ERROR: ${JSON.stringify(e)}`) })
  } else {
    window.sendPing()
  }
}
window.checkauth = () => {
  if (window.ws && window.ws.readyState === WebSocket.OPEN && (window.app.state.auth && window.app.state.auth !== 'undefined') && (window.app.state.userID && window.app.state.userID !== 'undefined')) window.ws.sendData({ type: 'auth', data: { method: 'check', auth: window.app.state.auth, _id: window.app.state.userID } })
}
window.runSocket = (e) => {
  let that
  try {
    that = JSON.parse(e)
  } catch (err) {
    try {
      that = JSON.parse(e.data)
    } catch (e) {
      return console.log(`RECEIVED DATA: ${JSON.stringify(e)}`)
    }
  }
  if (that && that.type) {
    let { type, data, error } = that
    switch (type) {
      case 'auth': {
        if (error) {
          window.flash(that.message || 'Issue with authenticating you, please sign in again')
          window.app.logOut()
        } else {
          console.log('authenticated')
          window.app.setState({ authenticated: true })
          window.ws.sendData({ type: 'fetch-notifications' })
          window.notificationFetch()
        }
        break
      }
      case 'ping': {
        if (window.lastPing !== data) {
          window.sendPing()
        } else {
          window.currentPing = new Date().getTime() - window.lastPingTime
          console.log(`Current Ping: ${window.currentPing}ms`)
        }
        break
      }
      case 'new notification': {
        window.app.setState({ notifications: [...window.app.state.notifications, data] })
        break
      }
      case 'fetch-notifications': {
        console.log(data, 'notifs')
        if (error) {
          window.flash(that.message || 'Issue with authenticating you, please sign in again')
          return window.app.logOut()
        }
        if (window.app) window.app.setState({
          notifications: data
        })
        break
      }
      case 'my-uploads': {
        if (error) {
          window.flash(that.message || 'Issue with authenticating you, please sign in again')
          return window.app.logOut()
        }
        if (window.myUploads) window.myUploads.setState({ uploads: data })
        break
      }
      case 'titles': {
        if (window.app) window.app.setState({ titles: data })
        if (window.filemanager) window.filemanager.setState({ titles: data })
        break
      }
      case 'file': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (window.filemanager.state.currentFolder?._id === data.directory) {
            if (window.filemanager.state.files.find(u => u._id === data._id)) {
              window.filemanager.setState({
                files: [...window.filemanager.state.files].map(u => {
                  if (u._id === data._id) return data
                  return u
                })
              })
            } else {
              window.filemanager.setState({ files: [...window.filemanager.state.files, data] })
            }
          }
          if (!data.directory) {
            if (!window.filemanager.state.titleFiles.find(u => u._id === data._id)) {
              window.filemanager.setState({ titleFiles: [...window.filemanager.state.titleFiles, data] })
            } else {
              window.filemanager.setState({
                titleFiles: [...window.filemanager.state.titleFiles].map(u => {
                  if (u._id === data._id) return data
                  return u
                })
              })
            }
          }
          if (window.filemanager.state.currentFile?._id === data._id) {
            window.filemanager.setState({ currentFile: data })
            window.file?.init(data)
          }
        }
        break
      }
      case 'file move': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (data.directory && window.filemanager.state.currentFolder && window.filemanager.state.currentFolder._id === data.directory) {
            window.filemanager.setState({ currentFolder: ({ ...window.filemanager.state.currentFolder, files: [...window.filemanager.state.currentFolder.files, data._id] }), files: [...window.filemanager.state.files, data]})
          } else {
            window.filemanager.setState({files: [...window.filemanager.state.files].filter(u => u._id !== data._id)})
          }
          if (data.directory) {
            window.filemanager.setState({titleFiles: [...window.filemanager.state.titleFiles].filter(u => u._id !== data._id)})
          } else {
            window.filemanager.setState({titleFiles: [...window.filemanager.state.titleFiles, data]})
          }
          window.filemanager.setState({
            directories: [...window.filemanager.state.directories].map(u => {
              if (u._id === data.directory && u.files) u.files = [...u.files, data]
              if (u._id !== data.directory && u.files) u.files = [...u.files].filter(a => a._id !== data._id)
              return u
            }),
            folders: [...window.filemanager.state.folders].map(u => {
              if (u._id === data.directory && u.files) u.files = [...u.files, data]
              if (u._id !== data.directory && u.files) u.files = [...u.files].filter(a => a._id !== data._id)
              return u
            }),
            titleFolders: [...window.filemanager.state.titleFolders].map(u => {
              if (u._id === data.directory && u.files) u.files = [...u.files, data]
              if (u._id !== data.directory && u.files) u.files = [...u.files].filter(a => a._id !== data._id)
              return u
            })
          })

        }
        break
      }
      case 'directory move': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (window.filemanager.state.currentFolder?._id === data._id) {
            window.filemanager.setState({ currentFolder: data })
          }
          window.filemanager.setState({
            directories: [...window.filemanager.state.directories].map(u => {
              if (u._id === data._id) return data
              return u
            }), titleFolders: [...window.filemanager.state.titleFolders].map(u => {
              if (u._id === data._id) return data
              return u
            }), folders: [...window.filemanager.state.folders].map(u => {
              if (u._id === data._id) return data
              return u
            })
          })
        }
        break
      }
      case 'file delete': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (window.filemanager.state.currentFile?._id === data._id) {
            window.filemanager.setState({ currentFile: null })
            window.flash('File was deleted!')
          }
          window.filemanager.setState({ files: [...window.filemanager.state.files].filter(u => u._id !== data._id), titleFiles: [...window.filemanager.state.titleFiles].filter(u => u._id !== data._id) })
        }
        break
      }
      case 'directory delete': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (window.filemanager.state.currentFolder?._id === data._id) {
            window.filemanager.setState({ currentFolder: null })
            window.flash('Directory was removed!')
          }
          window.filemanager.setState({ directories: [...window.filemanager.state.directories].filter(u => u._id !== data._id), titleFolders: [...window.filemanager.state.titleFolders].filter(u => u._id !== data._id), folders: [...window.filemanager.state.folders].filter(u => u._id !== data._id) })
        }
        break
      }
      case 'directory': {
        if (window.filemanager && window.filemanager.state.title === data.title) {
          if (window.filemanager.state.directories?.find(u => u._id === data._id)) {
            window.filemanager.setState({
              directories: [...window.filemanager.state.directories].map(u => {
                if (u._id === data._id) return data
                return u
              })
            })
          } else {
            window.filemanager.setState({ directories: [...window.filemanager.state.directories, data] })
          }
          if (window.filemanager.state.folders.find(u => u._id === data._id)) {
            window.filemanager.setState({
              folders: [...window.filemanager.state.folders].map(u => {
                if (u._id === data._id) return data
                return u
              })
            })
          } else if (window.filemanager.state.currentFolder?._id === data.directory) {
            let s = {currentFolder: {...window.filemanager.state.currentFolder, subfolders: [...window.filemanager.state.subfolders, {_id: data._id, name: data.name}]} }
            if (!window.filemanager.state.folders.find(u => u._id === data._id)) s.folders = [...window.filemanager.state.folders, data]
            window.filemanager.setState(s)
          }
          if (window.filemanager.state.currentFolder?._id === data._id) window.filemanager.setState({ currentFolder: data })
          if (window.filemanager.state.titleFolders.find(u => u._id === data._id)) {
            window.filemanager.setState({
              titleFolders: [...window.filemanager.state.titleFolders].map(u => {
                if (u._id === data._id) return data
                return u
              })
            })
          } else {
            window.filemanager.setState({ titleFolders: [...window.filemanager.state.titleFolders, data] })
          }
        }
        break
      }
      case 'package': {
        if (window.packages) {
          if (!window.packages.state.packages.find(u => u._id === data._id)) {
            window.packages.setState({ packages: [...window.packages.state.packages, data] })
          } else {
            window.packages.setState({
              packages: [...window.packages.state.packages].map(u => {
                if (u._id === data._id) return data
                return u
              })
            })
          }
        }
        break
      }
      case 'package update': {
        if (window.packages) {
          window.packages.setState({
            packages: [...window.packages.state.packages].map(u => {
              if (u._id === data._id) return data
              return u
            })
          })
        }
        break
      }
      case 'package delete': {
        if (window.packages) {
          let s = { packages: [...window.packages.state.packages].filter(u => u._id !== data) }
          if (window.packages.state.viewing === data) {
            window.flash('Package was removed!')
            s.viewing = ''
          }
          window.packages.setState(s)
        } else if (window.app.state.location === '/package') {
          if (window.app.queries?._id === data) {
            window.flash('Package was removed!')
            window.redirect('/packages')
          }
        }
        break
      }
      case 'message': {
        window.app.setState({ messages: [...window.app.state.messages, data] })
        break
      }
      default: {
        return console.log(`INVALID TYPE: ${e}`)
      }
    }
  }
}
window.socketWait = null
window.waitForSocketConnection = (socket, callback, data, timer = 1000) => {
  clearTimeout((window.socketWait))
  if (!callback || typeof callback !== 'function') return
  if (socket.readyState === 0) {
    window.socketWait = setTimeout(() => { window.waitForSocketConnection(socket, callback, data, timer) }, timer)
  } else if (socket.readyState === 1) {
    callback(data)
  } else {
    window.socketWait = window.waitForSocketConnection(socket, callback, data, timer)
  }
}
window.waitForAuth = (socket, callback, data, timer = 1000, rounds = 0) => {
  clearTimeout((window.socketWaitForAuth))
  if (!callback || typeof callback !== 'function' || rounds > 10) return
  if (!window.app.state.authenticated) {
    window.socketWaitForAuth = setTimeout(() => { window.waitForAuth(socket, callback, data, timer) }, timer, rounds + 1)
  } else {
    callback(data)
  }
}
window.newSocket = function () {
  return new Promise((res, rej) => {
    clearInterval(window.heartbeat)
    clearTimeout((window.socketWait))
    try {
      window.ws = new WebSocket(window.WSURI)
      window.ws.onopen = function (e) { window.heartbeat = setInterval(() => { window.checkws() }, 10000) };
      window.waitForSocketConnection(window.ws, () => { if (window.app?.state?.auth && window.app?.state?.userID && typeof window.app.state.auth !== 'undefined' && typeof window.app.state.userID !== 'undefined') window.ws.sendData({ type: 'auth', data: { auth: window.app.state.auth, userID: window.app.state.userID } }) })
      window.ws.onmessage = function incoming(data) { window.runSocket(data) }
      window.ws.sendData = (data) => {
        if (!window.app.state.authenticated && data.type !== 'auth') {
          window.waitForAuth(window.ws, data => { window.ws.send(JSON.stringify(data)) }, data)
        } else if (window.ws.readyState !== 1) {
          window.waitForSocketConnection(window.ws, data => { window.ws.send(JSON.stringify(data)) }, data)
        } else {
          window.ws.send(JSON.stringify(data))
        }
      }
      window.ws.onclose = () => window.newSocket()
      return res(true)
    } catch {
      if (window.reconnect <= 10) {
        setTimeout(() => {
          window.reconnect++
          window.newSocket()
        }, window.reconnect * 3000)
      } else {
        return rej('Error reloading websocket. must be an issue on the backend.')
      }
    }
  })
}
window.isInternal = () => window.app?.state?.type && (window.app.state.type === 'tech' || window.app.state.type === 'admin' || window.app.state.type === 'sales')
window.isAdmin = () => window.app?.state?.type === 'admin'
window.showHD = hd => {
  ReactDOM.render(<HD hd={hd}></HD>, document.getElementById('pop3'))
  document.getElementById('pop3').parentElement.classList.remove('hidden');
}
window.redirect = path => window.app.props.history.push(path)
window.checkOut = (id) => {
  return new Promise((res, rej) => {
    fetch(window.API + '/checkout', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: id }) }).then(result => result.json()).then(result => {
      if (result.error) return rej(result.message || JSON.stringify(result))
      res(true)
    }).catch(e => {
      rej(e)
    })
  })
}
window.checkIn = (id) => {
  return new Promise((res, rej) => {
    fetch(window.API + '/checkin', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ auth: window.app.state.auth, userID: window.app.state.userID, HD: id }) }).then(result => result.json()).then(result => {
      if (result.error) return rej(result.message || JSON.stringify(result))
      res(true)
    }).catch(e => {
      rej(e)
    })
  })
}
window.hideHD = () => {
  try {
    ReactDOM.unmountComponentAtNode(document.getElementById('pop3'))
    if (window.HDlist) window.HDlist.getHDs()
    document.querySelector('#popup3').classList.add('hidden');
  } catch (e) {
    console.log('error', e)
  }
}
window.sure = (id, name) => {
  if (!id) throw new Error('missing id')
  if (!name) {
    ReactDOM.render((<HDSure id={id} name={name} type={'checkout'}></HDSure>), document.getElementById('pop2'))
  } else {
    ReactDOM.render((<HDSure id={id} name={name} type={'hd'}></HDSure>), document.getElementById('pop2'))
  }
  document.getElementById('pop2').parentElement.classList.remove('hidden');
}
window.hideSure = () => {
  try {
    ReactDOM.unmountComponentAtNode(document.getElementById('pop2'))
    document.querySelector('#popup2').classList.add('hidden');
  } catch (e) {
    console.log('error', e)
  }
}
window.removeHD = (id) => {
  let warn = document.getElementById('sureWarn')
  let warning = e => { if (warn) warn.innerHTML = e }
  if (!id) return warning('missing an ID')
  window.app.request('/delete-hd', 'POST', { id }).then(result => {
    window.redirect('/hds')
    window.hideSure()
  }).catch(e => { window.flash(e) })
}
document.querySelector('#closepopup3').addEventListener('click', () => window.hideHD())
document.querySelector('#closepopup2').addEventListener('click', () => window.hideSure())
ReactDOM.render(
  <React.StrictMode>
    <Router><App /></Router>
  </React.StrictMode>,
  document.getElementById('root')
);
setTimeout(() => document.querySelector('splash-thing')?.remove(), 750)
