import React, { Component } from 'react'
class CheckList extends Component {
    render() {
        return (<div className="b1"><ul className="checklist">
            {this.props.items && typeof this.props.items === 'object' ? Object.keys(this.props.items).map((u, i) => {
                if (typeof this.props.items[u] !== 'undefined') {
                    let a = this.props.items[u]
                    return (<li id={u} key={i} className={((window.app.state.type === 'admin') ? "adminItem" : ('item b2 jsa'))}>
                        <p><strong>{u.toUpperCase()}</strong></p>
                        {a ? (<div className={(window.app.state.type === 'admin' || window.app.state.type === 'tech') ? 'icon selectedIcon' : 'icon selectedIcon oBlock'} onClick={e => {if ((window.app.state.type === 'admin' || window.app.stte.type === 'tech')) this.props.removeCheck(e.target?.parentElement?.id)}}></div>) : (<div className={(window.app.admin || window.app.tech) ? 'icon' : 'icon oBlock'} onClick={e => {if ((window.app.state.type === 'admin' || window.app.state.type === 'tech')) this.props.addCheck(e.target?.parentElement?.id)}}></div>)}
                        {window.app.state.type === 'admin' ? (<div className="removeIcon" onClick={e => this.props.removeItem(e.target?.parentElement?.id).catch(e => console.log(e))}>X</div>) : ''}
                    </li>)
                }
                return (<div key={i}></div>)
            }) : ''}</ul>
            {(window.app.state.type === 'admin' || window.app.state.type === 'tech') ? (<button id="addItem" onClick={e => {
                e.target.parentElement.querySelector('form').classList.remove('hidden')
                e.target.classList.add('hidden')
            }}>Add Item(+)</button>): ''}
            {(window.app.state.type === 'admin' || window.app.state.type === 'tech') ? (<form className="hidden" onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
                let form = e.target
                let warn = form.querySelector('.warn')
                let warning = e => warn.innerHTML = e
                let name = form['name'].value
                let available = form['available'].checked
                let n = typeof this.props.items === 'object' ? Object.keys(this.props.items).find(u => u.toUpperCase() === name.toUpperCase()) : null
                if (n) return warning('That item is already on the checklist.')
                this.props.addItem(name, available).then(() => {
                    form.reset()
                    form.parentElement.querySelector('#addItem').classList.remove('hidden')
                    form.classList.add('hidden')
                }).catch(e => {
                    warning(e)
                })
            }}>
                <span className="warn"></span>
                <div className="b1">
                    <label htmlFor="name">Item Name</label>
                    <input type="text" name="name"></input>
                </div>
                <div className="b1">
                    <label htmlFor="available">Is the item available?</label>
                    <input type="checkbox" name="available"></input>
                </div>
                <button type="submit">Add Item(+)</button>
            </form>) : ''}
        </div>)
    }
}
export default CheckList