import React, { Component } from 'react';
import { Route, withRouter, Redirect } from "react-router-dom";
import Home from './home'
import Login from "./components/account/login.js";
import ResetPass from './components/account/resetpass'
import NotificationCenter from './components/notification/notificationCenter'
import Package from './components/package/package'
import NewUser from './components/account/newuser'
import SideBar from './components/sidenav';
import DarkMode from './darkmode';
import Toggle from './components/helper/toggle';
import config from './config';
const URL_REDIRECT=config.URL_REDIRECT;
class App extends Component {
  state = {
    auth: localStorage.getItem('auth'),
    type: localStorage.getItem('type'),
    userID: localStorage.getItem('userID'),
    colorMode: localStorage.getItem('colorMode'),
    authenticated: false,
    location: this.props.location.pathname,
    showNav: false,
    notifications: [],
    titles: [],
    gotPolicies:false,
    messages: [],
    icons: {
      'image': 'image',
      '.png': 'image',
      '.jpg': 'image',
      '.webp': 'image',
      'keyart': 'image',
      'video': 'video',
      'files': 'file',
      'folders': 'folder',
      'audio': 'volume',
      '.mp3': 'file-music',
      '.pdf': 'file-pdf'
    },
    fileIcons: {
      'webp': {
        icon: 'file-image',
        color: '#ff0000'
      },
      'jpg': {
        icon: 'file-image',
        color: '#ff0000'
      },
      'pdf': {
        icon: 'file-pdf',
        color: '#ff0000'
      },
      'mp3': {
        icon: 'file-music',
        color: '#ff0000'
      },
      'xls': {
        icon: 'file-excel',
        color: '#188038'
      },
      'xlsx': {
        icon: 'file-excel',
        color: '#188038'
      },
      'doc': {
        icon: 'file',
        color: '#4285F4'
      },
      'ppt': {
        icon: 'file-powerpoint',
        color: '#F4B400'
      }
    }
  }

  queries = (() => {
    let p = (window.location?.search ? window.location.search.substr(1) : '').split('=')
    let ar = []
    let pageQueries = []
    for (let i = 0; i < p.length; i++) ar.push(...p[i].split('&'))
    for (let i = 0; i < ar.length; i += 2) {
      let k = ar[i]
      let v = ar[i + 1]
      if (k && v) pageQueries.push({ key: k, value: v })
    }
    let o = {}
    for (let i = 0; i < pageQueries.length; i++) o[pageQueries[i].key] = pageQueries[i].value
    return o
  })();
  safe = [URL_REDIRECT+'/login', URL_REDIRECT+'/reset-password', URL_REDIRECT+'/download-package']
  componentDidMount() {
    if (!this.state.colorMode || this.state.colorMode === 'undefined') {
      localStorage.setItem('colorMode', 'light')
      this.setState({ colorMode: 'light' })
    }
    if (/index.html/.test(this.props.location.pathname)) return window.redirect(URL_REDIRECT+'/')
    if (this.state.auth && this.state.userID && (this.safe.includes(this.props.location.pathname) && this.props.location.pathname !== URL_REDIRECT+'/download-package')) {
      return window.redirect(URL_REDIRECT+'/')
    } else if ((!this.state.auth || !this.state.userID) && !this.safe.includes(this.props.location.pathname)) {
     // console.log('this.props.location.pathname',this.props.location.pathname);
      this.setState({ showNav: false })
      return window.redirect(URL_REDIRECT+'/login')
    }
    if (this.state.auth && !this.state.authenticated) window.newSocket().catch(e => { console.log(e) })
  }
  componentDidUpdate() {
    if (/index.html/.test(this.props.location.pathname)) return window.redirect(URL_REDIRECT+'/')
    if (this.state.location !== this.props.location.pathname) this.setState({ location: this.props.location.pathname })
    if (this.state.auth && this.state.userID && (this.safe.includes() && this.props.location.pathname !== URL_REDIRECT+'/download-package')) {
      if (!this.state.showNav) this.setState({ showNav: true })
      return window.redirect(URL_REDIRECT+'/')
    } else if ((!this.state.auth || !this.state.userID) && !this.safe.includes(this.props.location.pathname)) {
      //console.log('this.props.location.pathname',this.props.location.pathname);
      if (this.state.showNav) this.setState({ showNav: false })
      return window.redirect(URL_REDIRECT+'/login')
    }
    if (this.state.auth && !this.state.authenticated && !window.ws) window.newSocket().catch(e => { console.log(e) })
    if (this.state.auth && !this.state.authenticated && window.ws) window.ws.sendData({ type: 'auth', data: { userID: this.state.userID, auth: this.state.auth } })
  }


  toggleDarkmode() {
    let a
    switch(this.state.colorMode) {
      case 'light': {
        a = 'dark'
        break
      }
      default: {
        a = 'light'
        break
      }
    }
    this.setState({ colorMode: a})
    localStorage.setItem('colorMode', a)
  }
  request = (page, method, data) => {
    return new Promise((res, rej) => {
      let options = {
        method: method ? method : 'GET',
        headers: { userID: window.app.state.userID, Authorization: window.app.state.auth, 'Content-Type': 'application/json' }
      }
      if (data && method && method !== 'GET') options.body = data && typeof data === 'object' ? JSON.stringify(data) : data
      fetch(window.API + page, options).then(result => result.json()).then(result => {
        if (result.error) return rej(result.message || result)
        return res(result)
      }).catch(e => rej(e))
    })
  }
  getTitles() {
    this.request('/titles', 'post').then(result => {
      this.setState({ titles: result.titles })
    }).catch(e => window.flash(e))
  }
  handleDownload(_id) {
    return new Promise((res, rej) => {
      if (!window.DESKTOP) return
      if (!_id) return window.flash('NO FILE _id SELECTED')
      window.DESKTOP.selectDirectory().then(dir => {
        window.DESKTOP.copyFile(_id, dir[0]).then(result => {
          window.flash('File copied to destination!')
          res(true)
        }).catch(e => rej(e))
      }).catch(e => rej(e))
    })
  }
  handleUpload(title, files, directory) {
    return new Promise(async (res, rej) => {
      if (!window.DESKTOP) return
      if (!title) return rej('NO TITLE _id SELECTED')
      if (files && typeof files === 'object' && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = files[i]
          await window.DESKTOP.addFile(file.path, title, directory).catch(e => rej(e))
        }
        window.flash(`${files.length > 1 ? 'Files' : 'File'} added to NAS Storage!`, 5000)
        res(true)
      } else if (files && typeof files === 'object') {
        window.DESKTOP.addFile(files.path, title, directory).then(result => {
          if (result.error) return rej(result.message || JSON.stringify(result))
          window.flash('File Added to NAS!')
          return res(result)
        }).catch(e => {
          return rej(e)
        })
      } else {
        window.DESKTOP.selectFile().then(path => {
          window.DESKTOP.addFile(path, title, directory).then(result => {
            window.flash('File Added to NAS!')
            res(result)
          }).catch(e => rej(e))
        }).catch(e => rej(e))
      }
    })
  }
  logOut() {
    localStorage.removeItem('userID')
    localStorage.removeItem('auth')
    localStorage.removeItem('type')
    this.setState({
      auth: null,
      type: null,
      userID: null,
      authenticated: false
    })
    if (window.DESKTOP) {
      window.DESKTOP.LogOut(window.API_FRONTEND+URL_REDIRECT+'/logout-cookie').then(() => {
        //console.log('ooooooooooooooo');
        window.redirect(URL_REDIRECT+'/')
      }).catch(e => window.flash(e))
    } else {
      window.location = window.API_FRONTEND+URL_REDIRECT+"/logout-cookie"
    }
  }
  showNotifications(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    window.app.setState({ showNotifications: true })
  }
  closeNotifications(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    window.app.setState({ showNotifications: false })
  }

  render() {
    window.app = this
    if (/index.html/.test(this.props.location.pathname)) return <Redirect to={`${URL_REDIRECT}/`}></Redirect>
    let barstyle = (!this.state.auth && (this.state.location === URL_REDIRECT+'/' || this.state.location === URL_REDIRECT+'/login' || this.state.location === URL_REDIRECT+'/reset-password' || this.state.location === URL_REDIRECT+'/download-package')) ? { gridTemplateColumns: '1fr' } : this.state.showNav ? { gridTemplateColumns: '1fr 10fr' } : { gridTemplateColumns: '1fr 17fr' }
    return (<div id="side" style={barstyle}>{this.state.auth && this.state.location !== URL_REDIRECT+'/login' && <SideBar location={this.state.location} showNav={this.state.showNav}></SideBar>}<div id="app">
      {this.state.showNotifications && <NotificationCenter close={this.closeNotifications} notifications={this.state.notifications}></NotificationCenter>}
      <Route path={`${URL_REDIRECT}/login`} component={Login}></Route>
      <Route exact path={`${URL_REDIRECT}/reset-password`} component={ResetPass}></Route>
      {!this.state.auth || !this.state.userID ? <Route exact path={`${URL_REDIRECT}/download-package`} component={Package}></Route> : ''}
      {!this.state.auth || !this.state.userID ? <Route exact path={`${URL_REDIRECT}/new-user`} component={NewUser}></Route> : ''}
      {this.state.auth && this.state.userID ? <Home titles={this.state.titles} openNotifications={this.showNotifications} notifications={this.state.notifications} /> : <Route exact path={`${URL_REDIRECT}/`} component={Login}></Route>}
      {this.state.auth && <>{this.state.colorMode === 'dark' && <DarkMode />}
        <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1 }}><span style={{ color: this.state.colorMode === 'dark' ? 'var(--d)' : 'var(--l)' }}>{this.state.colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}</span><Toggle change={e => this.toggleDarkmode()} init={this.state.colorMode === 'dark'}></Toggle></div></>}
      {this.state.auth && false && this.state.userID && <div id="footer">
        <p>Delivery Minds</p>
      </div>}
    </div></div>)
  }
}
export default withRouter(App)