import React, { Component } from 'react'
import '../css/hd.css'

class NewHD extends Component{
    state={
        selectedTitle: '',
        title: '',
        choose: false
    }
    render() {return (<div className="b1 n">
    <form className="b1" onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        let form = e.target
        let warn = form.querySelector('.warn')
        let warning = e => warn.innerHTML = e
        let {selectedTitle, choose} = this.state
        let data = {
            name: selectedTitle,
            choose: choose
        }
        fetch(window.API+'/hard-drive', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...data, location: form['location'].value || 'Unassigned', auth: window.app.state.auth, userID: window.app.state.userID }) }).then(result => result.json()).then(result => {
            if (result.error) {
                if (result.message === 'invalid auth') return window.app.logOut()
                return warning(result.message || JSON.stringify(result))
            }
            form.reset()
            warning('SUCCESS!')
            window.redirect(`/hard-drive?_id=${result.HD._id}`)
        }).catch(e => warning(e))
    }}>
        <h1>Add a Hard Drive</h1>
        <span className="warn"></span>
        <div className={"b1"}>
            <label htmlFor="name">Select a title</label>
            <select type="text" name="name" value={this.state.title} onChange={e => {
                let val = e.target.value
                if (val === 'NOTAREALTITLE') {
                    this.setState({title: e.target.value, selectedTitle: '', choose: true})
                } else {
                    this.setState({title: e.target.value, selectedTitle: e.target.value, choose: false})
                }
            }}><option>Select a title</option><option value="NOTAREALTITLE">Title is not listed</option>{[...window.app.state.titles]?.sort((a,b) => a.title > b.title ? 1 : a.title === b.title ? 0 : -1)?.filter(u => !u.hardDrive)?.map((u, i) => (<option key={i} value={u._id}>{u.title}</option>))}</select>
        </div>
        <div className={"b1" + (this.state.choose ? '' : ' hidden')}>
            <label htmlFor="name">Film Title</label>
            <input type="text" name="name" value={this.state.selectedTitle} onChange={e => this.setState({selectedTitle: e.target.value})}></input>
        </div>
        <div className="b1 hidden">
            <label htmlFor="location">Location</label>
            <input type="text" name="location"></input>
        </div>
        <button type="submit">Add HD</button>
    </form></div>)}
}
export default NewHD