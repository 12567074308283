import '../helper/logo'
import '../css/account.css'
import { useState } from 'react'
class hidenavbar extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `<style>#nav {
    display: none!important;
}

#mobNavOpen {
    display: none!important;
}

#mobilenav {
    display: none!important;
}
#app {
  width: 100%!important;
  padding: 0!important;
  margin: 0!important;
  padding-top: 0!important;
}
</style>`
  }
}
customElements.define('style-hidden', hidenavbar)
export default function Login() {
  const [loading,setLoading]=useState(false)
  return (<div className="b1 jfs" style={{margin: '0'}}>
    <div id="logoStacked" className="b1 loginTop">
      <long-logo color="var(--l)"></long-logo>
      <style-hidden></style-hidden>
    </div>
    <form className="b1 login" onSubmit={e => {
      e.preventDefault();
      setLoading(true)
      let form = e.target
      fetch(window.API + '/login', {
        method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
          email: form['email'].value,
          password: form['password'].value,
        })
      }).then(result => result.json()).then(result => {
        if (result.error) {
          setLoading(false)
          return window.flash(result.message || result, 5000, 'ef0000');
        }else{
        form.reset()
        setLoading(false)
        localStorage.setItem('userID', result.userID)
        localStorage.setItem('type', result.type)
        localStorage.setItem('auth', result.auth)
        window.flash('success', 5000, 'ef0000')
        if (window.DESKTOP) {
          window.DESKTOP.getCookie(result.redirect).then(() => {
            window.app.setState({
              auth: localStorage.getItem('auth'),
              type: localStorage.getItem('type'),
              userID: localStorage.getItem('userID'),
            })
            window.app.getTitles()
            window.redirect('/policy')
          }).catch(e => {
            window.flash(e)
            localStorage.clear()
          })
        } else {
          console.log(window.redirect)
          window.location = result.redirect
        }
      }
      }).catch(e => {
        console.log(e)
        window.flash('Error processing your request.', 5000, 'ef0000')
      })
    }}>
      <br />
      <h2>Portal Login</h2>
      <div className="b1 loginContCont">
        <div className="b1 loginCont">
          <input type="email" name="email" placeholder="Email"></input>
        </div>
        <div className="b1 loginCont" style={{ width: '60%', margin: '5px 15px' }}>
          <input type="password" name="password" placeholder="Password"></input>
        </div>
      </div>
      <div className="b1 loginCont">
        {loading?
         <button disabled>Please Wait...</button>:
         <button type="submit">Log In</button>
        }
        
      </div>
    </form>
    <a href="/reset-password"><span style={{ cursor: 'pointer' }}>Forgot your password? Click here.</span></a>
  </div>
  );
}
