import React, { Component } from 'react'
import Spinner from '../helper/spinner'
import ReactDOM from 'react-dom';
import Sure from '../helper/sure'
import TagList from '../helper/taglist';

import '../css/package.css'

class Package extends Component {
    state = {
        package: null,
        files: [],
        ftp: null,
        auth: false,
        contacts: []
    }
    componentDidMount() {
        if (this.props.package && typeof this.props.package === 'object') {
            console.log(this.props.package)
            this.setState({ package: this.props.package, auth: window.app.state.auth ? true : false })
            this.getFiles(this.props.package.files)
            this.getContacts(this.props.package.recipients)
            if (this.props.package.ftp) this.getFTP(this.props.package.ftp)
        } else if (typeof this.props.package === 'string') {
            this.getPackage(this.props.package, window.app.state.auth)
        } else {
            this.getPackage(window.app.queries['_id'], window.app.state.auth)
        }
    }
    removeFile = (id) => {
        window.app.request(`/remove-package-file`, 'post', { package: this.state.package._id, file: id }).then(result => {
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            }
            this.setState({ package: result.package, files: [...this.state.files].filter(u => u._id !== id) })
            if (window.packages?.state) window.packages.setState({
                packages: [...window.packages.state.packages].map(u => {
                    if (u._id === this.state.package._id) u.files = [...u.files].filter(u => u._id !== id)
                    return u
                })
            })
        }).catch(e => window.flash(e))
    }
    deletePackage = (id) => {
        window.app.request(`/delete-package?_id=${id}`, 'post').then(result => {
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            } else if (window.packages) {
                return window.packages.setState({ viewing: '', packages: [...window.packages.state.packages].filter(u => u._id !== id) })
            } else {
                window.redirect('/packages')
            }
        }).catch(e => window.flash(e))
    }
    getFiles(files) {
        window.app.request(`/files`, 'post', { files }).then(result => {
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            }
            this.setState({ files: result.files })
        }).catch(e => window.flash(e))
    }
    getContacts(recipients) {
        window.app.request(`/package-recipients`, 'post', { recipients }).then(result => {
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            }
            this.setState({ contacts: result.recipients })
        }).catch(e => window.flash(e))
    }
    getFTP(id) {
        window.app.request(`/package-ftp`, 'post', { _id: id }).then(result => {
            if (result.error) {
                window.flash(result.message || JSON.stringify(result))
                return window.app.logOut()
            }
            this.setState({ ftp: result.ftp })
        }).catch(e => window.flash(e))
    }
    getPackage(id, auth) {
        if (!id) return
        if (auth) {
            window.app.request(`/package?_id=${id}`, 'post').then(result => {
                console.log(id, result)
                if (result.error) {
                    return window.flash(result.message || JSON.stringify(result))
                }
                if (!result.package) return this.setState({missing: true})
                this.setState({ package: result.package, auth: true })
                this.getFiles(result.package.files)
                this.getContacts(result.package.recipients)
                if (result.ftp) this.getFTP(result.ftp)
            }).catch(e => {
                window.flash(e)
            })
        } else {
            window.app.request(`/download-package?_id=${id}`, 'post').then(result => {
                this.setState({ package: result.package, files: result.files, ftp: result.ftp, auth: false })
            }).catch(e => window.flash(e))
        }
    }
    sure(message, data, callback) {
        ReactDOM.render((<Sure confirm={data => { callback(data); this.removeSure() }} data={data} message={message} close={this.removeSure}></Sure>), document.getElementById('pop'))
        document.getElementById('popup').classList.remove('hidden');
    }
    removeSure() {
        try {
            ReactDOM.unmountComponentAtNode(document.getElementById('pop'))
            document.querySelector('#popup').classList.add('hidden');
        } catch (e) {
            console.log('error', e)
        }
    }
    render() {
        if (this.state.missing) return (<div className="b1"><h3>This package has been deleted.</h3></div>)
        if (!this.state.package) return (<div className="spin"><Spinner></Spinner><h3>Loading Package</h3></div>)
        return (<div className="b1 full">
            <h1>{this.state.package.name}</h1>
            <span><strong>{window.app.state.auth && `TOTAL RECIPIENTS:`}</strong>{window.app.state.auth && ` ${this.state.package.recipients?.length} |  `}<strong>TOTAL TITLES:</strong> {this.state.package.titles?.length} | <strong>TOTAL FILES:</strong> {this.state.package.files?.length} | <strong>TOTAL SIZE:</strong> {window.fileSize(this.state.package.size)}</span>
            <span><strong>Created:</strong> {new Date(this.state.package.date).toLocaleDateString()} <div className="warn"><strong>Expires:</strong> {new Date(this.state.package.expires).toLocaleDateString()}{window.location.pathname === '/packages' && <button className="smallbut" onClick={e => {
                console.log('change date')
            }}> <i className="fas fa-pen"></i> Change</button>}</div></span>
            {this.state.ftp && this.state.ftp.available && <div>
                <h3>FTP INFO</h3>
                <span><strong>Username:</strong> {this.state.ftp.username}</span>
                <span><strong>Password:</strong> {this.state.ftp.password}</span>
                <span><strong>Host:</strong> {this.state.ftp.host}</span>
                <span><strong>Port:</strong> {this.state.ftp.port}</span>
                <span><strong>Expires: </strong>{new Date(this.state.ftp.expires).toLocaleString()}</span>
            </div>}{this.state.ftp && !this.state.ftp.available && <div><h3>FTP NOT YET AVAILABLE</h3></div>}
            <div className="b1">
                <h3>Titles</h3>
                <div className="b2">{this.state.package?.titles?.map((u, i) => {
                    return (<p key={i} id={u}><strong>{window.app.state.titles?.find(a => a._id === u)?.title}</strong></p>)
                })}</div>
            </div>
            {window.app.state.auth && <div className="b1">
                <h3>Package Recipients</h3>
                <div className="grid">{this.state.contacts?.length > 0 && this.state.contacts.map((u, i) => (<div key={i} id={u._id}><p>{u.firstName} {u.lastName[0]}. - {u.email}</p></div>))}</div>
            </div>}
            <div className="b1">
                <h3>Files</h3>
                {false && <a href={`${window.API}/download-all-package?_id=${this.state.package._id}`} rel="noreferrer" target="_blank"><button><i className="fas fa-download"></i> Download All</button></a>}
                <div className="grid">
                    {this.state.files?.length > 0 && this.state.files.map((u, i) => (<div key={i} id={u._id} className="packageFile"><p>{u.name} - {window.fileSize(u.stats.size)} {window.app.state.auth && <TagList tags={u.tags}></TagList>}</p>{window.DESKTOP ? (<button id={u._id} onClick={e => window.app.handleDownload(e.target.id)}>Save</button>) : u.link && <a href={u.link} target="_blank" rel="noreferrer"><button><i className="fas fa-download"></i> Download</button></a>}{window.location.pathname === '/packages' && window.isInternal() &&  <button className="smallbut" onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        let file = this.state.files.find(u => u._id === e.target.parentElement.id)
                        if (file) this.sure(`Are you sure you want to remove ${file.name} from the package?`, file._id, this.removeFile)
                    }} className="red-btn"><i className="fas fa-trash"></i> Remove File</button>}</div>))}
                </div>
            </div>
            {window.location.pathname === '/packages' && window.isInternal() && <button style={{ width: '90%' }} onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                this.sure(`Are you sure you want to delete this package?`, this.state.package._id, this.deletePackage)
            }}>Delete Package</button>}
        </div>)
    }
}
export default Package