import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Spinner from './helper/spinner';
import Pagination from './helper/pagination';
import Title from './title/title'
import Package from './package/package'
import File from './file/file'
import List from './helper/list'
import SearchBar from './helper/searchbar';
class User extends Component {
    state = {
        mode: 'titles'
    }
    addTitle(id) {
        window.app.request('/add-title-to-user', 'post', { _id: this.props.user._id, title: id }).then(result => {
            window.flash('Title access granted!')
        }).catch(e => window.flash(e))
    }
    removeTitle(id) {
        window.app.request('/remove-title-from-user', 'post', { _id: this.props.user._id, title: id }).then(result => {
            window.flash('Removed title from user')
        }).catch(e => window.flash(e))
    }
    addPackage(id) {
        window.app.request('/add-package-to-user', 'post', { _id: this.props.user._id, package: id }).then(result => {
            window.flash('Package access granted to user')
        }).catch(e => window.flash(e))
    }
    removePackage(id) {
        window.app.request('/remove-package-from-user', 'post', { _id: this.props.user._id, package: id }).then(result => {
            window.flash('Removed package from user')
        }).catch(e => window.flash(e))
    }
    setUserType(type) {
        window.app.request('/change-user-type', 'post', { _id: this.props.user._id, type }).then(result => {
            window.flash('Update user type')
        }).catch(e => window.flash(e))
    }
    resetPassword() {
        window.app.request('/password-reset', 'post', { _id: this.props.user._id }).then(result => {
            window.flash('Password Reset Sent')
        }).catch(e => window.flash(e))
    }
    changeEmailAddress(email) {
        window.app.request('/change-user-email', 'post', { _id: this.props.user._id, email }).then(result => {
            window.flash('Email Address Updated')
        }).catch(e => window.flash(e))
    }
    setAppId(id) {
        window.app.request('/password-reset', 'post', { _id: this.props.user._id, appId: id }).then(result => {
            window.flash('App Id Updated!')
        }).catch(e => window.flash(e))

    }
    changeName(first, last) {
        window.app.request('/password-reset', 'post', { _id: this.props.user._id, firstName: first, lastName: last }).then(result => {
            window.flash('Updated user\'s name')
        }).catch(e => window.flash(e))
    }
    render() {
        window.USER = this
        if (this.state.package) return (<div className="b1 full rel backedw" style={{ zIndex: 10000 }}><button onClick={e => this.setState({ package: '' })} className="close">X</button><Package package={this.state.package} /></div>)
        if (this.state.file) return (<div className="b1 full rel backedw" style={{ zIndex: 10000 }}><button onClick={e => this.setState({ file: '' })} className="close">X</button><File file={this.state.file} /></div>)
        if (this.state.title) return (<div className="b1 full rel backedw" style={{ zIndex: 10000 }}><button onClick={e => this.setState({ title: '' })} className="close">X</button><Title title={this.state.title} /></div>)
        let u = { ...this.props.user }
        return (<div id={u._id} className="user">
            <div style={{ position: 'absolute', top: 0, left: 0 }} className="b2 fifty jfs">{Object.keys(u).filter(a => !['appId', '_id', 'email', 'dateJoined', 'type', 'companyName', 'firstName', 'lastName', '__v'].includes(a)).map((a, ie) => {
                return (<div key={ie} className="b1 jfs">
                    {(() => {
                        switch (a) {
                            case 'titles': {
                                return (<span className="selector" onClick={e => this.setState({ mode: 'titles' })}>Titles</span>)
                            }
                            case 'uploads': {
                                return (<span className="selector" onClick={e => this.setState({ mode: 'uploads' })}>Uploads</span>)
                            }
                            case 'downloads': {
                                return (<span className="selector" onClick={e => this.setState({ mode: 'downloads' })}>Downloads</span>)
                            }
                            case 'logins': {
                                return (<span className="selector" onClick={e => this.setState({ mode: 'logins' })}>Logins</span>)
                            }
                            case 'packages': {
                                return (<span className="selector" onClick={e => this.setState({ mode: 'packages' })}>Packages</span>)
                            }
                            default: return (<span onClick={e => this.setState({ mode: a })}><strong>{a}:</strong> {JSON.stringify(u[a])}</span>)
                        }
                    })()}
                </div>)

            })}</div>
            <div style={{ width: '100%', marginTop: '10vh' }}></div>
            <div className="b2 fifty backedw" style={{borderRadius: 'var(--radius)', border: 'var(--border)', boxShadow: 'var(--shadow)'}}><h3>{u.firstName} {u.lastName}</h3>
                <div className="b2">
                    <div className="b1 fifty">
                        <span><strong>_id:</strong> {u._id}</span>
                    </div>
                    <div className="b1 fifty">
                        <span><strong>Email:</strong> {u.email}</span>
                    </div>
                    <div className="b1 fifty">
                        <span><strong>Type:</strong> {u.type}</span>
                    </div>
                    {u.companyName && <div className="b1 fifty">
                        <span><strong>Company Name:</strong> {u.companyName}</span>
                    </div>}
                    {u.appId && <div className="b1 fifty">
                        <span><strong>App Id:</strong> {u.appId}</span>
                    </div>}
                    <div className="b1 fifty">
                        <span><strong>First Joined:</strong> {window.formatDate(u.dateJoined)}</span>
                    </div>
                </div>
            </div>

            <div className="b1">{(() => {
                switch (this.state.mode) {
                    case 'titles': {
                        return (<><h3>Titles</h3>{u['titles'].map(e => window.app.state.titles.find(c => c._id === e)).map((u, i) => (<div key={i} onClick={e => this.setState({ title: e.target.id })} id={u._id} className="selective">{u} {(u.type === 'user' || u.type === 'filmmaker') && <button onClick={e => {
                            let id = e.target.parentElement?.id
                            if (!id) return window.flash('missing id')
                            this.removeTitle(id)
                        }}>X</button>}</div>))}{(u.type === 'user' || u.type === 'filmmaker') && (<div className="addTitle">
                            <h3>Add Title to User</h3>
                            <SearchBar items={[...window.app.state.titles].filter(a => !u.titles.includes(a._id))} mainKey={'_id'} indexes={['title']} template={(u, i) => (<div key={i} id={u._id} onClick={e => this.addTitle(e.target.id)}>{u.title}</div>)} />
                        </div>)}</>)
                    }
                    case 'uploads': {
                        return (<>
                            <span><strong>Uploads</strong></span>
                            <List count={12} mainKey={'_id'} items={u['uploads'].map(u => ({ _id: u }))} indexes={['_id']} listItem={(u, i) => (<div className="selective" onClick={e => this.setState({ file: e.target.id })} key={i} id={u._id}>
                                {u._id}
                            </div>)} template={(u, i) => (<div onClick={e => this.setState({ file: e.target.id })} key={i} id={u._id}>
                                <h3 onClick={window.parentClick}>{u._id}</h3>
                            </div>)} />
                        </>)
                    }
                    case 'downloads': {
                        let dl = u['downloads'].reduce((o, n) => {
                            if (!o.find(u => u._id === n)) {
                                o.push({ _id: n, times: 1 })
                            } else {
                                return o.map(u => {
                                    if (u._id === n) u.times++
                                    return u
                                })
                            }
                            return o
                        }, [])
                        return (<>
                            <span><strong>Downloads</strong></span>
                            <List mainKey={'_id'} items={dl} indexes={'_id'} listItem={(u, i) => (<div className="selective" onClick={e => this.setState({ file: e.target.id })} key={i} id={u._id}>{u._id} - Times Downloaded: {u.times}</div>)} template={(u, i) => (<div onClick={e => this.setState({ file: e.target.id })} key={i} id={u._id}>{u._id} - Times Downloaded: {u.times}</div>)} />
                        </>)
                    }
                    case 'logins': {
                        return <List search={false} mainKey={'ip'} indexes={['ip']} items={u['logins'].sort((a, b) => new Date(b.date) - new Date(a.date))} listItem={(u, i) => (<div className="login" key={i}>
                            <span><strong>IP:</strong>{u.ip}<br></br><strong>Date:</strong> {new Date(u.date).toLocaleTimeString()} - {window.formatDate(u.date)}</span>
                        </div>)} />
                    }
                    case 'packages': {
                        return (<>
                            <span><strong>Packages</strong></span>
                            {u['packages'].map((u, i) => (<div onClick={e => this.setState({ package: e.target.id })} key={i} id={u} className="selective">{u} {(u.type === 'user' || u.type === 'filmmaker') && <button onClick={e => {
                                let id = e.target?.parentElement?.id
                                if (!id) return window.flash('missing id')
                                this.removePackage(id)
                            }}>X</button>}</div>))}
                            {(u.type === 'user' || u.type === 'filmmaker') && <div>
                                <h3>Add Package to User</h3>
                                <SearchBar items={window.app.state.packages} mainKey={'_id'} indexes={['name']} template={(u, i) => (<div id={u._id} onClick={e => this.addPackage(e.target.id)} key={i}>
                                    {u.name}
                                </div>)} />
                            </div>}
                        </>)
                    }
                    default: return (<span></span>)
                }
            })()}
            </div>
        </div>)
    }
}
class Users extends Component {
    state = { users: [], count: 12, page: 0, gotUsers: false }
    componentDidMount() {
        this.getUsers()
    }
    getUsers() {
        window.app.request('/users', 'get', null).then(result => this.setState({ users: result.users, gotUsers: true })).catch(e => { window.flash(e); this.setState({ gotUsers: true }) })
    }
    render() {
        window.USERS = this
        if (this.state.viewing) return (<div className="b1 full rel"><button onClick={() => this.setState({ viewing: '' })} className="close">X</button><User user={this.state.users.find(u => u._id === this.state.viewing)}></User></div>)
        return (<div className="b1 full rel">
            {<Link to="/new-user"><button className="fixedButton"><i className="fas fa-plus"></i> &nbsp;Create User</button></Link>}
            <div className="options">
                <SearchBar mainKey={'_id'} indexes={['firstName', 'lastName', 'email']} items={this.state.users} template={(u,i) => (<div id={u._id} style={{cursor: 'pointer'}} onClick={e => this.setState({viewing: e.target.id})} key={i}>
                    <h3 style={{cursor: 'pointer'}} onClick={window.parentClick}>{u.firstName} {u.lastName} - {u.email}</h3>
                </div>)} />
            </div>
            <h1>Users</h1>
            {!this.state.gotUsers ? (<><h3>Loading Users</h3><Spinner /></>) : <>{this.state.users?.length > 0 ? <div className="c2">
                {window.index([...this.state.users].sort((a, b) => new Date(a.dateJoined) - new Date(b.dateJoined)), this.state.page, this.state.count).map((u, i) => (<div key={i} id={u._id} onClick={e => this.setState({ viewing: e.target.id })} className="b1"><h3 onClick={window.parentClick} className="userName">{u.firstName} {u.lastName} - {u.email} - {u.type}</h3></div>))}
            </div> : <h3>No Users Listed</h3>}
                <Pagination count={this.state.count} total={this.state.users?.length || 0} set={e => this.setState({ page: e })} page={this.state.page} />
            </>}
        </div>)
    }
}
export default Users