import React, { Component } from 'react'
import * as JsSearch from 'js-search';
class SearchBar extends Component {
    state = {
        list: [],
        searching: ''
    }
    componentDidMount(){
    this.setState({key: this.props.mainKey || '_id'})
    if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    componentDidUpdate(){
        if (this.props.mainKey !== this.state.key) this.setState({key: this.props.mainKey})
        if (typeof this.props.bar === 'function') this.props.bar(this)
    }
    find = () => (() => {
        let s = new JsSearch.Search(this.state.key);
        s.indexStrategy = new JsSearch.PrefixIndexStrategy()
        for (let i = 0; i < this.props.indexes.length; i++) s.addIndex(this.props.indexes[i])
        return s
    })();
    search(value) {
        if (!this.props.items || this.props.length < 1) return []
        let s = this.find()
        s.addDocuments([...this.props.items])
        let a = s.search(value)
        if (typeof this.props.onSearch === 'function') this.props.onSearch(value)
        return a
    }
    list(u, i) {
        return (<div key={i} className="b2 jsb searchResult">
            <h3>{u[this.state.key]}</h3>
            <button id={u[this.state.key]} className="smallbut" onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (this.props.callback && typeof this.props.callback === 'function') this.props.callback(e.target.id)
            }}>VIEW</button></div>)
    }
    render() {
        return (<div id="searchbar">
            <input id="searchbarInput" placeholder={this.props.placeholder || "Search"} type="text" autoComplete="off" value={this.state.searching} onChange={e => this.setState({ list: this.search(e.target.value), searching: e.target.value })}></input>
            <div id="searchresults" className={'b1' + (!this.state.searching ? ' hidden' : '')}>
                <div className="b1 jsa">Search Results</div>
                {this.state.list.length < 1 ? (<h3>No Results for {this.state.searching || 'Null'}</h3>) : this.state.list.map(this.props.template || this.list)}
            </div>
        </div>)
    }
}
export default SearchBar